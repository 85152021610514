import TaskList from './TaskList';

const path = '/admin/dashboard/task-monitor';

export default [
  {
    name: 'Task List',
    path: `${path}/list`,
    icon: 'sticky note',
    component: TaskList,
    exact: true,
  },
 
];

export const redirects = [
  {
    from: `${path}`,
    to: `${path}/list`,
  },
  {
    from: `${path}/*`,
    to: `${path}/list`,
  },
];
