import React from 'react';
import compose from 'lodash/fp/compose';
import {
  withTranslation,
  withBulkDelete,
  withDeleteConfirmation,
  withVaccinationHistory,
  withCustomRouter,
  withUserPreferences,
} from 'components/hoc';
import { getFullName } from 'components/utils/getFullName';
import MyDataContentTemplate from '../../common/MyDataContentTemplate';
import LottieLoader from 'components/common/LottieLoader';

const options = ({ detail, _delete }) => {
  return [
    {
      onClick: detail,
      name: 'View',
    },
    {
      onClick: _delete,
      name: 'Delete',
    },
  ];
};

const VaccinationHistory = (props) => {
  // Add filterableColumns configuration
  const filterableColumns = [
    {
      columnId: '_person',
      columnDataType: 'text',
    },
    {
      columnId: '_org',
      columnDataType: 'text',
    },
    {
      columnId: '_vaccination_date',
      columnDataType: 'date',
    },
    {
      columnId: 'trade_name',
      columnDataType: 'text',
    },
    {
      columnId: 'batch_no',
      columnDataType: 'text',
    },
    {
      columnId: 'space_name',
      columnDataType: 'text',
    },
  ];

  const {
    vaccinationHistory,
    gotoPath,
    deleteVaccinationHistoryById,
    deleteBulkVaccinationHistory,
    fd,
    gotoCreatePath,
    gotoDetailPath,
    loaded,
  } = props;

  const handleCreateForm = () => gotoPath(`create`);

  const mapFields = () =>
    vaccinationHistory.data.map((vaccination) => ({
      ...vaccination,
      space_name: vaccination.space,
      _person: getFullName(vaccination?.person_detail),
      _org: vaccination?.org_detail?.name,
      _vaccination_date: fd(vaccination?.vaccination_date),
      trade_name: vaccination?.trade_name,
      batch_no: vaccination?.batch_no,
    }));

  if (!loaded) {
    return (
      <>
        <LottieLoader />
      </>
    );
  }

  return (
    <MyDataContentTemplate
      tableName="vaccination_history"
      columns={[]}
      data={mapFields()}
      bulkDelete={deleteBulkVaccinationHistory}
      filterableColumns={filterableColumns}
      toggleForm={gotoCreatePath}
      options={options({
        detail: (data) => gotoDetailPath(data.id),
        _delete: (data) => deleteVaccinationHistoryById(data),
      })}
    />
  );
};

export default compose(
  withTranslation,
  withBulkDelete,
  withVaccinationHistory,
  withDeleteConfirmation,
  withCustomRouter,
  withUserPreferences,
)(VaccinationHistory);
