/* eslint-disable camelcase */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import compose from 'lodash/fp/compose';
import styled from 'styled-components';
import {
  Input,
  Select,
  ColumnalSelect,
  InputDatePicker,
  Form,
  ButtonLink,
  NewEntriesSelect,
  // FormMode,
} from 'components/common/FormItems';
import {
  withLanguage,
  withDocument,
  withCountry,
  withState,
  withCity,
  withPersonalInfo,
  withAccount,
  withCustomRouter,
  withUserPreferences,
  withMsgInfo,
} from 'components/hoc';
import { useForm } from 'hooks/useForm';
import { documentSlice } from 'rootStateHandler/selectors';
import { useDataArea } from 'hooks/useDataArea';
import { useOrgIndicator } from 'hooks/useOrgIndicator';
import { ORG_INDICATOR_TYPES, orgIndicatorOptions } from '../../../../constants';
// import { addDocument, updateDocument } from 'actions';
import { getUserPreferences } from 'actions/userPreferences';
import { initialValues, getFormValuesFromPayload } from './utils';
import { Icon, Popup } from 'semantic-ui-react';
import swal from 'sweetalert';
import axios from '../../../../axios';
import API from 'actions/api';
import { RESPONSE_STRINGS } from '../../../../constants';
import { language } from 'reducers/preload';

const StyledButtonLink = styled(ButtonLink)`
  margin-top: 15px;
  span {
    text-transform: uppercase;
  }
`;

const baseUrl = API + '/dashboard/documents';

const Wrapper = styled.div``;

// Styled component for the outer bar
const ProgressBarWrapper = styled.div`
  width: 100%;
  background-color: #ddd;
  border-radius: 8px; // Adds rounded corners for a smoother look
  overflow: hidden; // Ensures the inner bar stays within the rounded corners
`;

// Styled component for the inner bar
const Progress = styled.div`
  height: 20px;
  width: ${(props) => props.width}%;
  background-color: ${(props) => props.color || 'green'};
  line-height: 20px; // Aligns the text vertically
  color: white;
  text-align: center;
  transition: width 0.3s ease-in-out; // Smooth transition for width changes
  border-radius: 8px; // Optional: if you want rounded corners on the progress itself
`;

const ProgressBar = ({ uploadProgress, color, statusText = '' }) => {
  return (
    <div>
      {uploadProgress > 0 && (
        <>
          <ProgressBarWrapper>
            <Progress width={uploadProgress} color={color}>
              {uploadProgress}%
            </Progress>
          </ProgressBarWrapper>
          <p>{statusText}</p>
        </>
      )}
    </div>
  );
};

const personLabels = [
  {
    first_name: 'First Name',
  },
  {
    last_name: 'Last Name',
  },
];

const StyledDiv = styled.div`
  position: relative;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  justify-content: ${({ justifyContent }) => justifyContent || ''};
  flex-wrap: wrap;
`;

const FileAvatar = styled.a`
  margin: 5px 5px;
  font-style: italic;
  font-size: 0.9rem;
  align-items: center;
  display: block;
  &:last-child {
    margin-top: 0;
  }
`;

const documentFormSchema = (isOrg) => ({
  main_area: {
    validation: 'required',
    message: {
      required: 'Data Area is required',
    },
  },
  document_type: {
    validation: 'required',
    message: {
      required: 'Document type is required',
    },
  },
  issuing_org: {
    validation: 'required',
    message: {
      required: 'Issuing organization is required',
    },
  },
  concerned_person: {
    validation: 'required',
    message: {
      required: 'Concerned person is required',
    },
  },
  reference_year: {
    validation: 'numeric|min:1|max:9999',
    message: {
      numeric: 'Reference year must be a number',
      min: 'Reference year must be at least 1',
      max: 'Reference year cannot be greater than 9999',
    },
  },
  reference_month: {
    validation: 'numeric|min:1|max:12',
    message: {
      numeric: 'Reference month must be a number',
      min: 'Reference month must be at least 1',
      max: 'Reference month cannot be greater than 12',
    },
  },
  language: {
    validation: 'required',
    message: {
      required: 'Language is required',
    },
  },
  org_indicator: {
    validation: 'required',
    message: {
      required: 'Organization indicator is required',
    },
  },
  ...(isOrg
    ? {
        country: {
          validation: 'required',
          message: {
            required: 'Country is required',
          },
        },
      }
    : {}),
});


const DocumentForm = (props) => {
  const [retrivedLanguage, setRetrivedLanguage] = useState('');
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { dataArea, dataAreaOptions } = useDataArea();
  const { documentDetails } = useSelector(documentSlice);
  const [duplicates, setDuplicates] = useState([]);
  // const [uploadProgress, setUploadProgress] = useState(0);
  // const [uploadColor, setUploadColor] = useState('green');
  // const [uploadText, setUploadText] = useState('Uploading...');
  // const { handleOrgIndicatorChange, organisationOrPersonOptions } = useOrgIndicator({
  //   defaultOrgIndicator: ORG_INDICATOR_TYPES.ORG,
  // });

  const { org_indicator, handleOrgIndicatorChange, organisationOrPersonOptions } =
    useOrgIndicator({
      defaultOrgIndicator: documentDetails?.org_indicator || ORG_INDICATOR_TYPES.ORG,
      onOrgIndicatorChange: (value) => {
        setValues((prev) => ({
          ...prev,
          org_indicator: value,
          issuing_org: '', // Reset issuing_org when indicator changes
          country: '', // Reset location fields
          state: '',
          city: '',
        }));
      },
    });

  const history = useHistory();

  const onSubmit = async (formValues) => {
    setIsSubmitting(true);
    const formData = new FormData();
    Object.entries(formValues).forEach(([key, value]) => {
      if (key === 'files' && value) {
        Array.from(value).forEach((file) => {
          formData.append('files[]', file);
        });
      } else if (value) {
        formData.append(key, value);
      }
    });
  
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      let response;
      if (formValues?.id) {
        // Update existing document (PATCH)
        response = axios.patch(`${baseUrl}/${formValues.id}`, formData, config);
        history.push(`/dashboard/documents`);
        dispatch(
          setMsgInfo({
            success: true,
            msg: [`${tr('Document updated successfully')}`],
          }),
        );
      } else {
        // Create a new document (POST)
        response = axios.post(`${baseUrl}`, formData, config);
        history.push(`/dashboard/documents`);
        dispatch(
          setMsgInfo({
            success: true,
            msg: [`${tr('The Document upload started in the background. You can move on now')}`],
          }),
        );
      }

    } catch (error) {
      let errorKey = error?.response?.data?.error_key;
      if (errorKey === 1) {
        const duplicatesErrors = error?.response?.data?.files || [];
        if (Array.isArray(duplicatesErrors)) {
          setDuplicates(duplicatesErrors);
        }
      }
      dispatch(
        setMsgInfo({
          success: false,
          msg: [`${tr('An error occurred while uploading documents.')}`],
        }),
      );
    } finally {
      setIsSubmitting(false);
    }
  };
  

  // const onSubmit = async (formValues) => {
  //   setIsSubmitting(true);
  //   const formData = new FormData();
  //   Object.entries(formValues).forEach(([key, value]) => {
  //     if (key === 'files' && value) {
  //       Array.from(value).forEach((file) => {
  //         formData.append('files[]', file);
  //       });
  //     }
  //     if (value) formData.append(key, value);
  //   });

  //   try {
  //     var config = {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //       },
  //     };
  //     setIsSubmitting(true);
  //     if (formValues?.id) {
  //       const { data } = await axios.patch(
  //         `${baseUrl}/${formValues?.id}`,
  //         formData,
  //         config,
  //       );
  //     } else {
  //      const { data } = await axios.post(`${baseUrl}`, formData, config);
  //     }

  //     history.push(`/dashboard/documents`);
  //     dispatch(
  //       setMsgInfo({
  //         success: true,
  //         msg: [`${tr('Document added successfully')}`],
  //       }),
  //     );
  //   } catch (error) {
  //     let errorKey = error?.response?.data?.error_key;
  //     if (errorKey == 1) {
  //       let duplicatesErrors = error?.response?.data?.files || [];
  //       if (Array.isArray(duplicatesErrors)) {
  //         setDuplicates(duplicatesErrors);
  //       }
  //     }
  //     setIsSubmitting(false);
  //   } finally {
  //     setIsSubmitting(false);
  //   }
  // };

  const {
    tr,
    countryLocaleOptions,
    fetchingState,
    fetchingCity,
    languageOptions,
    cityLocaleOptions,
    stateLocaleOptions,
    documentForm,
    toggleDocumentForm,
    // showDocumentPage,
    basePath,
    // routePathname,
    userPreferences,
    activeLanguageOptions,
    setMsgInfo,
    account: {
      user: { space_type = '' },
    },
  } = props;

  const fetchUserLanguageAccount = () => {
    dispatch(getUserPreferences()).then((response) => {
      const retrivedLang = response.language;
      setRetrivedLanguage(retrivedLang);
    });
  };
  const documentFormValidationSchema = documentFormSchema(space_type === 'O');

  const {
    handleChange,
    handleBlur,
    touched,
    values,
    errors,
    handleSubmit,
    setValues,
  } = useForm({
    validationSchema: documentFormValidationSchema,
    initialValues: {
      ...initialValues,
      main_area: dataArea,
      org_indicator: documentDetails?.org_indicator || ORG_INDICATOR_TYPES.ORG,
    },
    onSubmit,
  });

  const [showEntryForm, setShowEntryForm] = React.useState(false);
  const [entryValues, setEntryValues] = React.useState({
    value: '',
    language: userPreferences.language,
  });
  const [entrySubmitting, setEntrySubmitting] = React.useState(false);

  const toggleForm = () => {
    setValues({
      ...initialValues,
    });
    toggleDocumentForm({ showForm: !documentForm.showForm, data: {} });
  };

  const selectedDataArea =
    dataAreaOptions.find((data) => data.id === values.main_area) || {};

  const dataAreaToExclude = ['V', 'A'];

  const dataAreaFiltered = dataAreaOptions.filter(
    (area) => !dataAreaToExclude.includes(area.id),
  );

  const documentTypeOptions = props.documentType.filter((data) => {
    return data.area === selectedDataArea.long_description_eng;
  });

  useEffect(() => {
    if (values.country) {
      props.getStateByCountryId(values.country);
    }
    if (values.state) {
      props.getCityByStateId(values.state);
    }
  }, [values.country, values.state, values.city]);

  useEffect(() => {
    const lang = languageOptions.find(
      (language) => language.id === retrivedLanguage,
    );
    setValues({ ...values, language: lang?.id });
  }, [retrivedLanguage]);

  useEffect(() => {
    if (values.issuing_org && organisationOrPersonOptions.length > 0) {
      const selectedOrg = organisationOrPersonOptions.find(
        (org) => org.id === Number(values.issuing_org),
      );

      if (selectedOrg) {
        setValues((prev) => ({
          ...prev,
          country: selectedOrg.country_id || '',
          state: selectedOrg.state_id || '',
          city: selectedOrg.city_location || '',
        }));
      }
    }
  }, [values.issuing_org, organisationOrPersonOptions]);

  // useEffect(() => {
  //   if (values.issuing_org) {
  //     const org = organisationOrPersonOptions.filter(
  //       ({ id }) => Number(id) === Number(values.issuing_org),
  //     )[0];
  //   }
  // }, [values.issuing_org, organisationOrPersonOptions, setValues]);

  useEffect(() => {
    if (values.document_type) {
      const selectedDocumentType = props.documentType.filter(
        (docType) => docType.id === values.document_type,
      );

      setValues((currentValues) => ({
        ...currentValues,
        document_category: selectedDocumentType[0].doc_category,
      }));
    }
  }, [values.document_type, props.documentType, setValues]);

  useEffect(() => {
    if (documentDetails?.org_indicator && !values.org_indicator) {
      handleOrgIndicatorChange(documentDetails?.org_indicator);
      setValues((currentFormValues) => ({
        ...currentFormValues,
        issuing_org: documentDetails?.issuing_org || '',
      }));
    }
  }, [documentDetails?.org_indicator]);

  useEffect(() => {
    const newValues = getFormValuesFromPayload(documentForm.data);
    setValues((currentValues) => ({
      ...currentValues,
      ...newValues,
      document_type: documentDetails?.document_type || '',
      issuing_date: documentDetails?.issuing_date || '',
      issuing_org: documentDetails?.issuing_org || '',
      country: documentDetails?.country || '',
      concerned_person: documentDetails?.concerned_person || '',
      reference_year: documentDetails?.reference_year || '',
      reference_month: documentDetails?.reference_month || '',
      valid_from: documentDetails?.valid_from || '',
      valid_to: documentDetails?.valid_to || '',
      main_area: documentDetails?.main_area || '',
      org_indicator: documentDetails?.org_indicator || '',
      language: documentDetails?.language,
      // files: documentDetails?.file || '',
      description: documentDetails?.description || '',
      state: documentDetails?.state || '',
      city: documentDetails?.city || '',
    }));
  }, [documentForm, setValues, documentDetails?.id]);

  useEffect(() => {
    fetchUserLanguageAccount();
  }, []);

  const toggleEntryField = () => {
    setShowEntryForm(!showEntryForm);
  };

  const onEntryChange = (e) => {
    const { value, name } = e.target;
    setEntryValues({ ...entryValues, [name]: value });
  };

  const validateAndSubmitEntry = async () => {
    if (!(entryValues.name && entryValues.language)) {
      swal(
        tr('Warning!'),
        tr(`Please ensure all required fields are populated.`),
        'warning',
      );
      return;
    }
    try {
      setEntrySubmitting(true);
      const payload = {
        name: entryValues.name,
        language: entryValues.language,
        entry_type: 'document_type',
        data_area: values?.main_area,
      };
      await axios.post(`${API}/propose/entry/`, payload);
      swal(
        tr('Success!'),
        tr(`${RESPONSE_STRINGS?.ENTRY_RESPONSE_SUCCESS_MESSAGE}`),
        'success',
      );
      setEntryValues({});
      toggleEntryField();
      return;
    } catch (error) {
      setEntrySubmitting(false);
    } finally {
      setEntrySubmitting(false);
    }
  };

  return (
    <Wrapper key={basePath} className="form-wrapper">
      <div className="row">
        <div
          className="col-7 ml-10 mt-40 container"
          style={{
            position: 'relative',
            background: 'var(--admincat-color-grey-1)',
          }}
        >
          <div
            className="ml-0"
            style={{ position: 'absolute', top: '-50px', left: '0', zIndex: 200 }}
          >
            <StyledButtonLink
              to={basePath}
              className="option-btn"
              icon="angle double left"
              // name="View Documents"
              name="All Entries"
            />
          </div>
          <br />
          <Form
            onSubmit={handleSubmit}
            open={documentForm.showForm}
            toggleForm={toggleForm}
            validationSchema={documentFormValidationSchema}
            formData={values}
            isLoading={isSubmitting}
            title={tr('Document')}
            actionButtonName="Save"
          >
            <Input
              onChange={handleChange}
              value={values.description}
              name="description"
              label="Description"
              placeholder="Description"
              onBlur={handleBlur}
              error={touched?.description && errors?.description?.[0]}
            />

            <Select
              showRequiredAsterisk
              onChange={handleChange}
              label={tr('Data area')}
              optionDefaultValue={tr('Choose Data Area')}
              value={values.main_area}
              name="main_area"
              localeOptions={dataAreaFiltered}
              error={touched?.main_area && errors?.main_area?.[0]}
              sort={false}
            />

            <NewEntriesSelect
              value={values.document_type}
              name="document_type"
              label="Document Type"
              optionDefaultValue="Document Type"
              localeOptions={documentTypeOptions}
              required
              onChange={(e) => {
                if (e.target.value == 'proposeValue') {
                  toggleEntryField();
                  return;
                } else {
                  handleChange(e);
                }
              }}
              entryDefaultLanguage={entryValues.language}
              entryValue={entryValues.name}
              entryLanguageValue={entryValues.language}
              entryValueName={'name'}
              entryType={tr('Document Type')}
              entryLanguageName={'language'}
              onEntryChange={onEntryChange}
              onCreateNewEntry={(e) => {
                e.preventDefault();
                validateAndSubmitEntry();
              }}
              add={showEntryForm}
              toggleEntryField={toggleEntryField}
              options={activeLanguageOptions}
              isLoading={entrySubmitting}
              showNewEntryOption={
                values?.main_area != undefined && values?.main_area
              }
              closeEntryForm={() => {
                toggleEntryField();
              }}
            />

            <Select
              showRequiredAsterisk
              label={tr('Issuer is Org or Person')}
              value={values.org_indicator}
              name="org_indicator"
              id="title"
              className="d-inline"
              optionDefaultValue={tr('Choose Organisation or Person')}
              localeOptions={orgIndicatorOptions}
              onChange={(e) => {
                const newValue = e.target.value;
                handleChange(e);
                handleOrgIndicatorChange(newValue);
              }}
              onBlur={handleBlur}
              error={touched?.org_indicator && errors?.org_indicator?.[0]}
              sort={false}
            />

            <StyledDiv>
              <Select
                showRequiredAsterisk
                label={tr('Issuer')}
                value={values?.issuing_org}
                name="issuing_org"
                localeOptions={organisationOrPersonOptions}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched?.issuing_org && errors?.issuing_org?.[0]}
                optionDefaultValue="Issuer"
              />
            </StyledDiv>
            {/* {values.org_indicator && (
              <Select
                showRequiredAsterisk
                label={tr('Issuer')}
                value={values.issuing_org}
                name="issuing_org"
                localeOptions={organisationOrPersonOptions}
                onChange={handleChange}
                error={touched?.issuing_org && errors?.issuing_org?.[0]}
                optionDefaultValue={tr('Choose Issuer')}
              />
            )} */}

            <Select
              value={values.country}
              name="country"
              label="Country"
              optionDefaultValue="Choose Country"
              localeOptions={countryLocaleOptions}
              error={touched?.country && errors?.country?.[0]}
              onChange={(e) => {
                handleChange(e);
                props.getStateByCountryId(e.target.value);
              }}
              disabled={!values.issuing_org}
              onBlur={handleBlur}
            />

            <Select
              isLoading={fetchingState}
              value={values.state}
              name="state"
              label="State"
              optionDefaultValue="Choose State"
              localeOptions={stateLocaleOptions}
              error={errors?.state?.[0]}
              onChange={(e) => {
                handleChange(e);
                props.getCityByStateId(e.target.value);
              }}
              disabled={!values.country}
              onBlur={handleBlur}
            />

            <Select
              isLoading={fetchingCity}
              onChange={(e) => {
                handleChange(e);
              }}
              value={values.city}
              name="city"
              label="City"
              optionDefaultValue="Choose City"
              localeOptions={cityLocaleOptions}
              disabled={!values.state}
              onBlur={handleBlur}
            />
            <Select
              optionDefaultValue="Choose Concerned Person"
              name="concerned_person"
              label="Concerned person"
              value={values?.concerned_person || ''}
              onChange={handleChange}
              options={props.personalInfoOptions}
              deselectable
              sort={false}
              showRequiredAsterisk
            />

            {/* <Select
              onChange={handleChange}
              value={values.concerned_person}
              optionDefaultValue="Choose Concerned Person"
              name="concerned_person"
              label="Concerned person"
              // options={props.personalInfo.data}
              localeOptions={props.personalInfo.data}
              onBlur={handleBlur}
            /> */}

            {/* <ColumnalSelect
              onChange={handleChange}
              value={values.concerned_person}
              name="concerned_person"
              label="Concerned person"
              labels={personLabels}
              options={props.personalInfo.data}
              onBlur={handleBlur}
            /> */}

            <InputDatePicker
              onChange={handleChange}
              value={values.issuing_date}
              name="issuing_date"
              label="Issuing Date"
              onBlur={handleBlur}
            />

            <Input
              onChange={handleChange}
              value={values.reference_year}
              name="reference_year"
              label="Reference Year"
              placeholder="Reference Year"
              onBlur={handleBlur}
              error={touched?.reference_year && errors?.reference_year?.[0]}
            />

            <Input
              onChange={handleChange}
              value={values.reference_month}
              name="reference_month"
              label="Reference Month"
              placeholder="Reference Month"
              onBlur={handleBlur}
              error={touched?.reference_month && errors?.reference_month?.[0]}
            />

            <Select
              // showRequiredAsterisk
              required
              onChange={handleChange}
              value={values.language}
              name="language"
              label="Language"
              //optionDefaultValue={defaultLanguage.name}
              options={languageOptions}
            />

            <InputDatePicker
              onChange={handleChange}
              value={values.valid_from}
              name="valid_from"
              label="Valid From"
              onBlur={handleBlur}
              error={touched?.valid_from && errors?.valid_from?.[0]}
            />

            <InputDatePicker
              onChange={handleChange}
              value={values.valid_to}
              name="valid_to"
              label="Valid To"
              onBlur={handleBlur}
              error={touched?.valid_to && errors?.valid_to?.[0]}
              disabled={!values.valid_from}
            />

          </Form>
        </div>
        {duplicates.length > 0 && (
          <div className="col-4 ml-10 mt-30" style={{ position: 'relative' }}>
            <br />
            <Form
              title={`Duplicate Documents(${duplicates.length})`}
              renderActionButtons={null}
            >
              <Flex justifyContent="flex-start" flexDirection="column">
                {duplicates.map((duplicate, index) => {
                  return (
                    <FileAvatar key={index}>
                      <Icon name="attach" /> {duplicate}{' '}
                      <Popup
                        content={tr('Remove this file')}
                        size="mini"
                        //trigger={<Icon name="times" onClick={()=>{removeFile(data?.name)}} circular inverted color='red' size='small'  />}
                      />
                    </FileAvatar>
                  );
                })}
              </Flex>
            </Form>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

DocumentForm.propTypes = {
  getStateByCountryId: PropTypes.func.isRequired,
  getCityByStateId: PropTypes.func.isRequired,
  tr: PropTypes.func.isRequired,
  countryLocaleOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      description_eng: PropTypes.string,
      description_deu: PropTypes.string,
    }),
  ).isRequired,
  cityLocaleOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      description_eng: PropTypes.string,
      description_deu: PropTypes.string,
    }),
  ).isRequired,
  stateLocaleOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      description_eng: PropTypes.string,
      description_deu: PropTypes.string,
    }),
  ).isRequired,
  fetchingState: PropTypes.bool.isRequired,
  fetchingCity: PropTypes.bool.isRequired,
  documentForm: PropTypes.shape({
    showForm: PropTypes.bool.isRequired,
    data: PropTypes.shape({}),
  }).isRequired,
  toggleDocumentForm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default compose(
  withPersonalInfo,
  withDocument,
  withLanguage,
  withCountry,
  withState,
  withCity,
  withAccount,
  withCustomRouter,
  withUserPreferences,
  withMsgInfo,
)(DocumentForm);
