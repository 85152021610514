import React from 'react';
import compose from 'lodash/fp/compose';
import { connect } from 'react-redux';
import hoistNonReactStatics from 'hoist-non-react-statics';
import * as taskMonitorActions from 'actions/admin/taskMonitor';
import { BaseHOC, withBaseHOCProps } from '../common/BaseHOC';

const initialState = {
  loaded: false,
};

export const withTaskMonitor = (WrappedComponent) => {
  class WithTaskMonitor extends BaseHOC {
    state = { ...initialState };

    async componentDidMount() {
      const { getAllTaskMonitor } = this.props;
      console.log("getAllTaskMonitor", getAllTaskMonitor)
      if (!this.state.loaded) {
        await getAllTaskMonitor();
        this.setState({ ...initialState, loaded: true });
      }
    }

    // refreshDashboardData = async () => {
    //   const { fetchAdminDashboardData } = this.props;
    //   await fetchAdminDashboardData();
    // };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          loaded={this.state.loaded}
        //   refreshDashboardData={this.refreshDashboardData}
        />
      );
    }
  }

  const mapStateToProps = ({ taskMonitor }) => ({ taskMonitor });

  const TaskMonitor = connect(
    mapStateToProps,
    taskMonitorActions,
  )(WithTaskMonitor);

  hoistNonReactStatics(TaskMonitor, WrappedComponent);

  return compose(withBaseHOCProps)(TaskMonitor);
};

export default withTaskMonitor;
