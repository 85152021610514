// mapping table column name to a more readable label
export const tableLabels = {
  credit_card: {
    number: 'Card Number',
  },
  criteria_set: {
    criteria_set: 'Criteria ID',
    join: 'Join ID',
  },
  document: {
    full_name: 'Concerned Person',
    issuing_org: 'Issuing Organisation',
    org_indicator: 'Organisation Indicator',
    file: 'Document',
  },
  organisation: {
    city_location: 'City',
  },
  contract: {
    document_link: 'Document',
  },
  person: {
    date_of_birth: 'Birth Date',
    date_of_death: 'Death Date',
    birth_country: 'Country',
    marit_st_last_change: 'Marital Status Last Change',
  },
  time_dependent_name: {
    person: 'First Name',
  },
  vaccination_history: {
    org: 'Organisation',
  },
  contract_item: {
    financial_category: 'Financial Account',
    subject: 'Item Ref. ID',
  },
  user_payment: {
    payment_date: 'Payment YY Date',
    payment_coverage: 'Payment XX Coverage',
  },
  income: {
    period: 'month',
    amount_sc: 'Space Amount',
    sp_currency: 'Space Currency',
    org_indicator: 'Org. Indicator',
    partner: 'Transaction partner',
  },
  consumption: {
    amount_sc: 'Space Amount',
    sp_currency: 'Space Currency',
    org_indicator: 'Org. Indicator',
    partner: 'Transaction partner',
  },
  automobile: {
    vehicle_no: 'Vehicle Identification Number',
    mot_color: 'Motor Color',
  },
};

// const transaction_item = ['month'];

const transaction_item = [
  'account_category',
  'amount',
  'amount_sc',
  'sp_currency',
  'currency',
  'offset_account',
  'dc_indicator',
  'financial_account',
  'line',
  'measurement_unit',
  'position_link',
  'quantity',
  'transaction',
  'offset_account_category',
  'original_doc_no',
  'reverse_doc_no',
  'reversal',
  'reversed',
];

// extending tables with more columns
export const gExtraColumns = {
  bank_account: ['is_active', 'organisation'],
  credit_card: ['person', 'issuing_org', 'license_org'],
  criteria_set: ['criteria_set', 'criteria_set_description', 'join_description'],
  document: ['full_name'],
  expenses: [...transaction_item],
  consumption: [...transaction_item],
  income: [...transaction_item],
  join_builder: ['space'],
  person: ['last_name'],
  organisation: ['city_location', 'parent_org', 'org_motivation'],
  vocational_training: ['organisation'],
  transaction: ['contract', 'contract_item'],
  space_member: ['request_date'],
  contract: ['renewal_period', 'renewal_period_unit'],
  organisation: ['org_country', 'state'],
  work_experience: ['industry'],

};

// removing columns from tables
export const gRemoveColumns = {
  credit_card: ['_person', '_issuing_org', '_license_org', '_card_type', '_card_no'],
  document: [
    'content_type',
    'file_blob',
    'filename',
    'concerned_person',
    'mail',
    'size',
  ],
  join_builder: ['space_name'],
  relation: ['relation'],
  organisation: [
    'modified',
    'org_password',
    'organisation_logo',
    // 'approved',
    'approval',
    'public_resp_area',
    'main_space',
    'active',
    'register_doc',
    'register_doc_type',
  ],
  vocational_training: ['school'],
  person: ['ethnic_group', 'person_picture'],
  address: ['address_type_identifier', 'street_direction', 'address_type'],
  contract: ['document'],
  space_member: ['is_space_admin', 'is_approved', 'is_disapproved'],
  contract_item: ['expense_category'],
  user_payment: ['user'],
  // income: ['offset_financial_account'],
  // consumption: ['offset_financial_account'],
};

// modify column name can point to a modified data. we therefore
// points this modified column to the right ones
// e.g. original_column = _modified_column
export const gModifyIds = {
  address: {
    country: '_country',
    state: '_state',
    city: '_city',
    street_direction: '_street_direction',
    address_type: '_address_type',
  },
  bank_account: {
    valid_from: '_valid_from',
    valid_to: '_valid_to',
    BIC: '_BIC',
    access_type: '_access_type',
    account_type: '_account_type',
    account_ref: '_account_ref',
  },
  citizenship: {
    citizen: '_citizen',
    nationality: '_nationality',
    valid_from: '_valid_from',
    valid_to: '_valid_to',
  },
  contact: {
    person: '_person',
    address: '_address',
    contact_type: '_contact_type',
    active: '_active',
  },
  credit_card: {
    person: '_person',
    issuing_org: '_issuing_org',
    license_org: '_license_org',
    number: 'card_no',
    card_type: '_card_type',
    account_ref: '_account_ref',
  },
  document: {
    document_type: 'doc_type',
    city: '_city',
    language: 'lang',
    issuing_org: '_issuing_org',
    issuing_date: '_issuing_date',
  },
  join_builder: {
    space_name: 'space',
    space: 'space',
  },

  organisation: {
    legal_form: '_legal_form',
    public: '_public',
    foundation: '_foundation',
    liquidation: '_liquidation',
    // country: "_country",
    state: '_state',
    active: '_active',
    organisation_type: '_organisation_type',
    city_location: '_city_location',
    register_institute: '_register_institute',
    revenue_currency: '_revenue_currency',
    org_country: '_org_country',
    industry: '_industry',
    no_of_employees: '_no_of_employees',
    revenue_approx: '_revenue_approx',
    legal_personality: '_legal_personality',
    non_profit: '_non_profit',
    org_motivation: '_org_motivation',
    parent_org: '_parent_org',
    transfer_date: '_transfer_date',
  },

  person: {
    birth_country: '_birth_country',
    ethnic_group: '_ethnic_group',
    title: '_title',
    height: '_height',
    eye_color: '_eye_color',
    religion: '_religion',
    sex: '_sex',
    marital_status: '_marital_status',
    height_unit: '_height_unit',
    date_of_birth: '_date_of_birth',
    date_of_death: '_date_of_death',
  },
  relation: {
    person: '_person',
    relation_to: '_relation_to',
    relation_part: '_relation_part',
    valid_from: '_valid_from',
    valid_to: '_valid_to',
  },
  residence: {
    resident: '_resident',
    address: '_address',
    valid_from: '_valid_from',
    valid_to: '_valid_to',
    principal_indicator: '_principal_indicator',
  },
  vaccination_history: {
    person: '_person',
    org: '_org',
    vaccination_date: '_vaccination_date',
  },
  companion_animal: {
    animal: '_animal',
    date_of_birth: '_date_of_birth',
    date_of_death: '_date_of_death',
    owner: '_owner',
  },
  // contract_item: {
  //   financial_category: "_financial_category",
  // },
  user_payment: {
    payment_date: '_payment_date',
    payment_coverage: '_payment_coverage',
  },
};

export const setDefaultColumn = {
  person: [
    'title',
    'last_name',
    'first_name',
    'other_names',
    'date_of_birth',
    'birth_place',
    'birth_country',
    'sex',
    'eye_color',
    'marital_status',
    'marit_st_last_change',
    'height',
    'height_unit',
    'religion',
    'date_of_death',
    'space',
  ],
  person_group: ['description', 'space'],
  companion_animal: [
    'animal',
    'name',
    'date_of_birth',
    'date_of_death',
    'owner',
    'space',
  ],
  relation: [
    'person',
    'relation_part',
    'relation_to',
    'valid_from',
    'valid_to',
    'space',
  ],
  contract: [
    'data_area',
    'org_indicator',
    'contractor',
    'description',
    'contract',
    'customer',
    'person',
    'person_is',
    'continuous',
    'start_date',
    'run_time',
    'run_time_unit',
    'end_date',
    'auto_renewal',
    'renewal_period',
    'renewal_period_unit',
    'notice_period',
    'notice_period_unit',
    'document_link',
    'space',
  ],
  contract_item: [
    'contract',
    'item',
    'fix_or_var',
    'financial_category',
    'account_category',
    'item_description',
    'fix_amount',
    'fix_period_unit',
    'currency',
    'var_service_rate',
    'var_service_unit',
    'subject',
    'item_reference',
    'counter_reading_b',
    'counter_reading_e',
    'space',
  ],
  organisation: [
    'name',
    'legal_form',
    'industry',
    'org_country',
    'state',
    'city_location',
    'foundation',
    'liquidation',
    'org_motivation',
    'public',
    'non_profit',
    'revenue_approx',
    'revenue_currency',
    'register_institute',
    'register_no',
    'legal_personality',
    'parent_org',
    'no_of_employees',
    'website',
  ],
  disease_history: ['person', 'disease', 'valid_from', 'valid_to', 'space'],
  vaccination_history: [
    'person',
    'vaccination_date',
    'org',
    'trade_name',
    'batch_no',
    'space',
  ],
  address: [
    'country',
    'post_area',
    'city',
    'street_name',
    'street_no',
    'street_no_suffix',
    'state',
    'local_muncipality',
    'building_name',
    'space',
  ],
  residence: [
    'address',
    'resident',
    'valid_from',
    'valid_to',
    'principal_indicator',
    'space',
  ],
  contact: [
    'active',
    'person',
    'contact_type',
    'email',
    'tel_country_code',
    'tel_area_code',
    'tel_number',
    'address',
    'website',
    'space',
  ],
  citizenship: ['citizen', 'nationality', 'valid_from', 'valid_to', 'space'],
  work_experience: [
    'person',
    'organisation',
    'valid_from',
    'valid_to',
    'title_description',
    'profession',
    'industry',
    'space',
  ],
  membership: ['person', 'organisation', 'valid_from', 'valid_to', 'space'],
  bank_account: [
    'account_type',
    'account_no',
    'BIC',
    'valid_from',
    'valid_to',
    'currency',
    'access_type',
    'organisation',
    'account_ref',
    'IBAN',
    'is_active',
    'space',
  ],
  credit_card: [
    'person',
    'license_org',
    'card_type',
    'number',
    'valid_thru',
    'issuing_org',
    'account_ref',
    'cvc',
    'space',
  ],
  income: [
    'year',
    'period',
    'date',
    'person',
    'amount',
    'currency',
    'amount_sc',
    'sp_currency',
    'financial_account',
    'offset_account',
    'description',
    'contract',
    'reversed',
    'reverse_doc_no',
    'reversal',
    'original_doc_no',
    'quantity',
    'measurement_unit',
    'account_category',
    'offset_account_category',
    'partner',
    'org_indicator',
    'transaction',
    'line',
    'position_link',
    'dc_indicator',
    'space',
  ],
  consumption: [
    'year',
    'period',
    'date',
    'person',
    'amount',
    'currency',
    'amount_sc',
    'sp_currency',
    'financial_account',
    'offset_account',
    'description',
    'contract',
    'reversed',
    'reverse_doc_no',
    'reversal',
    'original_doc_no',
    'quantity',
    'measurement_unit',
    'account_category',
    'offset_account_category',
    'partner',
    'org_indicator',
    'transaction',
    'line',
    'position_link',
    'dc_indicator',
    'space',
  ],

  attachment_rule: [
    'description',
    'data_area',
    'document_type',
    'person',
    'organisation',
    'language',
    'reference_year',
    'reference_month',
    'space',
  ],
  further_training: [
    'person',
    'category',
    'valid_from',
    'valid_to',
    'organisation',
    'graduation_goal',
    'school_subject',
    'last_class',
    'degree_achieval',
    'final_grade',
    'study_subject',
    'vocschool_subject',
    'profession',
    'industry',
    'further_training',
    'language',
    'language_verbal',
    'language_literacy',
    'space',
  ],
  academic_studies: [
    'person',
    'category',
    'valid_from',
    'valid_to',
    'organisation',
    'graduation_goal',
    'school_subject',
    'last_class',
    'degree_achieval',
    'final_grade',
    'study_subject',
    'vocschool_subject',
    'profession',
    'industry',
    'further_training',
    'language',
    'language_verbal',
    'language_literacy',
    'space',
  ],
  general_education: [
    'person',
    'category',
    'valid_from',
    'valid_to',
    'organisation',
    'graduation_goal',
    'school_subject',
    'last_class',
    'degree_achieval',
    'final_grade',
    'study_subject',
    'vocschool_subject',
    'profession',
    'industry',
    'further_training',
    'language',
    'language_verbal',
    'language_literacy',
    'space',
  ],
  languages: [
    'person',
    'category',
    'valid_from',
    'valid_to',
    'organisation',
    'graduation_goal',
    'school_subject',
    'last_class',
    'degree_achieval',
    'final_grade',
    'study_subject',
    'vocschool_subject',
    'profession',
    'industry',
    'further_training',
    'language',
    'language_verbal',
    'language_literacy',
    'space',
  ],
  pre_school: [
    'person',
    'category',
    'valid_from',
    'valid_to',
    'organisation',
    'graduation_goal',
    'school_subject',
    'last_class',
    'degree_achieval',
    'final_grade',
    'study_subject',
    'vocschool_subject',
    'profession',
    'industry',
    'further_training',
    'language',
    'language_verbal',
    'language_literacy',
    'space',
  ],
  vacational_school: [
    'person',
    'category',
    'valid_from',
    'valid_to',
    'organisation',
    'graduation_goal',
    'school_subject',
    'last_class',
    'degree_achieval',
    'final_grade',
    'study_subject',
    'vocschool_subject',
    'profession',
    'industry',
    'further_training',
    'language',
    'language_verbal',
    'language_literacy',
    'space',
  ],
  vocational_training: [
    'person',
    'category',
    'valid_from',
    'valid_to',
    'organisation',
    'graduation_goal',
    'school_subject',
    'last_class',
    'degree_achieval',
    'final_grade',
    'study_subject',
    'vocschool_subject',
    'profession',
    'industry',
    'further_training',
    'language',
    'language_verbal',
    'language_literacy',
    'space',
  ],
  automobile: [
    'means_of_transport',
    'auto_brand',
    'model_series',
    'model_series_type',
    'year_of_manufacture',
    'mot_color',
    'energy_source',
    'max_speed',
    'measurement_unit',
    'power',
    'cubic_capacity',
    'no_of_doors',
    'no_of_seats',
    'vehicle_no',
    'initial_reg_date',
    'space',
  ],
  auto_registration: [
    'auto_mobile',
    'person',
    'registration_org',
    'valid_from',
    'valid_to',
    'licence_plate',
    'document',
    'space',
  ],
};
