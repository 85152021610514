import axios from '../../axios';
import * as types from '../types';
import { setMsgInfo } from '..';
import filterError from '../common/filterError';
import API from '../api';

const baseUrl = API + '/task_monitor';

export const getAllTaskMonitor = () => {
  return async (dispatch) => {
    dispatch({ type: types.TASK_MONITOR_LOADING });
    try {
      const { data } = await axios.get(`${baseUrl}`);
      console.log("data", data)
      dispatch({
        type: types.FETCH_TASK_MONITOR_SUCCESS,
        payload: data,
      });
      return data;
    } catch (err) {
      const errors = filterError(err);
      dispatch({ type: types.TASK_MONITOR_FAILURE, payload: errors });
      dispatch(
        setMsgInfo({
          success: false,
          msg: errors,
        }),
      );
      throw err;
    }
  };
};