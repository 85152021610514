import React from 'react';
import compose from 'lodash/fp/compose';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

import { withTranslation, withUserPreferences } from 'components/hoc';

const CustomTooltip = ({ active, payload, label, tr, fn }) => {
  if (active) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: 'var(--admincat-color-grey-4)',
          color: `var(--admincat-color-grey-2)`,
          padding: '10px',
          width: 'auto',
        }}
      >
        <p style={{ fontSize: 14, color: `var(--admincat-color-grey-2)` }}>
          {tr(label)}
        </p>

        {payload.map((entry, index) => {
          let tooltipLabel = entry.name;

          if (entry.name === 'loss') {
            tooltipLabel = Number(entry.value) > 0 ? 'profit' : 'loss';
          }

          return (
            <h5
              key={`item-${index}`}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                color: entry.fill,
                marginBottom: 5,
                fontSize: 10,
              }}
            >
              <span>{tr(tooltipLabel)}:</span>

              <span style={{ textAlign: 'right' }}>{fn(entry.value)}</span>
            </h5>
          );
        })}
      </div>
    );
  }

  return null;
};

const IncomeExpenseBarChart = ({ data, tr, fn }) => {
  return (
    <ResponsiveContainer width="100%" height="70%">
      <BarChart data={data}>
        <XAxis dataKey="name" fontSize={10} hide />

        <YAxis fontSize={12} width={30} hide />

        <Tooltip content={<CustomTooltip tr={tr} fn={fn} />} />

        <Bar dataKey="income" fill="#FCAB10" barSize={30} radius={10} />

        <Bar dataKey="expenses" fill="#317ED9" barSize={30} radius={10} />

        <Bar
          dataKey="loss"
          fill={Number(data[0]?.loss || 0) < 0 ? '#FF0000' : '#00FF00'}
          barSize={30}
          radius={10}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default compose(withTranslation, withUserPreferences)(IncomeExpenseBarChart);
