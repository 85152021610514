export const convertToBytes = (sizeString) => {
  const units = {
    B: 1,
    KB: 1024,
    MB: 1024 * 1024,
    GB: 1024 * 1024 * 1024,
  };
  const [size, unit] = sizeString.split(/(\d+(?:\.\d+)?)/).filter(Boolean);
  return parseFloat(size) * units[unit];
};

export const MAX_STORAGE_SIZE = 100 * 1024 * 1024;
