/* eslint-disable */
import React from 'react';
import compose from 'lodash/fp/compose';
import {
  withTranslation,
  withCustomRouter,
  withUserPreferences,
} from 'components/hoc';
import MyDataContentTemplate from 'components/Dashboard/MyData/common/MyDataContentTemplate';
import LottieLoader from 'components/common/LottieLoader';
import { withWorkExperienceInfo } from 'components/hoc/WorkExperienceInfo';

const WorkExperience = (props) => {
  // Add filterableColumns configuration
  const filterableColumns = [
    {
      columnId: 'person',
      columnDataType: 'text',
    },
    {
      columnId: 'organisation',
      columnDataType: 'text',
    },
    {
      columnId: 'valid_from',
      columnDataType: 'date',
    },
    {
      columnId: 'valid_to',
      columnDataType: 'date',
    },
    {
      columnId: 'title_description',
      columnDataType: 'text',
    },
    {
      columnId: 'profession',
      columnDataType: 'text',
    },
    {
      columnId: 'industry',
      columnDataType: 'text',
    },
  ];

  const mapFields = () => {
    const { workExperienceInfo, trObj, fd } = props;
    console.log('workExperienceInfo', workExperienceInfo);
    return workExperienceInfo.data.map((data) => {
      return {
        ...data,
        id: data.id,
        valid_from: fd(data?.valid_from),
        valid_to: fd(data?.valid_to),
        person:
          data?.person_detail?.first_name + ' ' + data?.person_detail?.last_name,
        profession: trObj(data?.profession_detail),
        organisation: data?.organisation_detail?.name,
        industry: trObj(data?.organisation_detail?.industry_type_detail),
      };
    });
  };

  const { loaded, deleteBulkWorkExperienceInfo, gotoCreatePath } = props;

  if (!loaded) {
    return (
      <>
        <LottieLoader />
      </>
    );
  }

  return (
    <MyDataContentTemplate
      tableName="work_experience"
      columns={[]}
      data={mapFields()}
      handleCreateForm={gotoCreatePath}
      bulkDelete={deleteBulkWorkExperienceInfo}
      filterableColumns={filterableColumns}
    />
  );
};

export default compose(
  withTranslation,
  withWorkExperienceInfo,
  withCustomRouter,
  withUserPreferences,
)(WorkExperience);
