import React from 'react';
import compose from 'lodash/fp/compose';
import {
  withUserPreferences,
  withTranslation,
  withCompanionAnimalInfo,
  withCustomRouter,
} from 'components/hoc';
import MyDataContentTemplate from '../../common/MyDataContentTemplate';
import LottieLoader from 'components/common/LottieLoader';

const CompanionAnimal = (props) => {
  // Add filterableColumns configuration
  const filterableColumns = [
    {
      columnId: 'name',
      columnDataType: 'text',
      label: 'Name',
    },
    {
      columnId: '_animal',
      columnDataType: 'text',
      label: 'Animal',
    },
    {
      columnId: '_date_of_birth',
      columnDataType: 'date',
      label: 'Date of Birth',
    },
    {
      columnId: '_date_of_death',
      columnDataType: 'date',
      label: 'Date of Death',
    },
    {
      columnId: '_owner',
      columnDataType: 'text',
      label: 'Owner',
    },
    {
      columnId: 'space',
      columnDataType: 'text',
      label: 'Space',
    },
  ];

  const mapFields = () => {
    const { companionAnimalInfo, trObj, fd } = props;
    return companionAnimalInfo.data.map((data) => {
      return {
        ...data,
        space_name: data.space,
        _animal: trObj(data.animal_detail),
        _date_of_birth: fd(data.date_of_birth),
        _date_of_death: fd(data.date_of_death),
        _owner: trObj(data.owner),
      };
    });
  };

  const { deleteBulkCompanionAnimalInfo, gotoCreatePath, loaded } = props;

  if (!loaded) {
    return (
      <>
        <LottieLoader />
      </>
    );
  }

  return (
    <React.Fragment>
      <MyDataContentTemplate
        tableName="companion_animal"
        columns={[]}
        data={mapFields()}
        toggleForm={gotoCreatePath}
        bulkDelete={deleteBulkCompanionAnimalInfo}
        filterableColumns={filterableColumns}
      />
    </React.Fragment>
  );
};

export default compose(
  withTranslation,
  withUserPreferences,
  withCompanionAnimalInfo,
  withCustomRouter,
)(CompanionAnimal);
