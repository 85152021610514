import React from 'react';
import {
  withTranslation,
  withUserPreferences,
  withCustomRouter,
  withTaskMonitor,
} from 'components/hoc';
import LottieLoader from 'components/common/LottieLoader';
import TaskMonitorContentTemplate from 'components/Admin/common/TaskMonitorContentTemplate';
import compose from 'lodash/fp/compose';

const TaskList = (props) => {
  const { 
    loaded, 
    taskMonitor, 
    // bulkDeleteProposals,
} = props;

  if (!loaded) {
    return (
      <>
        <LottieLoader />
      </>
    );
  }

  console.log("tasskMonitor", taskMonitor)
  console.log("task Monitor",  taskMonitor?.taskMonitorData)


//   const mapFields = () => {
//     const tasks = taskMonitor?.taskMonitorData;
//     // const adminDashboardData = adminDashboard?.dashboardData;
//     return tasks.map((task) => {
//       return {
//         ...task,
//       };
//     });
//   };

  return (
    <>
      <TaskMonitorContentTemplate
        tableName="task_monitor"
        columns={[]}
        // data={mapFields()}
        // bulkDelete={bulkDeleteProposals}
      />
    </>
  );
};

export default compose(
  withTranslation,
  withCustomRouter,
  withUserPreferences,
  withTaskMonitor,
)(TaskList);
