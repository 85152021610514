import React from 'react';
import compose from 'lodash/fp/compose';
import { get } from 'lodash';
import { connect } from 'react-redux';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { isEmptyValue } from 'components/utils/checkValue';
import * as userLayoutChoiceActions from 'actions/userLayoutChoice';
import { withFieldCatalog } from '../Preload/FieldCatalog';
import {
  tableLabels,
  gExtraColumns,
  gRemoveColumns,
  gModifyIds,
  setDefaultColumn,
} from './tableLayoutProps';

const gExcludeColumns = ['id', 'creator', 'created_at', 'updated_at'];

export const withUserLayoutChoice = (WrapperComponent) => {
  class UserLayoutChoice extends React.Component {
    static loaded = false;

    componentDidMount() {
      const { getUserLayoutChoice } = this.props;
      const user = localStorage.user;
      if (UserLayoutChoice.loaded) return;
      if (user) {
        getUserLayoutChoice({ user: JSON.parse(user).user_id });
        UserLayoutChoice.loaded = true;
      }
    }

    getUserLayoutChoiceOptions = () => {
      const { userLayoutChoice } = this.props;
      return userLayoutChoice;
    };

    getUserLayoutChoiceByTable = (targetTable, pExcludeColumns) => {
      const tables = this.getUserLayoutChoiceOptions();
      if (!targetTable) return tables.data;
      const excludeColumns = [...pExcludeColumns, ...gExcludeColumns];
      return tables.data
        .filter(({ frontend_table }) => frontend_table === targetTable)
        .filter(({ field }) => !excludeColumns.includes(field))
        .map((data) => ({ ...data, label: this.getLabel(data.field) }));
    };

    mapColumnsToLayout = (tableColumns, defaultColumns, pExcludeColumns) => {
      const [ids, labels] = defaultColumns.reduce(
        ([ids, labels], { id, label }) => {
          if (isEmptyValue(id) && isEmptyValue(label)) {
            return [ids, labels];
          }
          const newId = id.startsWith('_') ? id.substring(1) : id;
          const newIds = { ...ids, [newId]: id };
          const newLabels = { ...labels, [newId]: label };
          return [newIds, newLabels];
        },
        [{}, {}]
      );
      const excludeColumns = [...pExcludeColumns, ...gExcludeColumns];

      return tableColumns
        .map((column, index) => ({
          id: this.getId(column),
          selected: !!ids[column],
          order_no: index,
          label: ids[column] ? labels[column] : this.getLabel(column),
          field: column?.replace('_id', ''),
        }))
        .filter(({ field }) => !excludeColumns.includes(field));
    };

    updateColumnOrder = (columns, sourceIndex, destinationIndex) => {
      const result = Array.from(columns);
      const [removed] = result.splice(sourceIndex, 1);
      result.splice(destinationIndex, 0, removed);
      
      // Update order_no for all columns
      return result.map((column, index) => ({
        ...column,
        order_no: index,
      }));
    };

    getLayoutColumnProps = (...args) => {
      let [
        frontendTableName,
        backendTableName,
        defaultColumns,
        pIncludeColumns,
        pExcludeColumns,
        pModifyIds,
      ] = args;

      const includeColumns = [
        ...(pIncludeColumns || []),
        ...get(gExtraColumns, frontendTableName, []),
      ];

      const excludeColumns = [
        ...(pExcludeColumns || []),
        ...get(gRemoveColumns, frontendTableName, []),
      ];

      const modifyIds = {
        ...(pModifyIds || {}),
        ...get(gModifyIds, frontendTableName, {}),
      };

      const columnsToReorder = [...get(setDefaultColumn, frontendTableName, [])];

      const { getFieldCatalogByTable } = this.props;
      const tableColumns = getFieldCatalogByTable(backendTableName);
      const layoutChoice = this.getUserLayoutChoiceByTable(
        frontendTableName,
        excludeColumns
      );

      const mapppedColumns = this.mapColumnsToLayout(
        tableColumns,
        defaultColumns,
        excludeColumns
      );

      // Use layout choice if available, otherwise use mapped columns
      let initLayoutColumns = isEmptyValue(layoutChoice)
        ? mapppedColumns
        : layoutChoice;

      // Apply default column order from setDefaultColumn if no layout choice exists
      if (isEmptyValue(layoutChoice) && columnsToReorder.length > 0) {
        initLayoutColumns = initLayoutColumns.sort((a, b) => {
          const indexA = columnsToReorder.indexOf(a.field);
          const indexB = columnsToReorder.indexOf(b.field);
          if (indexA !== -1 && indexB !== -1) return indexA - indexB;
          if (indexA !== -1) return -1;
          if (indexB !== -1) return 1;
          return a.order_no - b.order_no;
        });
        
        // Update order_no to match the new sort order
        initLayoutColumns = initLayoutColumns.map((column, index) => ({
          ...column,
          order_no: index,
        }));
      }

      const fields = initLayoutColumns.map(({ field, label }) => field || label);
      const extraColumns = includeColumns.filter((field) => !fields.includes(field));

      let layoutColumns = [
        ...initLayoutColumns,
        ...extraColumns.map((column, index) => ({
          id: this.getId(column),
          selected: false,
          order_no: initLayoutColumns.length + index,
          label: this.getLabel(column),
          field: column?.replace('_id', ''),
        })),
      ];

      // Sort by order_no first, then by selected status
      layoutColumns = layoutColumns
        .sort((a, b) => a.order_no - b.order_no)
        .sort((a, b) => b.selected - a.selected)
        .map(({ field, ...rest }) => {
          const label = this.getLabel(field);
          const fieldId = this.getId(field);
          return {
            ...rest,
            field,
            label: get(tableLabels, `${frontendTableName}.${fieldId}`, label),
          };
        });

      const selectedColumns = layoutColumns.filter(
        ({ selected }) => selected === true
      );

      const columns = isEmptyValue(selectedColumns)
        ? mapppedColumns
        : selectedColumns;

      return {
        columns: [...this.updateColumns(columns, modifyIds), {}],
        layoutColumns,
        updateColumnOrder: this.updateColumnOrder, // Expose the updateColumnOrder method
      };
    };

    getId = (column) => {
      return column.includes('_id') ? `_${column?.replace('_id', '')}` : column;
    };

    getLabel = (column) => {
      return column?.replace('_id', '').replaceAll('_', ' ');
    };

    updateColumns = (columns, modifyIds) => {
      return columns.map(({ id: _id, field, ...rest }) => {
        const fieldId = this.getId(field);
        const id = get(modifyIds, fieldId, fieldId);
        return {
          ...rest,
          id,
          field,
        };
      });
    };

    render() {
      return (
        <WrapperComponent
          {...this.props}
          getUserLayoutChoiceByTable={this.getUserLayoutChoiceByTable}
          getUserLayoutChoiceOptions={this.getUserLayoutChoiceOptions}
          mapColumnsToLayout={this.mapColumnsToLayout}
          getLayoutColumnProps={this.getLayoutColumnProps}
        />
      );
    }
  }

  const mapStateToProps = ({ userLayoutChoice }) => ({
    userLayoutChoice,
  });

  const WithUserLayoutChoice = connect(mapStateToProps, {
    ...userLayoutChoiceActions,
  })(UserLayoutChoice);

  hoistNonReactStatics(WithUserLayoutChoice, WrapperComponent);
  return compose(withFieldCatalog)(WithUserLayoutChoice);
};