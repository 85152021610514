import React from 'react';
import compose from 'lodash/fp/compose';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

import { withTranslation, withUserPreferences } from 'components/hoc';

const CustomTooltip = ({ active, payload, label, tr, fn }) => {
  const contentWidth = payload.reduce((maxWidth, entry) => {
    const textWidth = entry.name.length + String(entry.value).length;
    return textWidth > maxWidth ? textWidth : maxWidth;
  }, 0);

  if (active) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: 'var(--admincat-color-grey-4)',
          color: `var(--admincat-color-grey-2)`,
          padding: '10px',
          width: 'auto',
        }}
      >
        <p style={{ fontSize: 14, color: `var(--admincat-color-grey-2)` }}>
          {tr(label)}
        </p>

        {payload.map((entry, index) => (
          <h5
            key={`item-${index}`}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
              color: entry.fill,
              marginBottom: 5,
              fontSize: 10,
            }}
          >
            <span>{tr(entry.name)}:</span>

            <span style={{ textAlign: 'right' }}>{fn(entry.value)}</span>
          </h5>
        ))}
      </div>
    );
  }

  return null;
};

const AssetsVsLiabilities = ({ data, tr, fn }) => {
  return (
    <ResponsiveContainer width="100%" height="70%">
      <BarChart data={data}>
        <XAxis dataKey="name" fontSize={10} hide />

        <YAxis fontSize={12} width={30} hide />

        <Tooltip content={<CustomTooltip tr={tr} fn={fn} />} />

        <Bar dataKey="assets" fill="#FCAB10" barSize={30} radius={10} />

        <Bar dataKey="liability." fill="#317ED9" barSize={30} radius={10} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default compose(withTranslation, withUserPreferences)(AssetsVsLiabilities);
