export const deu = {
  'create person, person group and assign a space group':
    'Person und Personengruppe anlegen und als Space-Gruppe auswählen',
  'persons are central in your workspace - the members of your family or household as well as relatives and friends. therefore start to create the persons that you need. group them as you like as person groups. a special group is the one that you assign to your space:all financial data on the dashboard refer to that group. and on the dashboard card "space group" you even see the pictures of its members.':
    'Personen sind zentral in Ihrem Space - die Mitglieder Ihrer Familie oder Ihres Haushalts sowie Verwandte und Freunde. Beginnen Sie deshalb damit, die Personen zu erstellen, die Sie benötigen. Gruppieren Sie sie nach Belieben in Personengruppen. Eine besondere Gruppe ist diejenige, die Sie Ihrem Space zuordnen: Alle Finanzdaten auf dem Dashboard beziehen sich auf diese Gruppe. Und auf der Dashboard-Karte "Space-Gruppe" sehen Sie sogar die Bilder der Mitglieder.',

  'propose creation': 'Anlage vorschlagen',

  'file uploaded successfully': 'Datei erfolgreich hochgeladen',
  'error uploading file. please try again.':
    'Fehler beim Hochladen der Datei. Bitte versuchen Sie es erneut.',
  'error downloading template, please try again later':
    'Fehler beim Herunterladen der Vorlage, bitte versuchen Sie es später noch einmal',
  'please select a file to upload': 'Bitte wählen Sie eine Datei zum Hochladen',
  'file size exceeds': 'Dateigröße überschreitet',
  'mb. please choose a smaller file.': 'MB. Bitte wählen Sie eine kleinere Datei.',
  'failed to upload data': 'Daten können nicht hochgeladen werden',
  'download template': 'Vorlage herunterladen',
  'upload income data': 'Einkommensdaten hochladen',
  'upload expense data': 'Kostendaten hochladen',
  'upload data': 'Daten hochladen',
  'uploading...': 'Hochladen...',
  upload: 'Hochladen',
  'click to upload': 'Zum Hochladen klicken',

  'for subscription of': 'Bei einem Abonnement von',
  'month(s)': 'Monaten',
  'you will be charged at': 'werden Ihnen',
  'per month': 'pro Monat berechnet',
  'local court ': 'Amtsgericht ',

  automobile: 'Automobil',
  'auto mobile': 'Automobil',

  'means of transport category': 'Fortbewegungsmittel-Kategorie',
  'means of transport': 'Fortbewegungsmittel',
  brand: 'Marke',
  'auto brand': 'Auto-Marke',
  'choose brand': 'Marke wählen',
  'model series': 'Modell',
  'model series type': 'Modell Serie Typ',
  'enter model series type': 'Modellreihe Typ eingeben',
  'year of manufacture': 'Jahr der Herstellung',
  'manufacture year': 'Jahr der Herstellung',
  'choose manufacture year': 'Herstellungsjahr wählen',
  'type (as model specification)': 'Typ (als Modellspezifikation)',
  'construction year': 'Baujahr',
  color: 'Farbe',
  'choose color': 'Farbe wählen',
  'motor color': 'Motorfarbe',
  'energy source': 'Energiequelle',
  'choose energy source': 'Energiequelle wählen',
  'fuel or energy source': 'Kraftstoff oder Energiequelle',
  'maximum speed': 'Höchstgeschwindigkeit',
  'enter maximum speed': 'Höchstgeschwindigkeit eingeben',
  'max speed': 'Höchstgeschwindigkeit',
  measurement: 'Maßeinheit',
  'choose measurement unit': 'Maßeinheit wählen',
  power: 'Leistung',
  'rated power': 'Leistung',
  'rated power in kw': 'Leistung in kW',
  'cubic capacity': 'Hubraum',
  'cubic capacity in cm³': 'Hubraum in cm³',
  'no of doors': 'Anzahl Türen',
  'number of doors': 'Anzahl Türen',
  'enter number of doors': 'Anzahl der Türen eingeben',
  'no of seats': 'Anzahl Sitze',
  'number of seats': 'Anzahl Sitze',
  'enter number of seats': 'Anzahl der Sitze eingeben',
  'vehicle identification number': 'Fahrgestellnummer',
  'initial reg date': 'Erstzulassungsdatum',
  'initial registration date': 'Erstzulassungsdatum',

  'auto registration': 'Auto-Registrierung',
  'registration org': 'Registrierungsorg',
  'licence plate': 'Nummernschild',
  'registration organization': 'Organisation der Registrierung',
  'select registration organization': 'Registrierungsorganisation auswählen',
  'enter licence plate': 'Nummernschild eingeben',
  'select automobile': 'Automobil auswählen',
  'select person': 'Person auswählen',

  'choose contract': 'Vertrag wählen',
  'transaction partner indicator': 'Transaktionspartnertyp',
  'transaction partner': 'Transaktion partner',
  reverse: 'Stornieren',
  'organisation function': 'Organisationsfunktion',
  'org. indicator': 'Org. Indikator',
  membership: 'Mitgliedschaft',

  'change username': 'Benutzernamen ändern',
  'are you sure you want to change username?':
    'Sind Sie sicher, dass Sie Ihren Benutzernamen ändern möchten?',
  'your username has been changed': 'Ihr Benutzername wurde geändert',

  'add or create your favourite organizations':
    'Hinzufügen oder Erstellen Ihrer Organisationen-Favoriten',
  'organizations are (like persons) central in your workspace – the ones you worked for or currently do business with. many organizations (for germany) exist already in our database. search and add a selection as your favourites. once done you can use these anywhere in your workspace. you can also create new organizations, if not yet existent.':
    'Organisationen sind (wie Personen) zentral in Ihrem Arbeitsbereich - die, für die Sie gearbeitet haben oder mit denen Sie derzeit Geschäfte machen. Viele Organisationen (für Deutschland) existieren bereits in unserer Datenbank. Suchen Sie und fügen Sie eine Auswahl als Ihre Favoriten hinzu. Danach können Sie diese überall in Ihrem Arbeitsbereich verwenden. Sie können auch neue Organisationen erstellen, wenn diese noch nicht existieren.',

  'upload and administer documents': 'Dokumente hochladen und verwalten',
  'passports, birth certificates, diploma etc. – documents are important. under the main menu option documents you can click on the upload area in the center and chose the documents from your drive. use the filter on the left or the full-text search bar on top and review the document details on the right. edit, view or delete each document individually.':
    'Pässe, Geburtsurkunden, Diplome usw. - Dokumente sind wichtig. Unter dem Hauptmenüpunkt Dokumente können Sie auf den Upload-Bereich in der Mitte klicken und die Dokumente von Ihrem Laufwerk auswählen. Verwenden Sie den Filter auf der linken Seite oder die Volltextsuchleiste oben und sehen Sie sich die Dokumentendetails auf der rechten Seite an. Sie können jedes Dokument einzeln bearbeiten, anzeigen oder löschen.',

  'e-mailbox and attachment rules': 'E-Mail-Postfächer und Regeln für Anhänge',
  'for each space admincat provides you with an own space email address. send and receive emails normally, mark them as read, print or export them. you can also assign them to data areas (finance, mobility, etc.) if you like to use that for filtering and sorting. and if you define attachment rules,incoming attachments are stored in the document area automatically, enriched with data according to the individual rules.':
    'Für jeden Space stellt Ihnen AdminCat eine eigene Space-E-Mail-Adresse zur Verfügung. Senden und empfangen Sie E-Mails ganz normal, markieren Sie sie als gelesen, drucken oder exportieren Sie sie. Sie können sie auch Datenbereichen (Finanzen, Mobilität, etc.) zuordnen, wenn Sie diese zum Filtern und Sortieren verwenden möchten. Und wenn Sie Regeln für Anhänge definieren, werden eingehende Anhänge automatisch im Dokumentenbereich abgelegt, angereichert mit Daten gemäß den individuellen Regeln.',

  contracts: 'Verträge',
  'a contract is the foundation for financial transactions – and a special type of document. here you can enter your contract details, link it to the involved persons and organizations as well as to a underlying document, that was saved in the documents area.':
    'Ein Vertrag ist die Grundlage für finanzielle Transaktionen - und eine besondere Art von Dokument. Hier können Sie Ihre Vertragsdetails eingeben, ihn mit den beteiligten Personen und Organisationen sowie mit einem zugrunde liegenden Dokument verknüpfen, das im Dokumentenbereich gespeichert wurde.',

  'the dashboard': 'Das Dashboard',
  'displays financial key figures and charts for your space – according to your space group and your chosen space currency. it also informs about storage space usage, number of stored documents, unread emails and important notifications concerning documents and contracts.':
    'Das Dashboard zeigt Finanzkennzahlen und Diagramme für Ihren Space an - je nach Space-Gruppe und der von Ihnen gewählten Space-Währung. Es informiert Sie auch über die Nutzung des Speicherplatzes, die Anzahl der gespeicherten Dokumente, ungelesene E-Mails und wichtige Benachrichtigungen zu Dokumenten und Verträgen.',

  'financial transactions in 53 currencies': 'Finanztransaktionen in 53 Währungen',
  'post your income and consumption transactions like a professional using a double-entry accounting with a variety of payment sources. foreign currencies get translated into your central workspace currency using a daily updated exchange rate. and if a posting is incorrect, just reverse it again whenever you like.':
    'Buchen Sie Ihre Einnahmen und Ausgaben wie ein Profi mit einer doppelten Buchführung und einer Vielzahl von Zahlungsquellen. Fremdwährungen werden anhand eines täglich aktualisierten Wechselkurses in Ihre zentrale Space-Währung umgerechnet. Und wenn eine Buchung falsch ist, können Sie sie jederzeit wieder stornieren.',

  'missing values -> propose new entries':
    'Fehlende Werte -> Neue Einträge vorschlagen',
  'the list of entry options is not complete in certain fields? well, there is a way how you can help us to improve this quickly: just propose a new entry and we will add the entry after a short review period.':
    'Die Liste der Eingabemöglichkeiten ist in bestimmten Feldern nicht vollständig? Nun, es gibt eine Möglichkeit, wie Sie uns helfen können, dies schnell zu verbessern: Schlagen Sie einfach einen neuen Eintrag vor und wir werden ihn nach einer kurzen Überprüfungszeit hinzufügen.',

  'select and make subscription': 'Wählen Sie Ihr Abonnement',
  'we are flexible, just pay for the months you want':
    'Sie sind flexibel, zahlen Sie einfach monatsweise',
  'you save 21% with this option. choose the number of periods':
    'Sie sparen 21% mit dieser Option. Wählen Sie nun die Anzahl der Jahre',
  monthly: 'Monatlich',
  yearly: 'Jährlich',
  'pay now': 'Jetzt zahlen',
  'select month(s)': 'Monat(e) auswählen',
  'select year(s)': 'Jähr(e) auswählen',
  'select duration': 'Dauer auswählen',
  'your payment choice:': 'Ihre gewählte Zahlung:',
  month: 'Monat',
  year: 'Jahr',
  months: 'Monate',
  years: 'Jahre',

  'all entries': 'Alle Einträge',
  'issuing person': ' Ausstellende Person',
  'issuing date': 'Ausstellungsdatum',
  'reference month': 'Referenzmonat',
  'reference year': 'Referenzjahr',
  'valid range': 'Gültigkeitszeitraum',
  'end date': 'Endtermin',
  'first name': 'Vorname',
  'last name': 'Nachname',
  'other names': 'Weitere Namen',
  'birth place': 'Geburtsort',
  'birth country': 'Geburtsland',
  'you are currently on the free trial period plan - still for ':
    'Sie nutzen aktuell die kostenlose Testversion - noch ',
  'you are currently on the subscription plan - still for ':
    'Sie nutzen aktuell das Abonnement - noch ',
  'the total size of the selected files exceeds the maximum allowed size of':
    'Die ausgewählten Dateien überschreiten die maximal zulässige Größe von',
  'one or more files exceed the maximum allowed file size of':
    'Eine oder mehrere Dateien überschreiten die maximal zulässige Dateigröße von',
  ' days': ' Tage',
  'death date': 'Todesdatum',
  '1 year': '1 Jahr',
  '3 years': '3 Jahre',
  '5 years': '5 Jahre',
  '10 years': '10 Jahre',
  'all time': 'Alle Zeit',

  'ethnic group': 'Ethnische Gruppe',
  sex: 'Geschlecht',
  'marital status': 'Beziehungsstatus',
  'marital status last change': 'Letzte Status-Änderung',
  religion: 'Religionszugehörigkeit',
  'eye color': 'Augenfarbe',
  'height info': 'Größeninformation',
  height: 'Größe',
  'height unit': 'Maßeinheit',
  unit: 'Einheit',
  cancel: 'Abbrechen',
  // 'submit': 'Speichern',
  submit: 'Senden',
  ' name': 'Name',
  'contact us': 'Kontaktieren Sie uns',
  title: 'Titel',
  country: 'Land',
  'related person': 'Betreffende',
  state: 'Bundesland',
  'city/town': 'Stadt',
  'local municipality': 'Ortsteil',
  'postal area': 'Postleitzahl',
  'post area': 'Postleitzahl',
  'building name': 'Gebäudename',
  'address type': 'Adressart',
  'address type id': 'Adressart ID',
  'street direction': 'Straßenrichtung',
  'street name': 'Straße',
  'street number': 'Straßennummer',
  'street no': 'Straßennr.',
  'street number suffix': 'Straßennr.-Suffix',
  'street no suffix': 'Straßennr.-Suffix',
  'principal indicator': 'Hauptadresse',
  'validity period': 'Gültigkeitszeitraum',
  prev: 'Vorige',
  next: 'Nächste',
  add: 'Hinzufügen',
  'select column': 'Spalte auswählen',
  actions: 'Aktionen',
  'add row': 'Zeile hinzufügen',
  before: 'Vor',
  after: 'Nach',
  on: 'Auf',
  range: 'Bereich',
  contains: 'Enthält',
  'you see this page, because your subscription has ended on':
    'Sie sehen diese Seite, weil Ihr Abonnement am',
  'please renew your subscription, if you like to keep using our services. thank you!':
    'abgelaufen ist. Bitte erneuern Sie Ihr Abonnement, wenn Sie unsere Dienste weiterhin nutzen möchten. Vielen Dank!',
  exact: 'Genau',
  'select condition': 'Bedingung auswählen',
  value: 'Wert',
  'start value': 'Startwert',
  'end value': 'Endwert',
  filter: 'Filter',
  'apply filter': 'Filter anwenden',
  'clear filter': 'Filter löschen',
  change: 'Ändern',
  'p.o. box': 'Postfach',
  'pdf files': 'PDF-Dateien',
  'relation part': 'Rolle',
  'relation to': 'Beziehung zu',
  'valid from': 'Gültig von',
  'valid to': 'Gültig bis',
  'relationship period': 'Beziehungszeitraum',
  'contact type': 'Kontaktart',
  'country telephone code': 'Ländervorwahl',
  'area telephone code': 'Vorwahl',
  'telephone number': 'Telefonnummer',
  active: 'Aktiv',
  inactive: 'Inaktiv ',
  deactive: 'Inaktiv',
  address: 'Adresse',
  email: 'Email',
  cockpit: 'Cockpit',
  'fill-out': 'Ausfüllen',
  overview: 'Übersicht',
  upload: 'Hochladen',
  filter: 'Filter',
  'filter form': 'Filter Formular',
  'issuing space': 'Ausgabe von Speicherplatz',
  'choose issung space': 'Wählen Sie den Ausgabespace',
  headers: 'Kopf',
  'form description': 'Formularbeschreibung',
  status: 'Status',
  'upload date': 'Upload-Datum',
  'email address': 'Email Adresse',
  'change password': 'Passwort ändern',
  "it's a good idea that you choose a more secure password":
    'Wählen Sie bitte ein möglichst sicheres Passwort',
  'personal spaces': 'Private Spaces',
  'you are the administrator -': 'Sie sind der Administrator -',
  'you are the administrator - change': 'Sie sind der Administrator - Ändern',
  'delete account': 'Konto löschen',
  'data storage': 'Datenspeicher',
  'payment methods': 'Zahlungsmethoden',
  'on admincat cloud': 'In der AdminCat Cloud',
  locally: 'Lokal',
  users: 'Benutzer',
  'user type': 'Benutzerart',
  defaults: 'Standards',
  'choose file': 'Datei auswählen',
  language: 'Sprache',
  'decimal notation': 'Dezimalnotation',
  'date format': 'Datumsformat',
  'time format': 'Zeitformat',
  timezone: 'Zeitzone',
  username: 'Benutzername',
  'control room': 'Kontrollraum',
  forms: 'Formulare',
  settings: 'Einstellungen',
  admin: 'Verwaltung',
  approved: 'Genehmigt',
  pending: 'Offen',
  reject: 'Ablehnen',
  declined: 'Abgelehnt',
  'approved users': 'Genehmigte Benutzer',
  space: 'Space',
  'approved date': 'Zutrittsdatum',
  browse: 'Suchen',
  save: 'Speichern',
  'organization user': 'Organisations-Nutzer',
  fields: 'Felder',
  profile: 'Profil',
  message: 'Nachricht',
  'need help?': 'Hilfe',
  'sign out': 'Abmelden',
  'choose title': 'Titel auswählen',
  'choose birth country': 'Geburtsland auswählen',
  'choose ethnic group': 'Ethnische Gruppe auswählen',
  'choose sex': 'Geschlecht auswählen',
  'choose marital status': 'Beziehungsstatus auswählen',
  'choose religion': 'Religionszugehörigkeit auswählen',
  'choose eye color': 'Augenfarbe auswählen',
  'choose height unit': 'Einheit auswählen',
  'choose animal': 'Tier auswählen',
  'relation form': 'Beziehungsdaten',
  'address form': 'Adressdaten',
  'residence form': 'Wohnsitzdaten',
  'contact form': 'Kontaktdaten',
  'choose relation part': 'Rolle auswählen',
  'choose country': 'Land auswählen',
  'choose address type': 'Adressart auswählen',
  'choose street direction': 'Straßenrichtung auswählen',
  'choose contact type': 'Kontaktart auswählen',
  'choose country telephone code': 'Ländervorwahl auswählen',
  resident: 'Bewohner',
  residence: 'Wohnsitz',
  search: 'Suche',
  'privacy policy': 'Datenschutzerklärung',
  'All rights reserved': 'Alle Rechte vorbehalten.',
  'my workspaces': 'Meine Spaces',
  'pending user requests': 'Offene Nutzeranträge',
  'declined users': 'Abgelehnte Benutzer',
  'register date': 'Antragsdatum',
  'declined date': 'Absagedatum',
  'form upload': 'Formular - Upload',
  description: 'Beschreibung',
  'own sign': 'Eigenes Zeichen',
  creator: 'Ersteller',
  ward: 'Mündel',
  'form category': 'Formularkategorie',
  'choose form category': 'Formularkategorie auswählen',
  'choose language': 'Sprache auswählen',
  'pdf file(fill-able)': 'PDF-Datei (ausfüllbar)',
  draft: 'Entwurf',
  released: 'Freigegeben',
  requester: 'Antragsteller',
  'ward in question': 'Betroffener Mündel',
  person: 'Person',
  'person picture': 'Personenbild',
  'person groups': 'Personengruppe',
  'anchor persons': 'Hauptpersonen',
  'pending users': 'Offene Nutzeranträge',
  'comment category': 'Kommentarkategorie',
  'enter description here...': 'Beschreibung hier eingeben',
  'you may contact me via email for this subject':
    'Sie können mich per Email zu diesem Thema kontaktieren',
  'upload a screenshot or explanatory documents':
    'Laden Sie einenScreenshot oder ein Dokument hoch',
  'wish type (please add details in description)':
    'Wunschart (bitte erklären Sie die Details in der Beschreibung)',
  'choose wish type': 'Choose wish type',
  category: 'Kategorie',
  position: 'Position',
  'field name': 'Feldname',
  'field type': 'Feldtyp',
  configured: 'konfiguriert',
  clear: 'Zurücksetzen',
  'invalid/valid': 'ungültig/gültig',
  type: 'Typ',
  'join description': 'Join-Beschreibung',
  'main area': 'Hauptbereich',
  'criteria sets': 'Kriteriensätze',
  'form usage': 'Formularnutzung',
  'join creator': 'Join-Ersteller',
  id: 'ID',
  create: 'erstellen',
  'choose comment category': 'Wählen Sie eine Kommentarkategorie',
  citizen: 'Bürger',
  nationality: 'Staatsangehörigkeit',
  'send message': 'Nachricht senden',
  'choose nationality': 'Wählen Sie die Nationalität',
  'citizenship information': 'Informationen zur Staatsbürgerschaft',
  citizenship: 'Staatsbürgerschaft',
  'citizenship view': 'Sicht der Staatsbürgerschaft',
  'citizenship form': 'Staatsbürgerschaftsformular',
  'reference year': 'Bezugsjahr',
  year: 'Jahr',
  period: 'Monat',
  'issue date': 'Ausgabedatum',
  'issuing organisation': 'Ausstellende Organisation',
  'document type': 'Dokumentenart',
  'document form': 'Dokumentformular',
  'document view': 'Dokumentansicht',
  'reference month': 'Referenzmonat',
  file: 'Datei',
  home: 'Home',
  login: 'Login',
  register: 'Registrieren',
  'register now': 'Hier registrieren',
  'admincat login': 'AdminCat Login',
  'your username': 'Dein Benutzername',
  'your password': 'Dein Passwort',
  'forgot password?': 'Passwort vergessen?',
  'sign in': 'Anmelden',
  'admincat registration': 'AdminCat Registrierung',
  'private user': 'Privat-Nutzer',
  password: 'Passwort',
  'join personal workspace': 'Trete Privatem Space bei',
  'create personal workspace': 'Erstelle Privaten Space',
  'join workspace': 'Trete Space bei',
  'create workspace': 'Erstelle Space bei',
  'sign up': 'Registrieren',
  'have an account?': 'Haben Sie ein Konto?',
  'log in': 'Zum Login',
  contract: 'Vertrag',
  contact: 'Kontakt',
  customer: 'Kundennr.',
  relation: 'Beziehung',
  'relation added successfully': 'Beziehung erfolgreich hinzugefügt',
  'relation updated successfully': 'Beziehung erfolgreich aktualisiert',
  'favourite organisation added successfully':
    'Lieblingsorganisation erfolgreich hinzugefügt',
  'organisation proposal created and will be review':
    'Organisationsvorschlag erstellt und wird geprüft',
  'org function assignment added successfully':
    'Org-Funktionsvorschlag erfolgreich hinzugefügt',
  'org function assignment updated successfully':
    'Org-Funktionszuweisung erfolgreich aktualisiert',
  'org function proposal added successfully':
    'Org-Funktionsvorschlag erfolgreich hinzugefügt',
  'org function proposal updated successfully':
    'Org-Funktionsvorschlag erfolgreich aktualisiert',

  'organization function is required': 'Organisationsfunktion ist erforderlich',
  'school type is required': 'Schulart ist erforderlich',
  'religion is required': 'Religion ist erforderlich',
  'bic is required': 'BIC ist erforderlich',
  'contract created successfully, you can proceed to add contract items':
    'Der Vertrag wurde erfolgreich erstellt, Sie können nun Vertragspositionen hinzufügen',
  'contract updated successfully': 'Vertrag erfolgreich aktualisiert',

  taxes: 'Steuern',
  'house and rent': 'Haus und Miete',
  'house & rent': 'Haus & Miete',
  'personal information': 'Personendaten',
  'personal form': 'Personen-Eingabe',
  'personal view': 'Personen-Anzeigen',
  'relation information': 'Beziehungsdaten',
  'address information': 'Adressdaten',
  'residence information': 'Wohnsitzdaten',
  'contact information': 'Kontaktdaten',
  'an innovative document management tool':
    'Ein innovatives Dokumenten-Management-Tool',
  'concerned person': 'Betroffene Person',
  'birth date': 'Geburtsdatum',
  'view/edit/delete': 'Aussicht/Bearbeiten/Löschen',
  view: 'Ansicht',
  'view persons': 'Ansicht Personen',
  edit: 'Bearbeiten',
  delete: 'Löschen',
  'archor persons': 'Anker Personen',
  'valid period': '',
  'my documents': 'Meine Dokumente',
  personal: 'Persönlich',
  insurance: 'Versicherung',
  'upload documents': 'Dokumente hochladen',
  'documents list': 'Dokumentenliste',
  'issuing date': 'Ausstellungsdatum',
  area: 'Bereich',
  ind: 'Einzelkonto',
  and: 'Gemeinschaftskonto (abhängiger Zugriff)',
  or: 'Gemeinschaftskonto (unabhängiger Zugriff)',
  checkacc: '  Girokonto',
  instsav: 'Tagesgeldkonto',
  fixeddep: 'Festgeldkonto',
  creditacc: 'Kreditkartenkonto',
  savings: 'Sparkonto',
  depot: 'Depotkonto',
  default: 'Standard',
  'delete marked': 'Markierte löschen',
  'bank account': 'Bankkonto',
  currency: 'Währung',
  'select currency': 'Währung wählen',
  'account type': 'Kontoart',
  'select account type': 'Kontoart wählen',
  organisation: 'Organisation',
  'account number': 'Kontonummer',
  'account no': 'Kontonummer',
  'access type': 'Zugangstyp',
  'all rights reserved': 'Alle Rechte vorbehalten',
  'select access type': 'Zugangstyp wählen',
  'bic (swift code)': 'BIC (Swift code)',
  bank: 'Bank',
  'is active': 'ist aktiv',
  'reference checking account': 'Referenzkonto',
  'account ref': 'Referenzkonto',
  iban: 'IBAN',
  'enter owner': 'Eigentümer eingeben',
  owner: 'Eigentümer',
  'go back': 'Zurück',
  back: 'Zurück',
  ok: 'Ok',
  city: 'Stadt',
  'choose city': 'Stadt auswählen',
  'choose state': 'Bundesland auswählen',
  data: 'Daten',
  'legal form': 'Gesellschaftsform',
  'choose legal form': 'Gesellschaftsform auswählen',
  liquidation: 'Auflösung',
  charitable: 'Charitable',
  'website(as evidence)': 'Webseite',
  'organization information': 'Organisations Informationen',
  'organisation certificate': 'Organisation certificate',
  'add organisation': 'Organisation hinzufügen',
  'add organization': 'Organisation hinzufügen',
  'drag and drop files here or upload files': 'Dateien hier ablegen oder hochladen',
  land: 'Land',
  'organisation class': 'Organisation class',
  'choose organisation class': 'Choose organisation class',
  'organisation type': 'Organisation type',
  'choose organisation type': 'Choose organisation type',
  'organisation size': 'Organisation size',
  'choose organisation size': 'Choose organisation size',
  foundation: 'Gründung',
  'form type': 'Formulartyp',
  'choose form type': 'Wählen Sie Formulartyp',
  'wish type': 'Wunschtyp',
  'choose wish type': 'Wählen Sie Wunschtyp',
  'enter description': 'Beschreibung eingeben',
  'response welcome (no/yes)': 'Antwort willkommen (Nein / Ja)',
  documents: 'Dokumente',
  base: 'Basis',
  Health: 'Gesundheit',
  // "Housing": 'Wohnen',
  Finance: 'Finanzen',
  Education: 'Ausbildung',
  Work: 'Arbeit',
  Mobility: 'Mobilität',
  Leisure: 'Freizeit',
  Transaction: 'Transaktion',
  'dc indicator': 'D/C',
  line: 'Trans.-Position',
  'measurement unit': 'Maßeinheit',
  'position link': 'Bilanzposition',
  quantity: 'Menge',
  'offset account category': 'Kat. Gegenkonto',
  'offset financial account': 'Gegenkonto',
  'assign to area': 'Bereich zuweisen',
  send: 'Senden',
  attach: 'Anfügen',
  subject: 'Betreff',
  from: 'Von',
  to: 'Zu',
  'from mail': 'Sender',
  'to mail': 'Empfänger',
  'mail type': 'Ordner',
  date: 'Datum',
  size: 'Größe',
  'your email': 'Deine E-Mail',
  "sender's email": 'E-Mail des Absenders',
  "reciever's email": 'E-Mail des Empfängers',
  'your message...': 'Deine Nachricht...',
  'compose mail': 'Mail schreiben',
  'sorry, you already sent 600 mails this month.':
    'Sorry, Sie haben diesen Monat bereits 600 Mails verschickt.',
  reply: 'Antworten',
  forward: 'Nach vorne',
  'loading...': 'Wird geladen...',
  'add rule': 'Regel hinzufügen',
  'data area': 'Datenbereich',
  'attachment rule': 'Anhangsregel',
  sender: 'Absender',
  text: 'Text',
  "multiple entries can be delimited with ';' (semicolon)":
    "Mehrere Einträge können mit ';' (Semikolon)",
  'edit mode': 'Bearbeitungsmodus',
  'view mode': 'Ansichtsmodus',
  'graduation goal': 'Ausbildungsziel',
  'pre-school': 'Kindergarten',
  'pre school': 'Kindergarten',
  'pre-school institution': 'Kindertageseinrichtung',
  'general education': 'Allgemeine Schule',
  'vocational school': 'Berufsschule',
  'vocschool subject': 'Berufsschul-Schwerpunkt',
  'vocational training': 'Berufsausbildung',
  'academic studies': 'Studium',
  'further training': 'Weiterbildung',
  languages: 'Sprachen',
  'language literacy': 'Lese-Niveau',
  'language verbal': 'Sprech-Niveau',
  'school type': 'Schulart',
  school: 'Schule',
  'school subject': 'Fachl. Schwerpunkt',
  'subject area (if any)': 'Fachlicher Schwerpunkt (falls vorhanden)',
  'study subject': 'Studienfach',
  'degree achieval': 'Abschluss',
  'final grade': 'Endnote',
  'last class': 'Letzte Klasse',
  profession: 'Beruf',
  industry: 'Branche',
  'academic institution': 'Hochschule',
  'no document selected': 'Kein Dokument ausgewählt',
  'please note duplicate files will be ignored':
    'Bitte beachten Sie, dass doppelte Dateien ignoriert werden',
  'no results found': 'Keine Ergebnisse gefunden',
  'choose academic institution': 'Hochschule auswählen',
  'organisation created successfully': '“Organisation wurde angelegt.',
  'add organisation': 'Organisation hinzufügen',
  'create new': 'Org.anlegen',
  'add favourite': 'Hinzufügen',
  'go to org function': 'Zur Org.-Funktion',
  'official register no': 'Offizielle Register-Nummer',
  'general organisation': 'Allgemeine Organisation',
  'choose organization': 'Organisation auswählen',
  'choose organisation category': 'Kategorie der Organisation auswählen',
  'organisation category': 'Kategorie der Organisation',
  'choose school type': 'Schulart auswählen',
  'favorite org': 'Org-Auswahl',
  'type of school': 'Schulart',
  'concerned person': 'Betreffende Person',
  yes: 'Ja',
  no: 'Nein',
  ongoing: 'Noch laufend',
  'proficiency level': 'Kenntnisstand',
  'proficiency level reading/writing': 'Kenntnisstand Lesen/Schreiben',
  'proficiency level listening/speaking': 'Kenntnisstand Hören/Sprechen',
  'subject of studies': 'Studienfach',
  'this field is required': 'Dieses Feld wird benötigt',
  'aspired school graduation (if any)': 'Angestrebter Abschluss (falls zutreffend)',
  'aspired school graduation': 'Angestrebter Abschluss',
  'subject area': 'Fachlicher Schwerpunkt',
  reason: 'Grund',
  'add name': 'Name hinzufügen',
  'favourite organization': 'Org-Auswahl',
  'view names': 'Namen anzeigen',
  'time dependent name': 'Zeitabhängiger Name',
  update: 'aktualisieren',
  layout: 'Feldselektion',
  'organisation created successfully': 'Organisation wurde angelegt',
  'registration institution': 'Registrierungsinstitution',
  'master data': 'Stammdaten',
  'asset account': 'Vermögenswert',
  cash: 'Bargeld',
  receivable: 'Forderung',
  'real estate': 'Immobilie',
  securities: 'Wertpapier',
  'object of value': 'Wertgegenstand',
  'liability account': 'Kapitalherkunft',
  payable: 'Verbindlichkeit',
  'credit card': 'Kreditkarte',
  'credit card licenser': 'Kreditkarten-Lizenzgeber',
  'license org': 'Lizenzgeber',
  'issuing bank organization': 'Ausgebende Bank',
  // 'issuing organization': 'Ausgebende Bank',
  'issuing org': 'Ausgebende Org.',
  'credit card': 'Kreditkarte',
  'card number': 'Kartennummer',
  'card type': 'Kartentyp',
  'valid thru': 'Gültig bis',
  'issuing bank': 'Ausgebende Bank',
  'select data area': 'Datenbereich auswählen',
  'select document type': 'Dokumententyp auswählen',
'o.t.p browser dependent setting': 'Browser-Kennung nutzen, um OTP bei Login seltener abzufragen',
'enter current password': 'Aktuelles Passwort eingeben',
'change space admin': 'Space Admin ändern',
'expenditure for this month': 'Z.B. Rechnung',
'new successful transaction': 'Z.B. Beitragsinformation',

'you use admincat free of charge!': 'Sie nutzen AdminCat derzeit kostenlos!',

  loan: 'Darlehen',
  mortgage: 'Hypothek',
  equity: 'Eigenkapital',
  transactions: 'Transaktionen',
  expenses: 'Ausgaben',
  expenditure: 'Ausgaben',
  consumption: 'Ausgaben',
  'asset shift': 'Anlagetausch',
  'debt & asset': 'Kredit & Anlage',
  'click the plus icon to add a last name':
    'Klicken Sie auf das Pluszeichen, um einen Nachnamen hinzuzufügen',
  'update name': 'Name aktualisieren',
  'must be greater than the Birth Date': 'muss größer sein als das Geburtsdatum',
  'must be lesser than the Birth Date': 'muss kleiner sein als das Geburtsdatum',
  'last name at birth': 'Nachname bei Geburt',
  'add contract': 'Vertrag',
  'choose data area': 'Datenbereich auswählen',
  continuous: 'Langfristig',
  'contractor is org. or person': 'Vertragspartner ist Organisation oder Person',
  contractor: 'Vertragspartner',
  'choose contractor': 'Vertragspartner auswählen',
  'choose person': 'Person auswählen',
  'choose continuous': 'Langfristig auswählen',
  'choose org. or person': 'Organisation oder Person auswählen',
  'person role': 'Rolle der Person',
  'person is': 'Person ist',
  'choose person role': 'Personenrolle auswählen',
  'org indicator': 'Org./Person',
  'organisation indicator': 'Org./Person',
  buyer: 'Kunde',
  seller: 'Anbieter',
  'customer id': 'Kunden-ID',
  'contract id': 'Vertrags-ID',
  'start date': 'Startdatum',
  'automatic renewal after run time': 'Auto-Verlängerung nach der Laufzeit',
  'choose auto renewal': 'Automatische Vertragsverlängerung',
  'auto renewal': 'Auto-Verlängerung',
  'run time': 'Laufzeit',
  'run time unit': 'Einheit der Laufzeit',
  'notice period': 'Kündigungsfrist',
  'notice period unit': 'Einheit der Kündigungsfrist',
  'add contract item': 'Vertragsposition hinzufügen',
  'disease history': 'Krankheitsgeschichte',
  disease: 'Krankheit',
  'choose disease': 'Krankheit auswählen',
  'amount (in ic)': ' Betrag (in OW)',
  'item curr.': 'Obj.-Währung',
  'amount (in sc)': ' Betrag (in SW)',
  'space curr.': 'Space-Währung',
  ' accountno': 'KONTONR.',
  'debit or credit': 'Debit- oder Kreditkarte',
  'debit/credit': 'Debit/Kreditkarte',
  'card no': 'Karten-Nummer',
  'card licenser': 'Kartenlizenzgeber',
  'card verification code (cvc)': 'Kartenverifizierungs-Code (CVC)',
  'local muncipality': 'Kommune bzw. Stadtteil',
  'add contract ': 'Vertrag',
  // 'continuous ': 'Langfristig',
  'contractor ': 'Vertragspartner',
  'choose contractor': 'Vertragspartner auswählen',
  'person role': 'Rolle der Person',
  'add contract item': 'Position hinzufügen',
  document: 'Dokument',
  'start date': 'Startdatum',
  'automatic renewal after Run time': ' Automatische Vertragsverlängerung',
  'choose auto renewal': 'Automatische Vertragsverlängerung',
  'disease history': 'Krankheitsgeschichte',
  'space curr.': 'Space-Währung',
  ACCOUNTNO: 'KONTONR',
  inbox: 'Posteingang',
  sent: 'Gesendet',
  trash: 'Papierkorb',
  'attachment rules': 'Anhang-Regeln',
  forward: 'Weiterleiten',
  user: 'Benutzer',
  'user setting': 'Benutzer-Einstellungen',
  'choose date format': 'Datumsformat',
  'change workspace': 'Space auswählen',
  'inactive / active': 'Inaktiv / Aktiv',
  'contractor is organisation or person':
    'Vertragspartner ist Organisation oder Person',
  'update contract': 'Vertrag aktualisieren',
  'issuer is org or person': 'Aussteller ist Organisation oder Person',
  'update contract item': 'Vertragsposition aktualisieren',
  'add item': 'Position hinzufügen',
  'item description': 'Beschreibung der Position',
  'item reference': 'Sachbezug der Position',
  'choose workspace': 'Auswahl des Space',
  'inactive / active': 'Inaktiv / Aktiv',
  'account category': 'Kontenkategorie',
  'financial account': ' Finanz-Konto',
  'fix or var': 'Fest oder Variabel',
  'fix or variable': 'Fest oder Variabel',
  'fixed period unit': 'Zeiteinheit zur festen Position',
  'fix period unit': 'Zeiteinheit zur festen Position',
  'fixed amount': 'Festbetrag',
  'fix amount': 'Festbetrag',
  fix: 'Fest',
  variable: 'Variabel',
  'var service unit': 'Einheit zur variablen Position',
  'var service rate': ' Betrag pro variabler Einheit',
  'variable unit': 'Einheit zur variablen Position',
  'variable rate': ' Betrag pro variabler Einheit',

  'counter reading at start': 'Zählerstand zu Beginn',
  'counter reading at end': 'Zählerstand zum Ende',
  'counter reading start': 'Zählerstand zu Beginn',
  'counter reading end': 'Zählerstand zum Ende',
  'counter reading b': 'Zählerstand zu Beginn',
  'counter reading e': 'Zählerstand zum Ende',

  'contract no': 'Vertrags-Nr.',
  'contract item': 'Vertragspos.',
  item: 'Position',
  'contract item added successfully': 'Vertragsposition erfolgreich hinzugefügt',
  'contract item updated successfully': 'Vertragsposition erfolgreich aktualisiert',

  'contract / item': 'Vertrag / Position',
  'transaction date': 'Transaktionsdatum',
  amount: 'Betrag',
  description: 'Beschreibung',
  'category / account': 'Kategorie / Konto',
  'city location': 'Stadt',
  'choose city location': 'Stadt wählen',
  public: 'Öffentlich-rechtlich',
  'public resp area': 'Verantwortungsebene',
  'choose public resp. area': 'Ebene der öffentlich-rechtlichen Verantwortung',
  'org motivation': 'Motivation',
  'choose org motivation': 'Motivation der Organisation auswählen',
  'legal personality': 'Rechtlich eigenständige Persönlichkeit',
  'parent organisation': 'Mutterorganisation bzw. Träger',
  'choose parent organisation': 'Mutterorganisation bzw. Träger',
  'non profit': 'Gemeinnützig',
  'choose industry': 'Industrie auswählen',
  'no. of employees': 'Anzahl Mitarbeiter',
  'revenue approx.': 'Umsatz ungefähr',
  'revenue currency': 'Umsatz-Währung',
  'choose revenue currency': 'Umsatz-Währung',
  website: 'Webseite',
  'organization function assignment': 'Zuweisung einer Organisationsfunktion',
  'organization function': 'Organisationsfunktion',
  'choose organization function': 'Organisationsfunktion auswählen',
  vaccination: 'Impfung',
  'trade name': 'Handelsname',
  'batch number': 'Chargennummer',
  'trade name (vaccine)': 'Handelsname (Impfstoff)',
  'batch number (vaccine)': 'Chargennummer (Impfstoff)',
  'batch no': 'Chargennummer',
  'disease protection': 'Schutz vor Krankheit',
  disease: 'Krankheit',
  vaccine: 'Impfstoff',
  credits: 'Impressum',
  germany: 'Deutschland',
  'authorized managing director': 'Vertretungsberechtigter Geschäftsführer',
  'register court': 'Registergericht',
  'register number': 'Registernummer',
  'vat id': 'USt-IdNr.',
  'valid on': 'Gültig am',
  'organisation (doctor)': 'Organisation (Doktor)',
  'disease list': 'Krankheitsliste',
  'vaccination date': 'Impfdatum',
  close: 'Schließen',
  'country tel. code': 'Länder Tel.-Vorwahl',
  'tel country code': 'Länder Tel.-Vorwahl',
  'choose country tel. code': 'Länder Tel.-Vorwahl wählen',
  'tel. number': 'Telefonnummer',
  'tel number': 'Telefonnummer',
  'area tel. code': 'Ortsvorwahl',
  'tel area code': 'Ortsvorwahl',
  'choose number format': 'Zahlenformat auswählen',
  'companion animal': 'Haustier',
  animal: 'Tier',
  'date of death': 'Todestag',
  'date of birth': 'Geburtstag',
  'selected fields': 'Feldauswahl',
  'no of employees': 'Anzahl Mitarbeiter',
  'register no': 'Registernummer',
  'register institute': 'Register-Institut',
  'register doc type': 'Register-Dokumenttyp',
  'register doc': 'Register-Dokument',
  'parent org': 'Mutter-Unternehmen',
  'main space': 'Haupt-Space',
  'revenue approx': 'Umsatz ca.',

  'income for dependent employment': 'Einkommen aus nichtselbständiger Arbeit',
  'income for self-employment': 'Einkommen aus selbständiger Arbeit',
  'income from farming': 'Einkommen aus Landwirtschaft',
  'income from rent and lease': 'Einkommen aus Vermietung und Verpachtung',
  'capital income': 'Einkünfte aus Kapitalvermögen',
  'business income': 'Einkünfte aus Gewerbebetrieb',
  'other income': 'Sonstiges Einkommen',

  'the document upload started in the background. you can move on now':
    'Das Hochladen des Dokuments wurde im Hintergrund gestartet. Sie können jetzt fortfahren',

  reversal: 'Storno',
  reversed: 'Storniert',
  'original doc no': 'Original-Dok nummer',
  'reverse doc no': 'Storno-Dok nummer',
  'housing & garden': 'Haus & Garten',
  'heating & ac': 'Heizung & AC',
  utilities: 'Ver- und Entsorgung',
  electronics: 'Elektronik',
  'interior decoration': 'Inneneinrichtung',
  'clothing & shoes': 'Kleidung & Schuhe',
  health: 'Gesundheit',
  'beauty & hygiene': 'Schönheit & Pflege',
  groceries: 'Lebensmittel',
  insurance: 'Versicherung',
  'transport (workaday)': 'Transport (Alltag)',
  hobby: 'Hobby',
  travel: 'Reisen',
  communication: 'Kommunikation',
  capital: 'Hauptstadt',
  education: 'Bildung',
  groceries: 'Lebensmittel',
  others: 'Andere',
  utilites: 'Dienstprogramme',
  travellings: 'Reisen',
  income: 'Einnahmen',
  expenditure: 'Ausgaben',
  'transport (workaday)': 'Transport (Arbeitstag)',
  'user terms': 'AGB',
  'I Agree With the Terms and Conditions': 'Ich stimme den Nutzungsbedingungen zu',
  'gathering & dining': 'Ausgehen & Feiern',
  donation: 'Spende',
  'education & reading': 'Bildung & Lesen',
  entertainment: 'Unterhaltung',
  'capital expenditure': 'Kapitalkosten',
  others: 'Anderes',
  disclaimer: 'Haftungsausschluss',
  Username: 'Nutzername',
  'Personal User': 'Persönlicher Benutzer',
  'Have an account': 'Ein Konto haben',
  'Organization Information': 'Organisations informationen',
  Register: 'Registrieren',
  Login: 'Login',
  Password: 'Passwort',
  'Forgot Password': 'Passwort vergessen',
  'User Terms': 'AGB',
  'Sign in': 'Einloggen',
  'join personal workspace': 'Trete Privatem Space bei',
  'create personal workspace': 'Erstelle Privaten Space',
  'Organisation User': 'Organisations-Nutzer',
  'Sign up': 'Anmelden',
  'amount in space currency': 'Betrag in Space Währung',
  'space currency': 'Space Währung',
  'offset category': 'Gegen-Kategorie',
  post: 'Buchen',
  'income category': 'Einkommenskategorie',
  'position-ID': 'positions-ID',
  position: 'Position',
  'account (+)': 'Konto (+)',
  'offset-account': 'Gegen-Konto',
  'expense category': 'Kostenkategorie',
  'choose member': 'Mitglied auswählen',
  add: 'Hinzufügen',
  'select column': 'Spalte auswählen',
  actions: 'Aktionen',
  before: 'Vor',
  after: 'Nach',
  on: 'Auf',
  range: 'Bereich',
  contains: 'Enthält',
  exact: 'Genau',
  'add row': 'Zeile hinzufügen',
  'select condition': 'Bedingung auswählen',
  value: 'Wert',
  'start value': 'Startwert',
  'end value': 'Endwert',
  filter: 'Filter',
  'apply filter': 'Filter anwenden',
  'clear filter': 'Filter löschen',
  'renewal period': 'Verlängerungszeitraum',
  'renewal period unit': 'Einheit der Verlängerung',
  'add document': 'Dokument hinzufügen',
  'choose document': 'Dokument auswählen',
  'contract continuous?': 'Vertrag ist langfristig?',
  'choose organisation or person': 'Organisation oder Person wählen',
  'propose a new entry': 'Neuen Eintrag vorschlagen',
  'new value': 'Neuer Eintrag',
  'new entry': 'Neuer Eintrag',
  'warning!': 'Achtung!',
  'you have not selected any item to delete':
    'Sie haben kein Element zum Löschen ausgewählt',
  'you have not selected any item to view':
    'Sie haben keinen Artikel zur Ansicht ausgewählt',
  'please fill all fields.': 'Bitte füllen Sie alle Felder aus.',
  'you have not select any member to approve.':
    'Sie haben kein Mitglied ausgewählt, um es zu bestätigen.',
  'You have not select any member to decline.':
    'Sie haben kein Mitglied zum Ablehnen ausgewählt.',

  'please ensure all required fields are populated.':
    'Bitte stellen Sie sicher, dass alle Pflichtfelder gefüllt sind.',
  'success!': 'Erfolgreich übermittelt!',
  OK: 'OK',
  compose: 'Verfassen',
  'move back to inbox': 'Verschieben in Posteingang',
  receiver: 'Empfänger',
  'tagged emails': 'Markierte Emails',
  'mark as unread': 'Als ungelesen markieren',
  'mark as read': 'Als gelesen markieren',
  'other actions': 'Weitere Aktionen',
  'export email': 'Emails exportieren',
  'mail has been marked': 'Mails wurden markiert',
  'mail has been unmarked': 'Mails wurden unmarkiert',
  'workspace successfully changed': 'Arbeitsbereich erfolgreich geändert',
  'please assign a person group to this space':
    'Bitte dem Space eine Personengruppe zuweisen',

  payments: 'Zahlungen',
  'make payment': 'Zahlung vornehmen',
  'service paid until': 'Leistung bezahlt bis',
  'payment date': 'Datum der Zahlung',
  subscribe: 'Abonnieren',

  'address added successfully': 'Adresse erfolgreich hinzugefügt',
  'address updated successfully': 'Adresse erfolgreich aktualisiert',
  'residence added successfully': 'Wohnsitz erfolgreich hinzugefügt',
  'residence updated successfully': 'Wohnsitz erfolgreich aktualisiert',

  'contact added successfully': 'Kontakt erfolgreich hinzugefügt',
  'contact updated successfully': 'Kontakt erfolgreich aktualisiert',

  'citizenship added successfully': 'Staatsbürgerschaft erfolgreich hinzugefügt',
  'citizenship updated successfully': 'Staatsbürgerschaft erfolgreich aktualisiert',

  "you haven't selected any mail to unmark as read.":
    'Sie haben noch keine Email zum Markieren selektiert.',
  "you haven't selected any mail to mark as read.":
    'Sie haben noch keine Email zum Markieren selektiert.',
  "you haven't selected any mail to move.":
    'Sie haben noch keine Email zum Verschieben selektiert.',
  "you haven't selected any mail to delete.":
    'Sie haben noch keine Email zum Löschen selektiert.',
  "you haven't selected any mail to assign to an area.":
    'Sie haben noch keine Email zum Zuweisen eines Bereichs selektiert.',
  'mail has been assigned': 'Die Mail wurde zugewiesen.',

  'go to contract items': 'Zu den Vertragspositionen',
  'contract items': 'Vertragspositionen',
  'we will review your proposal. if successful, it will be added in the next 5 days. thank you!':
    'Wir werden Ihren Vorschlag prüfen. Falls erfolgreich, wird er in den nächsten 5 Tagen hinzugefügt. Danke!',
  'this contract has no contract items and is therefore not complete yet. please add at least one item.':
    'Dieser Vertrag enthält keine Positionen, somit ist er noch nicht vollständig. Füge bitte mindestens eine Vertragsposition hinzu.',
  'person added successfully': 'Person erfolgreich hinzugefügt',
  'person updated successfully': 'Person erfolgreich aktualisiert',
  'person deleted successfully': 'Person erfolgreich gelöscht',
  'person group': 'Personengruppe',
  'person group added successfully': 'Personengruppe erfolgreich hinzugefügt',
  'person group updated successfully': 'Personengruppe erfolgreich aktualisiert',
  'person group deleted successfully': 'Personengruppe erfolgreich gelöscht',
  'person group member added successfully':
    'Personengruppenmitglied erfolgreich hinzugefügt',
  'person group member updated successfully':
    'Personengruppenmitglied erfolgreich aktualisiert',
  'person group member deleted successfully':
    'Personengruppenmitglied erfolgreich gelöscht',
  'education added successfully': 'Ausbildung erfolgreich hinzugefügt',
  'education updated successfully': 'Ausbildung erfolgreich aktualisiert',
  'education deleted successfully': 'Ausbildung erfolgreich gelöscht',
  'pre-school added successfully': 'Kindergartenzeit erfolgreich hinzugefügt',
  'pre-school updated successfully': 'Kindergartenzeit erfolgreich aktualisiert',
  'pre-school deleted successfully': 'Kindergartenzeit erfolgreich gelöscht',
  'general education added successfully':
    'Allgemeine Schulausbildung erfolgreich hinzugefügt',
  'general education updated successfully':
    'Allgemeine Schulausbildung erfolgreich aktualisiert',
  'general education deleted successfully':
    'Allgemeine Schulausbildung erfolgreich gelöscht',
  'vocational school added successfully':
    'Berufsschulausbildung erfolgreich hinzugefügt',
  'vocational school updated successfully':
    'Berufsschulausbildung erfolgreich aktualisiert',
  'vocational school deleted successfully':
    'Berufsschulausbildung erfolgreich gelöscht',
  'vocational training added successfully':
    'Berufsausbildung erfolgreich hinzugefügt',
  'vocational training updated successfully':
    'Berufsausbildung erfolgreich aktualisiert',
  'vocational training deleted successfully':
    'Berufsausbildung erfolgreich gelöscht',
  'academic studies added successfully': 'Studium erfolgreich hinzugefügt',
  'academic studies updated successfully': 'Studium erfolgreich aktualisiert',
  'academic studies deleted successfully': 'Studium erfolgreich gelöscht',
  'further training added successfully': 'Weiterbildung erfolgreich hinzugefügt',
  'further training updated successfully': 'Weiterbildung erfolgreich aktualisiert',
  'further training deleted successfully': 'Weiterbildung erfolgreich gelöscht',
  'language skills added successfully': 'Sprachkenntnisse erfolgreich hinzugefügt',
  'language skills updated successfully':
    'Sprachkenntnisse erfolgreich aktualisiert',
  'language skills deleted successfully': 'Sprachkenntnisse erfolgreich gelöscht',
  'data added successfully': 'Daten erfolgreich hinzugefügt',
  'data updated successfully': 'Daten erfolgreich aktualisiert',
  'data deleted successfully': 'Daten erfolgreich gelöscht',
  'organisation space successfully created':
    'Organisationsspace erfolgreich erstellt',
  'personal space successfully created': 'Personenspace erfolgreich erstellt',
  'the access request was sent to the space administrator':
    'Die Bitte um Zutritt wurde an den Space-Administrator gesendet',
  'default currency saved': 'Standardwährung gespeichert',
  member: 'Mitglied',
  'member successfully approved': 'Mitglied erfolgreich genehmigt',
  'member could not be approved': 'Mitglied wurde nicht genehmigt',
  'member successfully disapproved': 'Mitglied erfolgreich abgelehnt',
  'member could not be disapproved': 'Mitglied konnte nicht abgelehnt werden',
  'space administrator changed': 'Space-Administrator geändert',
  'organisation updated successfully': 'Organisation erfolgreich aktualisiert',
  'your account is successfully removed': 'Ihr Konto wurde erfolgreich entfernt',
  // 'registration successful. Activation link has been sent to your email':
  //   'Registrierung erfolgreich. Der Aktivierungslink wurde an Ihre E-Mail gesendet',
  'check your email for password reset link':
    'Überprüfen Sie Ihre E-Mail auf den Link zum Zurücksetzen des Passworts',
  'user verified!': 'Benutzer verifiziert!',
  'password changed successfully': 'Das Passwort wurde erfolgreich geändert',
  'password updated successfully': 'Das Passwort wurde erfolgreich aktualisiert',
  'server error please try again': 'Serverfehler - bitte versuchen Sie es erneut',
  'vaccine history disease added successfully':
    'Impfinformationen erfolgreich hinzugefügt',
  'disease added successfully': 'Krankheit erfolgreich hinzugefügt',
  'disease updated successfully': 'Krankheit erfolgreich aktualisiert',
  'vaccine history disease updated successfully':
    'Impfinformationen erfolgreich aktualisiert',
  'name added successfully': 'Name erfolgreich hinzugefügt',
  'work experience added successfully': 'Berufserfahrung erfolgreich hinzugefügt',
  'work experience updated successfully': 'Berufserfahrung erfolgreich aktualisiert',

  'membership added successfully': 'Mitgliedschaft erfolgreich hinzugefügt',
  'membership updated successfully': 'Mitgliedschaft erfolgreich aktualisiert',

  'name updated successfully': 'Name erfolgreich aktualisiert',
  'name deleted successfully': 'Name erfolgreich gelöscht',
  'name could not be deleted': 'Name konnte nicht gelöscht werden',
  'mail sent successfully': 'Mail erfolgreich gesendet',
  'recent mails could not be retrieved':
    'Neueste Mails konnten nicht abgerufen werden',
  'data saved successfully': 'Daten erfolgreich gespeichert',
  'data could not be deleted': 'Daten konnten nicht gelöscht werden',
  'data fetched successfully': 'Daten erfolgreich abgerufen',
  'form uploaded successfully': 'Formular erfolgreich hochgeladen',
  'form updated successfully': 'Formular erfolgreich aktualisiert',
  'fields updated successfully': 'Felder erfolgreich aktualisiert',
  'field Option configuration saved': 'Konfiguration der Feldoptionen gespeichert',
  'document saved successfully': 'Dokument erfolgreich gespeichert',
  'document updated successfully': 'Dokument erfolgreich aktualisiert',
  'document deleted successfully': 'Dokument erfolgreich gelöscht',
  'document could not be deleted': 'Dokument konnte nicht gelöscht werden',
  'disease episode added successfully': 'Krankheitsphase erfolgreich hinzugefügt',
  'disease episode updated successfully': 'Krankheitsphase erfolgreich aktualisiert',
  'criteria set created successfully': 'Kriteriensatz erfolgreich erstellt',
  'criteria set updated successfully': 'Kriteriensatz erfolgreich aktualisiert',
  'criteria set deleted successfully': 'Kriteriensatz erfolgreich gelöscht',
  'criteria set could not be deleted': 'Kriteriensatz konnte nicht gelöscht werden',
  'contract items updated': 'Vertragspositionen aktualisiert',
  'contract created successfully': 'Vertrag erfolgreich erstellt',
  'companion animal added successfully': 'Haustier erfolgreich hinzugefügt',
  'companion animal updated successfully': 'Haustier erfolgreich aktualisiert',
  'rule added successfully': 'Regel erfolgreich hinzugefügt',
  'rule updated successfully': 'Regel erfolgreich aktualisiert',
  'attachment rule deleted successfully': 'Anhangsregel erfolgreich gelöscht',
  'attachment rule could not be deleted':
    'Anhangsregel konnte nicht gelöscht werden',
  'academic studies added successfully': 'Studium erfolgreich hinzugefügt',
  'academic studies updated successfully': 'Studium erfolgreich aktualisiert',
  'bank account created successfully': 'Bankkonto erfolgreich erstellt',
  'bank account deleted successfully': 'Bankkonto erfolgreich gelöscht',
  'bank account updated successfully': 'Bankkonto erfolgreich aktualisiert',
  'problem fetching credit card': 'Fehler beim Abrufen der Kreditkarte',
  'credit card created successfully': 'Kreditkarte erfolgreich erstellt',
  'problem creating credit card': 'Fehler beim Erstellen einer Kreditkarte',
  'credit card updated successfully': 'Kreditkarte erfolgreich aktualisiert',
  'problem updating credit card': 'Fehler beim Aktualisieren der Kreditkarte',
  'problem fetching transaction': 'Fehler beim Abrufen der Transaktion',
  'transaction created successfully': 'Transaktion erfolgreich erstellt',
  'problem creating transaction': 'Fehler beim Erstellen einer Transaktion',
  'transaction updated successfully': 'Transaktion erfolgreich aktualisiert',
  'problem updating transaction': 'Fehler beim Aktualisieren der Transaktion',

  'your contract will end': 'Ihr Vertrag endet',
  'the run time of your contract': 'Die Laufzeit Ihres Vertrages',
  'total inbox': 'Anzahl Emails',
  'total liability': 'Kredite',
  'total asset': 'Vermögen',
  Username: 'Nutzername',
  'Personal User': 'Persönlicher Benutzer',
  'Have an account': 'Ein Konto haben',
  'Organization Information': 'Organisations informationen',
  Login: 'Login',
  Password: 'Passwort',
  'Forgot Password': 'Passwort vergessen',
  copyright: 'Urheberrecht',
  'All rights reserved': 'Alle Rechte vorbehalten.',
  'Sign in': 'Einloggen',
  'join personal workspace': 'Trete Privatem Space bei',
  'create personal workspace': 'Erstelle Privaten Space',
  'Organisation User': 'Organisations-Nutzer',
  'Sign up': 'Anmelden',
  link: '/deu/privacy-statement ',
  'your contract': 'Dein Vertrag',
  // 'will end': 'wird zum',
  'this contract': 'Dieser Vertrag',
  'will end': 'wird enden',
  'the document will expire': 'Das Dokument läuft ab',
  'will end on': 'endet am',
  'the run time of your contract': 'Die Laufzeit Ihres Vertrages',
  "if you don't wish to renew it you can cancel it":
    'Wenn Sie es nicht erneuern möchten, können Sie es kündigen',
  'this document will end': 'Dieses Dokument endet',
  'if neccesary, please take care for a new one':
    'Falls notwendig, beantrage jetzt ein neues.',
  "if you don't wish to renew it you can cancel it before":
    'Wen Sie es nicht erneuern möchten, können Sie es vorher kündigen',
  'the document': 'Das Dokument',
  document: 'Dokument',
  'will expire on': 'wird zum',
  'will expire on the': 'wird zum der',
  of: 'von',

  'payment status': 'Status der Zahlung',
  'paypal reference': 'Paypal-Referenz',
  'start date': 'Startdatum',
  'end date': 'Enddatum',

  initiated: 'eingeleitet',
  success: 'abgeschlossen',
  'transaction successful': 'Transaktion war erfolgreich',
  'the transaction was successful, you will be redirected back':
    'Die Transaktion war erfolgreich, Sie werden nun wieder zurückgeleitet',

  'your payment has been initiated, you will be redirected to paypal to complete this payment':
    'Ihre Zahlung wurde eingeleitet, Sie werden zu Paypal weitergeleitet, um die Zahlung abzuschließen',

  'no document available': 'Kein Dokument verfügbar',
  'no notifications yet!': 'Noch keine Benachrichtigungen!',
  'no entries yet!': 'Noch keine Einträge!',
  'you have not created any space groups!': 'Sie haben keine Raumgruppen erstellt!',

  'Welcome to your digital workspace!': 'Willkommen in Deiner digitalen Zentrale!',
  'Log in': ' Login',
  'Get started': 'Jetzt starten',

  'Simplify bureaucracy!': 'Vereinfacht Verwaltung!',
  'We provide features that make your private administration easy and fun.':
    'Wir bieten Funktionen, mit denen die private Verwaltung Spaß macht.',
  'The control station for your life!': 'Das Cockpit für Dein Leben!',
  'Our dashboard enables you to take control like a captain.':
    'Unser Dashboard ermöglicht es Dir, wie ein Kapitän das Steuer zu übernehmen.',
  'All you need in one App!': ' Alles in einer App!',
  'No more headache with incompatible apps. We provide a seamless all-in-one-solution.':
    'Keine Kopfschmerzen mehr mit Teillösungen. Wir bieten eine nahtlose All-in-one-App.',
  'Here are some of our wonderful features':
    'Hier einige unserer wundervollen Funktionen',
  'Simply find everything': 'Finde einfach alles wieder',
  'Digitalize your documents and easily manage them online.':
    'Digitalisiere Deine Dokumente und verwalte sie einfach online.',
  'Stay up to date': 'Bleib auf dem Laufenden',
  'Don‘t worry about deadlines - we‘ll remind you!':
    'Keine Sorge vor Fristen – wir erinnern Dich!',
  'Financial control': 'Die Finanzen im Griff',
  'Wanna keep an overview on your finances? We got it!':
    'Willst Du Deine Finanzen managen? Bei uns geht’s!',
  'Safety first': 'Sicherheit zuerst',
  'Centralize your data and documents in one safe place.':
    'Sammle Deine Daten und Dokumente an einem sicheren Ort.',
  'Smart Email': 'Smarte Email',
  'Receive emails and automatically save attachments in the relevant folders.':
    'Erhalte Emails und speichere Anhänge automatisch in den richtigen Ordnern.',
  'Family-friendly': 'Familien-freundlich',
  'All data of your family integrated - voilá!':
    'Alle Daten Deiner Familie vereint - voilá!',
  'Customers Trust Us': 'Kunden vertrauen uns',
  'Here are some of our reviews from our users':
    'Hier einige Kommentare unserer Nutzer',
  'My paperwork was always a challenge. Now I come here and find my documents in a few seconds. You are sooo smart.':
    'Mein Papierkram war immer eine Herausforderung. Jetzt komme ich hierher und finde meine Dokumente in wenigen Sekunden. Ihr seid echt gut.',
  'AdminCat is a great help to organize contracts and personal finances in general. And I like the dashboard a lot.':
    'AdminCat ist eine große Hilfe, um Verträge und die privaten Finanzen zu verwalten. Und am besten gefällt mir das Dashboard.',

  'we are here for you!': 'Wir sind für Dich da!',
  'check out admincat today! it’s free.':
    'Teste AdminCat noch heute! Es ist kostenlos.',
  'press the button below in order to start with your first own admincat workspace, you’re gonna love it!':
    'Klicke auf diese Schaltfläche, um mit Deinem ersten eigenen AdminCat Workspace zu starten. Du wirst es lieben!',
  'get started': 'Jetzt starten',

  'Represented by': 'Vertreten durch',

  Contact: 'Kontakt',

  'upload of individual documents with a size of up to 10 mb and multiple documents at once with a':
    'Upload von einzelnen Dokumenten mit einer Größe von bis zu 10 MB und mehreren Dokumenten auf einmal mit',
  'a size of together up to 50 mb': 'einer Größe von zusammen bis zu 50 MB',

  'Liability for Content': 'Haftung für Inhalte',
  // 'The content provided has been created with greatest care. However we cannot guarantee for the accuracy, completeness, reliability, usability and timeliness of the content. As a service provider we are responsible for own content on these sites under the general laws. On the other hand it is not our duty, to control or check information transmitted or saved from third parties, whether or not they are in line with the law. Obligations to remove or block the use of information under the general laws remain unaffected. However, liability is only possible from the date of knowledge of a specific infringement. Upon notification of such violations, we will remove the content immediately.':
  //   'Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Allerdings sind wir nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.',
  Copyrights: 'Urheberrecht',

  'Data Protection': 'Datenschutz',
  'The collection of person-related data on our website is based, as far as possible, on voluntariness. These data will not be transmitted to third parties without your explicit approval. Please be aware, that the data transmission over the internet (e.g. communication via email) may bear security risks. Therefore a complete protection of data from access of third parties is not possible. We hereby expressly object to the use of contact data published within the framework of the imprint obligation by third parties for sending unsolicited advertising and information material. The site operators reserve the right to take legal action in the event that unsolicited advertising information is sent, such as spam e-mails.':
    'Die Erhebung personenbezogener Daten auf unseren Seiten erfolgt, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.',

  'Usage fee': 'Nutzungsentgelt',
  'The use of AdminCat is available free of charge without restriction for the foreseeable future.':
    'Die Nutzung von AdminCat wird auf absehbare Zeit uneingeschränkt gratis zur Verfügung gestellt.',
  'Obligations of the customer': 'Pflichten des Kunden',
  'The customer will keep the access password to his account secret and change it immediately or have it changed by AdminCat if unauthorized third parties could have gained knowledge of it.':
    'Der Kunde wird das Zugangspasswort zu seinem Account geheim halten und es unverzüglich ändern oder von AdminCat ändern lassen, wenn unbefugte Dritte davon Kenntnis erlangt haben könnten.',
  'The customer is also liable for third parties who use or have used services via his account, either authorized or unauthorized. This does not apply if the customer is not responsible for unauthorized use. The customer must provide evidence that he is not responsible for such use.':
    'Der Kunde haftet auch für Dritte, die befugt oder unbefugt Leistungen über seinen Account nutzen oder genutzt haben. Dies gilt nicht, wenn der Kunde eine unbefugte Nutzung nicht zu vertreten hat. Der Kunde hat den Nachweis zu erbringen, dass er eine solche Nutzung nicht zu vertreten hat.',
  'The customer will not send any advertising by e-mail for commercial purposes via the mail service and will not use the mail service to send standardized e-mails to a large number of recipients.':
    'Der Kunde wird über den Mail Service keine Werbung per E-Mail für kommerzielle Zwecke versenden und den Mail Service nicht zum Versand standardisierter E-Mails an eine Vielzahl von Empfängern nutzen.',
  'Abuse and protection of minors': 'Missbrauch und Jugendschutz',
  'The customer undertakes not to misuse AdminCat':
    'Der Kunde verpflichtet sich, AdminCat nicht missbräuchlich zu nutzen',
  'not to send spam (no more than 100 e-mails per hour and no more than 300 e-mails':
    'keinen Spam zu verschicken (nicht mehr als 100 E-Mails pro Stunde und nicht mehr als 300 E-Mails',
  'per day)': 'pro Tag versenden)',

  'not to violate any laws (e.g. sending forbidden or harassing messages)':
    'nicht gegen Gesetze zu verstossen (z.B. Versenden verbotener oder belästigender Nachrichten)',
  'not to violate regulations for the protection of young people':
    'nicht gegen Vorschriften zum Schutze der Jugend zu verstoßen',
  'not to use any mechanisms, software or scripts in connection with the use of the services that could':
    'keine Mechanismen, Software oder Scripts im Zusammenhang mit der lnanspruchnahme der Dienste verwenden,',
  'impair or disrupt the functioning of admincat':
    'die das Funktionieren von AdminCat beeinträchtigen oder stören können',

  'The customer is liable to AdminCat for damage caused by violating his obligations under 6.1 and releases AdminCat from any third party claims in this regard. This does not apply if he is not responsible for the violation. The customer must provide evidence that he is not responsible for the violation.':
    'Der Kunde haftet AdminCat gegenüber für Schäden, die durch Verstöße gegen seine Pflichten aus 6.1 entstehen und stellt AdminCat von diesbezüglichen Ansprüchen Dritter frei. Dies gilt nicht, wenn er den Verstoß nicht zu vertreten hat. Der Kunde hat den Nachweis zu erbringen, dass er den Verstoß nicht zu vertreten hat.',
  'If the customer uses AdminCat contrary to the provisions of 6.1 and 5.3, the user account can be blocked by AdminCat without prior notice. The right to terminate the contract without notice remains unaffected.':
    'Nutzt der Kunde AdminCat entgegen den Bestimmungen aus 6.1 und 5.3 kann das Nutzerkonto ohne vorherige Ankündigung durch AdminCat gesperrt werden. Das Recht zur fristlosen Kündigung bleibt davon unberührt.',
  Privacy: 'Datenschutz',
  'AdminCat and the customer will comply with the statutory data protection requirements, in particular according to the Telemedia Act (TMG) and the Federal Data Protection Act (BDSG). Inventory and usage data of the customer are only collected, processed or used insofar as this is necessary for the execution of the contractual relationship.':
    'AdminCat und der Kunde werden die gesetzlichen datenschutzrechtlichen Vorgaben, insbesondere nach dem Telemediengesetz (TMG) und dem Bundesdatenschutzgesetz (BDSG) einhalten. Bestands- und Nutzungsdaten des Kunden werden nur erhoben, verarbeitet oder genutzt, soweit dies für die Abwicklung des Vertragsverhältnisses erforderlich ist.',
  'In order to ensure the service integrity and compliance with the Terms of Use of AdminCat, in cases of reasonable suspicion of misuse AdminCat is entitled to determine polluters and restrict the usage of services for these customers.':
    'Um die lntegrität der Dienste und die Einhaltung der Nutzungsbedingungen von AdminCat sicherstellen zu können, ist AdminCat berechtigt, bei begründetem Verdacht auf missbräuchliche Nutzung, den Verursacher festzustellen und die in Anspruch genommene Leistung im Einzelfall für diesen einzuschränken.',
  'Further information on the type, scope and purpose of the collection, processing and use of personal and other data can be found in the Privacy policy of AdminCat.':
    'Weitere Informationen über Art, Umfang und Zwecke der Erhebung, Verarbeitung und Nutzung personenbezogener und anderer Daten finden Sie in der Datenschutzerklärung zu AdminCat.',
  'Warranty and Liability': 'Gewährleistung und Haftung',
  'AdminCat is expressly offered to its users as a beta version and free of charge for testing. It protects the managed data as far as this is possible with the current state of the art. With the continuous further development of the services through updates, however, data can occasionally be lost despite the greatest care. AdminCat assumes no liability for this in test operation, so use is at your own risk.':
    'AdminCat wird seinen Nutzern ausdrücklich als Beta-Version und kostenlos zum Test angeboten. Es schützt die verwalteten Daten soweit dies nach aktuellem Stand der Technik möglich ist. Bei der kontinuierlichen Weiterentwicklung der Leistungen durch Updates kann es allerdings trotz größter Sorgfalt auch vereinzelt zu Datenverlust kommen. AdminCat übernimmt dafür im Testbetrieb keinerlei Haftung, die Nutzung erfolgt somit auf eigene Gefahr.',
  'AdminCat does not guarantee that all of the services mentioned under item 3 are available and free of errors at all times. This applies in particular if access to these services is caused by disruptions that are beyond the control of AdminCat. AdminCat will immediately eliminate disruptions to the services mentioned under item 3 within the scope of the existing technical and operational possibilities.':
    'AdminCat gewährleistet nicht, dass alle unter Ziffer 3 genannten Leistungen jederzeit erreichbar und fehlerfrei sind. Dies gilt insbesondere, soweit der Zugriff auf diese Leistungen durch Störungen verursacht wird, die nicht im Einflussbereich von AdminCat liegen. AdminCat wird Störungen der unter Ziffer 3 genannten Leistungen unverzüglich im Rahmen der bestehenden technischen und betrieblichen Möglichkeiten beseitigen.',
  'Termination, deletion of the account': 'Kündigung, Löschung des Accounts',
  'The usage relationship runs for an indefinite period. It can be terminated by the customer at any time by deleting the user or by AdminCat at the end of a month with a notice period of 1 month. The right to terminate the contract without notice in accordance with section 1.2 or for an important reason (sections 5 and 6) remains unaffected.':
    'Das Nutzungsverhältnis läuft für unbestimmte Zeit. Es kann vom Kunden jederzeit durch Löschung des Users oder von AdminCat zum Ende eines Monats mit einer Frist von 1 Monat gekündigt werden. Das Recht zur fristlosen Kündigung nach Ziffer 1.2 oder aus wichtigem Grund (Ziffern 5 und 6) bleibt davon unberührt.',
  'The customer can only terminate the user relationship via the AdminCat customer menu. AdminCat can terminate the user relationship by e-mail.':
    'Eine Kündigung des Nutzungsverhältnisses durch den Kunden kann nur über das Kundenmenü von AdminCat erfolgen. AdminCat kann das Nutzungsverhältnis per E-Mail kündigen.',
  'The data that is managed by the customer in the role of a space administrator is immediately deleted from the effective date of termination. If other users are interested in the continuation of a space, the space administrator must ensure that the administrator rights are transferred to another user in good time and thus prevent the relevant data from being deleted.':
    'Die vom Kunden als Space-Administrator verwalteten Daten werden sofort ab Wirksamkeit der Kündigung gelöscht. Falls weitere Nutzer ein Interesse an der Fortführung eines Spaces haben, hat der Space-Administrator dafür Sorge zu tragen, die Administratoren-Rechte rechtzeitig auf einen anderen Nutzer zu übertragen und damit die Löschung der entsprechenden Daten zu verhindern.',
  'Governing Law': 'Geltendes Recht',
  'Password Recovery': 'Passwort Wiederherstellung',
  'The law of the Federal Republic of Germany applies exclusively to all legal  relationships between AdminCat and the customer. The law of the United Nations  Convention on the International Sale of Goods (“CISG”) does not apply.':
    'Für sämtliche Rechtsbeziehungen zwischen AdminCat und dem Kunden gilt ausschließlich das Recht der Bundesrepublik Deutschland. Das Gesetz zu dem Übereinkommen der Vereinten Nationen über den internationalen Warenkauf („CISG“) findet keine Anwendung.',

  'Change My Password': 'Ändere mein Passwort',
  'new password': 'Neues Passwort',
  'current password': 'Aktuelles Passwort',
  'enter new password': 'Neues Passwort eingeben',
  'select person group': 'Personengruppe auswählen',
  'Confirm Password': 'Kennwort bestätigen',
  liability: 'Haftung',
  assets: 'Vermögenswerte',
  account: 'Konto',

  'delete?': 'löschen?',
  'are you sure you want to delete?': 'Sind Sie sicher, dass Sie löschen wollen?',
  'yes!': 'Ja!',
  'deleting!': 'Gelöscht!',
  'please wait...': 'Bitte warten Sie...',
  'deleted!': 'gelöscht!',
  'data has been deleted!': 'Die Daten wurden gelöscht!',
  'data could not be deleted': 'Daten konnten nicht gelöscht werden',
  'oops!': 'Huch!',
  'description of the filter': 'Beschreibung der Filter',
  'save criteria': 'Kriterien speichern',
  '--select favorite--': '--Favoriten auswählen--',
  'start date can not be younger than end date':
    'Das Startdatum darf nicht jünger sein als das Enddatum',
  'start or End month should not be less than 01 or greater than 12':
    'Start- oder Endmonat sollte nicht kleiner als 01 oder größer als 12 sein',
  'start date can not be younger than end date':
    'Das Startdatum darf nicht jünger sein als das Enddatum',
  'start or end year should not be younger than 9999 or older than 1900':
    'Das Anfangs- oder Endjahr sollte nicht jünger als 9999 oder älter als 1900 sein.',

  'the total size of the selected files exceeds the maximum allowed size of':
    'Die Gesamtgröße der ausgewählten Dateien überschreitet die maximal zulässige Größe von',
  // Document area
  'one box': 'Filter',
  folder: 'Ordner',
  'full text search': 'Volltextsuche',
  'delete document': 'Dokument löschen',
  'are you sure you want to delete this document?':
    'Sind Sie sicher, dass Sie das Dokument löschen möchten?',
  delete: 'Löschen',
  'document category': 'Dokumentkategorie',
  'filter criteria': 'Filterkriterien',
  'no document uploaded yet!': 'Kein Dokument vorhanden!',
  'no result': 'Kein Ergebnis',
  'no result was found...': 'Kein Ergebnis gefunden.',
  'file name': 'Dateiname',
  'issuing organization': 'Ausstellende Organization',
  "organisation's country": 'Land',
  "organisation's state": 'Bundesland',
  "organisation's city": 'Stadt',
  'document category': 'Dokumentkategorie',
  'last updated': 'Zuletzt aktualisiert',
  'document size': 'Dokumentgröße',
  'no document seleted': 'Kein Dokument ausgewählt.',
  'file details': 'Details zum Dokument',
  'results found': 'Ergebnisse gefunden',
  'the total size of the selected files exceeds the maximum allowed size of 10 mb':
    'Die ausgewählten Dateien überschreiten die maximal zulässige Größe von 10 MB',
  'result(s)': 'Ergebnis(se)',
  dashboard: 'Dashboard',
  housing: 'Wohnen',
  work: 'Arbeit',
  mobility: 'Mobilität',
  leisure: 'Freizeit',
  finance: 'Finanzen',
  'favourite org': 'Lieblingsorg',
  // "loading":"Lädt gerade",
  faqs: 'FAQs',

  'your session has expired. if you wish, please log in again.':
    'Ihre Sitzung wurde beendet. Wenn Sie mögen, melden Sie sich bitte erneut an.',

  phone: 'Telefon',
  fax: 'Telefax',
  'general contact': 'Allgemeiner Kontakt',
  'represented by': 'Vertreten durch',
  credits: 'Impressum',
  germany: 'Deutschland',
  'head office': 'Firmensitz',
  'no support': 'kein Support',
  'please mail to': 'Post bitte an',
  contact: 'Kontakt',
  'liability for content': 'Haftung für Inhalte',

  'the content provided has been created with greatest care. however we cannot guarantee for the accuracy, completeness, reliability, usability and timeliness of the content. as a service provider according to § 7 (1) of the german telemedia act (tmg), we are responsible for own content on these sites under the general laws. however, corresponding to §§ 8 to 10 tmg, it is not our duty, to control or check information transmitted or saved from third parties, whether or not they are in line with the law.':
    'Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir nach § 7 Abs. 1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Allerdings sind wir gemäß §§ 8 bis 10 TMG nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. ',
  'obligations to remove or block the use of information under the general laws remain unaffected. however, liability is only possible from the date of knowledge of a specific infringement. upon notification of such violations, we will remove the content immediately.':
    'Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.',

  copyrights: 'Urheberrecht',

  'the contents and works on these pages compiled by us are subject to copyright law. copying, processing, distribution and any kind of use outside the limits of copyright law require the written consent of us.':
    'Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.',

  'in case the content is not created by us, the copyrights of third parties are being observed. in particular contents of third parties are marked as such. however, if a user becomes aware of a copyright infringement, we ask the user for notification. upon notification of such violations, we will remove the content immediately.':
    'Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.',

  'data protection': 'Datenschutz',

  'the collection of person-related data on our website is based, as far as possible, on voluntariness. these data will not be transmitted to third parties without your explicit approval.':
    'Die Erhebung personenbezogener Daten auf unseren Seiten erfolgt, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.',

  'please be aware, that the data transmission over the internet (e.g. communication via email) may bear security risks. therefore a complete protection of data from access of third parties is not possible.':
    'Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.',

  'we hereby expressly object to the use of contact data published within the framework of the imprint obligation by third parties for sending unsolicited advertising and information material. the site operators reserve the right to take legal action in the event that unsolicited advertising information is sent, such as spam e-mails.':
    'Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.',

  'what is admincat? and why should i use admincat?':
    'Was ist AdminCat? Und warum sollte ich AdminCat nutzen?',
  'admincat is a unique internet service solution for private households striving to completely digitalize and interconnect your paperwork and personal data. this way you can save administrative time and get a more powerful control over your entire life.':
    'AdminCat ist eine einzigartige Internet-Service-Lösung für Privathaushalte, die danach streben, ihren Papierkram und ihre persönlichen Daten vollständig zu digitalisieren und miteinander zu vernetzen. Auf diese Weise können Sie Verwaltungszeit einsparen und erhalten eine bessere Kontrolle über Ihr gesamtes Leben.',

  'which key features does admincat offer?':
    'Welche Hauptfunktionen bietet AdminCat?',
  'with the integrated email-service, attached documents can get saved automatically where they belong and with the right categorization. an ocr text extraction allows a quick and easy full-text search. admincat also allows managing contracts and financial data in over 50 currencies with daily exchange rate updates. reminders make you aware of upcoming expiration of contracts and important documents. and as a cloud service you can reach admincat from anywhere and at anytime. but the best is still to come as admincat is seeking to be your all-in-one admin solution.':
    'Mit dem integrierten Email-Service können angehängte Dokumente automatisch dort gespeichert werden, wo sie hingehören, und mit der richtigen Kategorisierung versehen werden. Eine OCR-Textextraktion ermöglicht eine schnelle und einfache Volltextsuche. AdminCat ermöglicht auch die Verwaltung von Verträgen und Finanzdaten in über 50 Währungen mit tagesaktuellen Wechselkursen. Erinnerungen machen Sie auf das bevorstehende Auslaufen von Verträgen und wichtigen Dokumenten aufmerksam. Und als Cloud-Service können Sie AdminCat von überall und jederzeit erreichen. Aber das Beste kommt erst noch, denn AdminCat will Ihre All-in-One-Verwaltungslösung sein.',

  'which limitations at admincat do i need to know?':
    'Welche Einschränkungen muss ich bei AdminCat kennen?',
  'admincat currently offers a data storage of up to 1 gb per workspace. uploads are possible for documents not greater than 10 mb. and excessively sending emails (>300 a day) are not allowed. for this please also see our terms and conditions.':
    'AdminCat bietet derzeit einen Datenspeicher von bis zu 1 GB pro Arbeitsbereich. Uploads sind für Dokumente nicht größer als 10 MB möglich. Und das übermäßige Versenden von E-Mails (>300 pro Tag) ist nicht erlaubt. Bitte beachten Sie hierzu auch unsere Allgemeinen Geschäftsbedingungen (AGB).',

  'how much does it cost to use admincat?':
    'Wie viel kostet die Nutzung von AdminCat?',
  'we offer a 30-days free of charge trial period to every user initially. after that all of the admincat services and features are offered for a monthly rate of 2 € or a yearly rate of only 19 €.':
    'Wir bieten jedem Nutzer zunächst eine 30-tägige kostenlose Testphase an. Danach werden alle AdminCat-Dienste und -Funktionen für eine monatliche Rate von 2 € oder eine jährliche Rate von nur 19 € angeboten.',

  'is there a notice period for cancelling the contract?':
    'Gibt es eine Kündigungsfrist für den Vertrag?',
  'no, the contract is valid until the end of the prepayment period. this means that your payment alone defines the end of the contract. all user accounts are kept anonymous and hence it is technically not foreseen to offer a premature contract cancellation and payback option.':
    'Nein, der Vertrag ist bis zum Ende der Vorauszahlungsfrist gültig. Das bedeutet, dass allein Ihre Zahlung das Ende des Vertrages bestimmt. Alle Nutzerkonten werden anonym gehalten und daher ist es technisch nicht vorgesehen, eine vorzeitige Vertragsauflösung und Rückzahlungsoption anzubieten.',

  'does admincat automatically delete my data after the contract has ended?':
    'Löscht AdminCat meine Daten automatisch nach Vertragsende?',
  'no. all your workspaces that still have at least one valid and subscribed user will continue to exist. if you haven’t assigned a new admin for them, the system will choose a subscribed successor automatically. workspaces, which are assigned to inactive users only, will also remain in the system - for another two months. during that time, the first user to renew his or her subscription will become the new admin of such a space.':
    'Nein. Alle Workspaces, die noch mindestens einen gültigen und abonnierenden Benutzer haben, bleiben bestehen. Wenn Sie ihnen keinen neuen Admin zugewiesen haben, wählt das System automatisch einen Nachfolger mit Abonnement. Spaces, die nur inaktiven Benutzern zugewiesen sind, bleiben ebenfalls im System erhalten - für weitere zwei Monate. In dieser Zeit wird der erste Benutzer, der sein Abonnement erneuert, der neue Administrator eines solchen Bereichs.',

  'what happens with spaces that have no admin for more than two months?':
    'Was geschieht mit Spaces, die seit mehr als zwei Monaten keinen Administrator haben?',
  'spaces abandoned for more than two months will get deleted automatically. an automatic and immediate deletion also takes place, if all assigned users have already deleted their accounts.':
    'Spaces, die länger als zwei Monate nicht verwaltet werden, werden automatisch gelöscht. Eine automatische und sofortige Löschung erfolgt auch, wenn alle zugewiesenen Nutzer ihre Konten bereits gelöscht haben.',

  'are users also getting deleted automatically?':
    'Werden auch Benutzer automatisch gelöscht?',
  'inactive (unsubscribed) users, that have no active space assignment, will get deleted automatically after one month. this concerns users for instance, which either never completed the registration or space acceptance process or whose spaces got deleted because of the two months inactivity described above.':
    'Inaktive (abgemeldete) Benutzer, die keine aktive Space-Zuordnung haben, werden nach einem Monat automatisch gelöscht. Dies betrifft z.B. Nutzer, die entweder nie den Registrierungs- oder Space-Zulassungsprozess abgeschlossen haben oder deren Spaces aufgrund der oben beschriebenen zweimonatigen Inaktivität gelöscht wurden.',

  'is my data safe on admincat?': 'Sind meine Daten auf dem AdminCat sicher?',
  'for security reasons we constantly keep our software packages up-to-date to avoid exploits. additionally to the standard encryption of our server provider, we encrypt your data a second time, such that even our staff can’t read it. high password standards, a two-factor-authentication (totp), and the automatic session expiration after inactivity, protects all user accounts. we use the secure https protocol for data transmissions through the internet of course and daily backups are stored for the unlikely event of a major data loss.':
    'Aus Sicherheitsgründen halten wir unsere Softwarepakete ständig aktuell, um Exploits zu vermeiden. Zusätzlich zur Standardverschlüsselung unseres Serveranbieters verschlüsseln wir Ihre Daten ein zweites Mal, so dass selbst unsere Mitarbeiter sie nicht lesen können. Hohe Passwortstandards, eine Zwei-Faktor-Authentifizierung (TOTP) und der automatische Ablauf der Sitzung nach Inaktivität schützen alle Benutzerkonten. Für Datenübertragungen über das Internet verwenden wir selbstverständlich das sichere HTTPS-Protokoll, und für den unwahrscheinlichen Fall eines größeren Datenverlustes werden täglich Backups gespeichert.',

  'do you recommend additional security measures for users?':
    'Empfehlen Sie zusätzliche Sicherheitsmaßnahmen für die Benutzer?',
  'we recommend users to obey general security rules concerning password protection and special prudence against malicious phishing mails. additionally you can export your emails from admincat to your local machine manually or establish a synchronization with your email program as an own backup.':
    'Wir empfehlen den Benutzern, die allgemeinen Sicherheitsregeln bezüglich des Passwortschutzes zu befolgen und besondere Vorsicht vor bösartigen Phishing-Mails walten zu lassen. Zusätzlich können Sie Ihre Emails aus AdminCat manuell auf Ihren lokalen Rechner exportieren oder eine Synchronisation mit Ihrem Email-Programm als eigenes Backup einrichten.',

  'are you subject to the gdpr standard in the eu?':
    'Unterliegt AdminCat dem GDPR-Standard in der EU?',
  'yes, admincat exclusively runs on servers located in frankfurt (germany) and is therefore subject to one of the highest privacy-protecting regulations in the world – the gdpr of the european union. for this reason we have contracted an external data security officer, which is regularly checking, monitoring and improving our security processes.':
    'Ja, AdminCat läuft ausschließlich auf Servern in Frankfurt (Deutschland) und unterliegt damit einer der höchsten datenschutzrechtlichen Bestimmungen der Welt - der DSGVO der Europäischen Union. Aus diesem Grund haben wir einen externen Datenschutzbeauftragten unter Vertrag genommen, der unsere Sicherheitsprozesse regelmäßig der Verordnung überprüft, überwacht und verbessert.',

  'does admincat also offer access via app?':
    'Bietet AdminCat auch einen Zugang per App?',
  'not yet. at first we want you to enjoy the generous user experience that our website offers for large-screen devices. but we are planning to introduce a mobile app in the future as well.':
    'Noch nicht. Wir möchten, dass Sie zunächst das großzügige Benutzererlebnis genießen, das unsere Website für Großbildschirme bietet. Aber wir planen, in Zukunft auch eine mobile App einzuführen.',

  'how should i get started with my new account and space?':
    'Wie soll ich mit meinem neuen Konto und dem Space beginnen?',
  'add persons, person groups and favorite organizations. once defined, these units can get assigned to your space, your documents and data like contracts and financial transactions. once logged in you can also check out our quick-tour videos for more recommendations. or you visit our channel series on youtube.':
    'Fügen Sie Personen, Personengruppen und Organisationen-Favoriten hinzu. Einmal definiert, können diese Einheiten Ihrem Space, Ihren Dokumenten und Daten wie Verträgen und finanziellen Transaktionen zugewiesen werden. Sobald Sie eingeloggt sind, können Sie sich auch unsere Quick-Tour-Videos ansehen, um weitere Empfehlungen zu erhalten. Oder Sie besuchen unsere Video-Serie auf YouTube.',

  'does every person as member of a space need a user account?':
    'Braucht jede Person, die in einem Space verwaltet wird, ein Benutzerkonto?',
  'no – like in a household, where only one or two people actively do the paperwork for all. in fact, a user may even be a third person taking care for the household’s administration.':
    'Nein - wie in einem Haushalt, wo nur ein oder zwei Personen aktiv den Papierkram für alle anderen erledigen. Tatsächlich kann ein Nutzer sogar eine dritte Person sein, die sich um die Verwaltung des Haushalts und die entsprechenden Personen in einem Space kümmert.',

  'what is the basis for the data on the dashboard?':
    'Was ist die Grundlage für die Daten auf dem Dashboard?',
  'each space represents a person group, which may be the members of a household or another type of personal economic unit. the dashboard displays data corresponding to this person group. if the person group members change, the financial data on the dashboard changes too.':
    'Jeder Space repräsentiert eine Personengruppe, die aus den Mitgliedern eines Haushalts oder einer anderen Art von persönlicher Wirtschaftseinheit bestehen kann. Das Dashboard zeigt Daten an, die dieser Personengruppe entsprechen. Wenn sich die Mitglieder der Personengruppe ändern, ändern sich auch die Finanzdaten auf dem Dashboard.',

  'how can i contact admincat in case of questions or feedback?':
    'Wie kann ich AdminCat kontaktieren, wenn ich Fragen oder Feedback habe?',
  'as an active user you can find the option "contact us" in the user menu on the top right. else you can also write to support@admincat.net directly. we appreciate your valuable comments and feedback, which will of course play an important role in our further product development. but please be aware, that we can only give general support and guidance, as your data is invisible for us on the database.':
    'Als aktiver Benutzer finden Sie die Option "Kontaktieren Sie uns" im Benutzermenü oben rechts. Ansonsten können Sie auch direkt an support@admincat.net schreiben. Wir freuen uns über Ihre wertvollen Kommentare und Rückmeldungen, die natürlich eine wichtige Rolle bei der weiteren Produktentwicklung spielen werden. Bitte bedenken Sie aber, dass wir nur allgemeine Hilfestellungen geben können, da Ihre Daten für uns in der Datenbank nicht sichtbar sind.',

  'could i join the admincat team?': 'Kann ich dem AdminCat-Team beitreten?',
  'of course! we are honored by your interest. just send us an email and describe your main experiences and biggest strengths and we will come back to you.':
    'Ja, natürlich! Wir freuen uns über Ihr Interesse. Schicken Sie uns einfach eine E-Mail und beschreiben Sie Ihre wichtigsten Erfahrungen und größten Stärken und wir kommen auf Sie zurück.',

  'privacy statement': 'Datenschutzerklärung',
  'thank you for your interest in our online service. the protection of your data is very important to us. we therefore apply the utmost care and state-of-the-art security standards to ensure maximum protection of your data. below we will explain in detail, which data admincat is processing and how it protects this data.':
    'Wir freuen uns über Ihr Interesse an unserem Online-Service. Der Schutz Ihrer Daten liegt uns sehr am Herzen. Daher wenden wir äußerste Sorgfalt und modernste Sicherheitsstandards an, um einen maximalen Schutz Ihrer Daten zu gewährleisten. Im Folgenden erklären wir Ihnen ausführlich, welche Daten AdminCat verarbeitet und wie es diese Daten schützt.',

  'the person responsible for data processing is':
    'Verantwortlicher für die Datenverarbeitung ist',

  'legal basis': 'Rechtsgrundlage',
  "the provision of our services requires the processing of data and takes place in accordance with the statutory provisions. for data protection, the general data protection regulation (gdpr), the applicable federal data protection act (bdsg), the telemedia act (tmg) and telecommunications secrecy are decisive. admincat's services are hosted exclusively on servers in germany (frankfurt am main).":
    'Die Erbringung unserer Dienstleistungen erfordert die Verarbeitung von Daten und erfolgt entsprechend den gesetzlichen Vorschriften. Für den Datenschutz sind insbesondere die Datenschutzgrundverordnung (DSGVO), das geltende Bundesdatenschutzgesetz (BDSG), das Telemediengesetz (TMG) sowie das Fernmeldegeheimnis maßgeblich. Die Dienste von AdminCat werden ausschliesslich auf Servern in Deutschland (Frankfurt am Main) gehostet.',

  terms: 'Begriffe',
  "the legislator requires that personal data be processed in a lawful manner, in good faith and in a manner that is understandable for the person concerned ('lawfulness, processing in good faith, transparency'). to ensure this, we inform you about the individual legal definitions that are also used in this data protection declaration":
    'Der Gesetzgeber fordert, dass personenbezogene Daten auf rechtmäßige Weise, nach Treu und Glauben und in einer für die betroffene Person nachvollziehbaren Weise verarbeitet werden („Rechtmäßigkeit, Verarbeitung nach Treu und Glauben, Transparenz“). Um dies zu gewährleisten, informieren wir Sie über die einzelnen gesetzlichen Begriffsbestimmungen, die auch in dieser Datenschutzerklärung verwendet werden',

  'personal data': 'Personenbezogene Daten',
  '"personal data" is all information that relates to an identified or identifiable natural person (hereinafter “data subject”); a natural person is regarded as identifiable who can be identified directly or indirectly, in particular by means of assignment to an identifier such as a name, an identification number, location data, an online identifier or one or more special features that express the physical, physiological, genetic, psychological, economic, cultural or social identity of this natural person.':
    '“Personenbezogene Daten” sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person (im Folgenden „betroffene Person“) beziehen; als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten,iner Online-Kennung oder zu einem oder mehreren besonderen Merkmalen identifiziert werden kann, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind.',

  processing: 'Verarbeitung',
  '"processing" is any process carried out, with or without the help of automated processes, or any such series of processes in connection with personal data such as the collection, recording, organization, ordering, storage, adaptation or change, reading out, querying, use, disclosure by transmission, distribution or any other form of provision, comparison or linking, restriction, deletion or destruction.':
    '“Verarbeitung” ist jeder, mit oder ohne Hilfe automatisierterVerfahren, ausgeführter Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.',

  'restriction of processing': 'Einschränkung der Verarbeitung',
  '"restriction of processing" is the marking of stored personal data with the aim of restricting their future processing.':
    '"Einschränkung der Verarbeitung" ist die Markierung gespeicherter personenbezogener Daten mit dem Ziel, ihre künftige Verarbeitung einzuschränken.',

  profiling: 'Profiling',
  '"profiling" is any type of automated processing of personal data that consists of using this personal data to evaluate certain personal aspects relating to a natural person, in particular aspects relating to work performance, economic situation, health,to analyze or predict personal preferences, interests, reliability, behavior, whereabouts or relocation of this natural person.':
    '"Profiling" ist jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin besteht, dass diese personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf eine natürliche Person beziehen, zu bewerten, insbesondere um Aspekte bezüglich Arbeitsleistung, wirtschaftliche Lage, Gesundheit, persönliche Vorlieben, Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen Person zu analysieren oder vorherzusagen.',

  pseudonymization: 'Pseudonymisierung',
  '"pseudonymization" is the processing of personal data in such a way that the personal data can no longer be assigned to a specific data subject without the use of additional information, provided that this additional information is stored separately and is subject to technical and organizational measures that ensure that the personal data cannot be assigned to an identified or identifiable natural person.':
    '"Pseudonymisierung" ist die Verarbeitung personenbezogener Daten in einer Weise, dass die personenbezogenen Daten ohne Hinzuziehung zusätzlicher Informationen nicht mehr einer spezifischen betroffenen Person zugeordnet werden können, sofern diese zusätzlichen Informationen gesondert aufbewahrt werden und technischen und organisatorischen Maßnahmen unterliegen, die gewährleisten, dass die personenbezogenen Daten nicht einer identifizierten oder identifizierbaren natürlichen Person zugewiesen werden können.',

  'file system': 'Dateisystem',
  '"file system" is any structured collection of personal data that is accessible according to certain criteria, regardless of whether this collection is managed centrally, decentralized or according to functional or geographical criteria':
    '"Dateisystem" ist jede strukturierte Sammlung personenbezogener Daten, die nach bestimmten Kriterien zugänglich sind, unabhängig davon, ob diese Sammlung zentral, dezentral oder nach funktionalen oder geografischen Gesichtspunkten geordnet geführt wird.',

  'person responsible': 'Verantwortlicher',
  '"person responsible" is a natural or legal person, authority, institution or other body that alone or jointly with others decides on the purposes and means of processing personal data; if the purposes and means of this processing are specified by union law or the law of the member states, the person responsible or the specific criteria for his appointment can be provided for in accordance with union law or the law of the member states.':
    '"Verantwortlicher" ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet; sind die Zwecke und Mittel dieser Verarbeitung durch das Unionsrecht oder das Recht der Mitgliedstaaten vorgegeben, so können der Verantwortliche beziehungsweise die bestimmten Kriterien seiner Benennung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten vorgesehen werden.',

  'access data': 'Zugriffsdaten',
  'admincat collects data and information, some of which is personal data, each time the website is accessed. this data is stored in the server log files. the following can be recorded':
    'AdminCat erfasst bei jedem Aufruf der Webseite Daten und Informationen, die teilweise auch personen-bezogene Daten darstellen. Diese Daten werden in den Logfiles des Servers gespeichert. Erfasst werden können',

  'web request and interaction with a service, e.g. paypal':
    'Webanfrage und Interaktion mit einem Service, z.B. PayPal',
  'the internet protocol address (ip address)':
    'Die Internetprotokoll-Adresse (IP-Adresse)',
  'browser type and language of the browser': 'Browsertyp und Sprache des Browsers',
  'date and time of access to our website':
    'Datum und Uhrzeit eines Zugriffs auf unsere Webseite',
  'time zone difference to greenwich mean time (gmt)':
    'Zeitzonendifferenz zur Greenwich Mean Time (GMT)',
  'the email address of a space, if a user is logged in there':
    'Die Email-Adresse eines Space, wenn ein User dort eingeloggt ist',
  'access status/https status code': 'Zugriffsstatus/HTTPS-Statuscode',

  'the legal basis for data processing is art. 6 (1) p. 1 lit. f gdpr. our legitimate interest is based on the following purposes for data collection':
    'Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Unser berechtigtes Interesse begründet sich aus folgenden Zwecken zur Datenerhebung',
  'ensuring a smooth connection to the website':
    'Sicherstellung einer reibungslosen Verbindung der Website',
  'ensuring a convenient use of our website':
    'Sicherstellung einer komfortablen Nutzung unserer Website',
  'evaluation of system security and stability, and':
    'Bewertung der Systemsicherheit und -stabilität, und',
  'for other administrative purposes': 'für andere administrative Zwecke',

  'after the end of the communication process, the collected information is only evaluated for statistical purposes and in anonymized form. under no circumstances do we use the data collected for the purpose of drawing conclusions about your person.':
    'Nach Beendigung des Kommunikationsvorgangs werden die gesammelten Informationen lediglich zu statistischen Zwecken und in anonymisierter Form ausgewertet. In keinem Fall verwenden wir die erhobenen Daten, um Rückschlüsse auf Ihre Person zu ziehen.',

  cookies: 'Cookies',
  "admincat doesn't use any cookie. therefore we also don't provide an integrated cookie consent tool on our site, which usually allows you to administrate your opt-in choices related to certain cookies and their features.":
    'AdminCat verwendet keine Cookies. Daher bieten wir auf unserer Website auch kein integriertes Cookie-Zustimmungstool an, mit dem Sie normalerweise Ihre Opt-in-Optionen in Bezug auf bestimmte Cookies und deren Funktionen verwalten können. ',

  'inventory and payment data': 'Bestands- und Bezahldaten',
  'all you need to create a user account is an e-mail address. we use this to send you a link to confirm your registration and to reset your password. we do not collect any other inventory data. your email address is not passed on to third parties, nor do we use third-party data to enrich your email address with this data and create a profile about you.':
    'Zur Erstellung eines Nutzerkontos wird lediglich eine Email-Adresse benötigt. Diese verwenden wir, um Ihnen zur Bestätigung Ihrer Registrierung und im Falle des Zurücksetzens Ihres Passwortes jeweils einen entsprechenden Link zusenden zu können. Weitere Bestandsdaten werden von uns nicht erhoben. Ihre Email-Adresse wird weder an Dritte weitergegeben, noch nutzen wir Daten Dritter, um Ihre Email-Adresse mit diesen Daten anzureichern und ein Profil über Sie zu erstellen.',
  'although you can voluntarily enter or view extensive data in a space to which you have been granted access (about people, addresses, health data, contract data and much more), the members of these spaces are themselves responsible for the data managed there. admincat employees have virtually no access to this data (see explanations under 7. content data).':
    'Zwar können Sie in einem Space, zu dem Sie Zugang erhalten haben, freiwillig umfangreiche Daten erfassen oder einsehen (über Personen, Adressen, Gesundheitsdaten, Vertragsdaten und vieles mehr), allerdings sind die Mitglieder dieser Spaces für die dort verwalteten Daten selbst verantwortlich. Mitarbeiter von AdminCat haben darauf so gut wie keinen Zugriff (siehe Erläuterungen unter 7. Inhaltsdaten).',
  'you can pay for our services, such as the paid subscription, via payment services such as paypal. the amount, the subscription period and a paypal reference in connection with your user account are stored in our database for you as confirmation and overview for each payment transaction processed via this service, but only in double-encrypted form, so that our employees have no access to this data.':
    'Unsere Leistungen wie z.B. das kostenpflichtige Abo können Sie über Bezahldienste wie PayPal bezahlen. Für jeden darüber abgewickelten Bezahlvorgang wird der Betrag, der Abo-Zeitraum und eine PayPal-Referenz in Verbindung mit Ihrem Nutzerkonto in unserer Datenbank für Sie als Bestätigung und Übersicht gespeichert, allerdings nur in doppelt verschlüsselter Form, sodass unsere Mitarbeiter hierauf keinerlei Zugriff haben.',

  'content data (email, documents and recorded data)':
    'Inhaltsdaten (Email, Dokumente und erfasste Daten)',
  'admincat manages emails, documents and extensive master and transaction data for you (insofar as you decide to use it). only you as the user should have sovereignty and full control over this data. that is why we offer you maximum protection with our security concept':
    'AdminCat verwaltet für Sie (insoweit Sie sich für deren Nutzung entscheiden) Emails, Dokumente und umfangreiche Stamm- und Bewegungsdaten. Die Hoheit über diese Daten sollen allein Sie als Nutzer haben. Daher bieten wir Ihnen mit unserem Sicherheitskonzept maximalen Schutz',

  'admincat is hosted on an aws server in frankfurt am main (germany) and is therefore subject to the strictest eu data protection regulations; aws has iso/iec certifications 27001:2022, 27017:2015 and 27018:2019':
    'AdminCat wird auf einem Server der Firma AWS in Frankfurt am Main (Deutschland) gehostet und unterliegt damit strengsten EU-Datenschutzvorgaben; AWS besitzt die ISO/IEC-Zertifizierungen 27001:2022, 27017:2015 und 27018:2019',
  'we secure communication with your end device using the modern tls 1.3 (transport layer security) standard, which means that all data is transmitted in encrypted form and protected against manipulation and unauthorized access by third parties during transmission':
    'Die Kommunikation mit Ihrem Endgerät sichern wir mit dem modernen Standard TLS 1.3 (Transport Layer Security), wodurch sämtliche Daten verschlüsselt übermittelt werden und auf dem Übertragungsweg vor Manipulationen und dem unbefugten Zugriff Dritter geschützt sind',
  'we counter potential attackers with high password standards, automatic session termination after 1 hour of inactivity and optional two-factor authentication (2fa)':
    'Wir begegnen möglichen Angreifern mit hohen Passwort-Standards, einer automatischen Sitzungsbeendigung nach 1 Stunde Inaktivität und einer optionalen Zwei-Faktor-Authentisierung (2FA)',
  'in addition to aws encryption, your content data is stored on a second encrypted database and access to it is only permitted using keys assigned to your login data':
    'Ihre Inhaltsdaten werden zusätzlich zu der AWS-Verschlüsselung auf einer zweitverschlüsselten Datenbank gespeichert und ein Zugriff darauf ausschließlich durch Ihren Logindaten zugeordnete Schlüssel erlaubt',
  'the column names in the tables of our database are still masked in such a way that an unauthorized reader can hardly interpret or assign the content of the columns':
    'Die Spaltenbezeichnungen in den Tabellen unserer Datenbank sind weiterhin derart maskiert, dass ein unbefugter Leser den Inhalt der Spalten kaum interpretieren bzw. zuordnen kann',
  'in rare cases, complete encryption of the data is associated with unacceptable performance losses, e.g. for full-text searches in documents uploaded by you. therefore, instead of secondary encryption, rigorous text splitting is used here, which means that the specific text content remains protected for you':
    'In seltenen Fällen ist eine Komplettverschlüsselung der Daten mit unzumutbaren Leistungseinbußen verbunden, z.B. bei der Volltextsuche in von Ihnen hochgeladenen Dokumenten. Daher kommt hier anstelle der Zweit-Verschlüsselung eine rigorose Textsplittung zum Einsatz, durch die der konkrete Textinhalt für Sie weiterhin geschützt bleibt',

  'service contact (contact form, email, phone)':
    'Servicekontakt (Kontaktformular, Email, Telefon)',
  'you can contact us via a contact form (accessible from your user account), by email or by phone. these communication channels are generally open to you for your questions. we are also very interested in your feedback, what you like and your suggestions for improvements.':
    'Sie können uns über ein Kontaktformular (von Ihrem Nutzerkonto aus zugänglich), per Email oder Telefon kontaktieren. Diese Kommunikationswege stehen Ihnen allgemein für Ihre Fragen offen. Darüber hinaus interessieren wir uns sehr für Ihr Feedback, was Ihnen gefällt und Ihre Vorschläge bezüglich Verbesserungen.',

  'personal data is not required, unless you may wish to be called back or receive an answer by email. to analyze errors on the site, we ask you for a description of the error as detailed as possible, but we will never ask for personal data or even login data. seven days after each request has been completed, we delete all related messages in our systems. your telephone number will remain on a call list on one of our telephones for one month without a name reference until it is deleted; no additional storage or further processing will take place.':
    ' Personenbezogene Daten werden hierfür nicht benötigt, außer Sie wünschen evtl. einen Rückruf oder eine Antwort per Email. Zur Analyse von Fehlern auf der Seite bitten wir Sie um eine möglichst detailierte Fehlerbeschreibung, personenbezogene Daten oder sogar Login-Daten werden wir dafür allerdings niemals erfragen. Sieben Tage nach Abschluss einer jeden Anfrage löschen wir sämtliche dazugehörige Nachrichten in unseren Systemen. Ihre Telefonnummer bleibt bis zur Löschung einen Monat ohne Namenbezug auf einer Anrufliste eines unserer Telefone, eine zusätzliche Speicherung bzw. Weiterverarbeitung findet nicht statt.',

  'disclosure of data': 'Weitergabe von Daten',
  'admincat does not pass on any data to third parties unless there is a legal obligation to do so or the transfer of the data is absolutely necessary for the provision of a service requested by the user (art. 6 para. 1 lit. f gdpr). this section provides information about such services, our partners involved in them and what data is used for what purpose. users who make use of these services on our website tacitly consent to the corresponding processing.':
    'AdminCat gibt keine Daten an Dritte weiter, es sei denn es gibt dazu gesetzliche Verpflichtungen oder die Weitergabe der Daten ist für die Erbringung einer vom Nutzer gewünschten Dienstleistung unbedingt erforderlich (Art. 6 Abs. 1 lit. f DSGVO). Dieser Abschnitt informiert über solche Services, unsere dafür eingebundenen Partner und welche Daten zu welchem Zweck verwendet werden. Nutzer, die diese Dienste auf unserer Seite in Anspruch nehmen, geben zur entsprechenden Verarbeitung stillschweigend ihr Einverständnis.',
  'aws is our hosting partner, on whose servers our website is operated in frankfurt am main (germany). this means that aws is of course bound by the strict european data protection laws (gdpr), and under no circumstances may it access your data or pass data on to third parties. the open source software awstats is used on its servers to analyze log files, which means that no cookies are required by your browser. aws only uses anonymized ip address data for this purpose. this data cannot be assigned to a specific person and is not forwarded to third parties.':
    'AWS ist unser Hosting-Partner, auf dessen Servern unsere Webseite in Frankfurt am Main (Deutschland) betrieben wird. Damit ist AWS selbstverständlich an die strengen europäischen Datenschutzgesetze (DSGVO) gebunden, unter keinen Umständen darf es weder auf Ihre Daten zugreifen noch Daten an Dritte weitergeben. Auf seinen Servern wird zur Analyse von Logfiles die OpenSource-Software AWStats eingesetzt, wodurch keine Cookies durch Ihren Browser notwendig sind. AWS verwendet dazu nur anonymisierte IP-Adressdaten. Diese Daten sind keiner bestimmten Person zuordenbar, eine Weiterleitung an Dritte findet nicht statt.',
  'if you take out a subscription on our website, you can use the service provider paypal for payment. to do this, you need a corresponding user account with paypal, for which you agree to the terms and conditions and privacy policy accordingly. for each payment process, admincat only transmits the absolutely necessary data such as provider, amount and currency and receives the status and a paypal reference number of the payment from paypal.':
    'Wenn Sie auf unserer Seite ein Abonnement abschließen, können Sie zur Bezahlung den Dienstleister PayPal verwenden. Dafür benötigen Sie ein entsprechendes Nutzer-Konto bei PayPal, zu dessen Eröffnung Sie sich dort mit AGB und Datenschutzerklärung einverstanden erklären. AdminCat übermittelt bei jedem Bezahlvorgang lediglich die dafür absolut notwendigen Daten wie Anbieter, Betrag und Währung und erhält von PayPal jeweils den Status und eine PayPal-Referenznummer der Zahlung.',
  'you will find embedded videos on our pages that are provided by the youtube service. youtube is operated by google ireland limited, gordon house, 4 barrow st, dublin, d04 e5w5, ireland. when you play a video, a direct connection to the google servers is established. your ip address and the address of the page from which you are watching the video will be transmitted. youtube also uses a cookie that collects further user data in order to offer matching videos. if you have a google user account and are currently logged in, youtube can link data, such as the video you clicked on, to your google user account. if you do not wish this to happen, you must log out of google.':
    'Auf unseren Seiten finden Sie eingebettete Videos, die durch den Dienst YouTube bereitgestellt werden. Betreiber von YouTube ist die Firma Google Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5, Ireland. Wenn Sie ein Video abspielen, wird eine direkte Verbindung zu den Servern der Firma Google hergestellt. Es wird Ihre IP-Adresse und die Adresse der Seite übermittelt, von der aus Sie das Video ansehen. Außerdem verwendet YouTube ein Cookie, welches weitere Nutzerdaten sammelt, um dazu passende Videos anzubieten. Wenn Sie über ein Nutzerkonto von Goolge verfügen und aktuell angemeldet sind, kann YouTube Daten, wie das angeklickte Video, mit Ihrem Google-Nutzerkonto verknüpfen. Falls Sie dies nicht wünschen, müssen Sie sich bei Google abmelden (ausloggen).',
  "we also use google fonts on our website, that are loaded onto your device from the google fonts servers by your browser. in this way, we can consistently present our texts in an appealing form (art. 6 para. 1 lit. f gdpr). you can find more information about google's data protection at":
    'Weiterhin verwenden wir auf unserer Webseite Schriftarten, die von Google zur Verfügung gestellt werden. Die Schriftarten werden dabei von den Servern von Google Fonts durch Ihren Browser auf Ihr Endgerät geladen. Auf diese Weise können wir unsere Texte immer in ansprechender Form darstellen (Art. 6 Abs. 1 lit. f DSGVO). Weitere Informationen zum Datenschutz von Google finden Sie in der Datenschutzerklärung unter',
  'https://policies.google.com/privacy.':
    'https://policies.google.com/privacy?hl=de.',
  'admincat does not use social media plugins (e.g. for facebook, instagram or x). we also do not use tracking and analysis services such as google analytics. furthermore, we do not use user profiling and automated decisions based on this, nor do we display advertising or send newsletters.':
    'AdminCat nutzt keine Social-Media-Plugins (wie z.B. für Facebook, Instagram oder X). Auch Tracking und Analyse-Dienste wie z.B. Google Analytics sind bei uns nicht im Einsatz. Weiterhin verzichten wir komplett sowohl auf ein sogenanntes User-Profiling und darauf basierende automatisierte Entscheidungen als auch auf die Anzeige von Werbung und das Versenden von Newslettern.',

  others: 'Sonstiges',
  'admincat does not pass on any data to third parties (except in the case of a legal obligation). the site also does not use any social media plug-ins (such as for facebook, instagram or twitter). we also do not use tracking and analysis services such as google analytics. furthermore, we completely dispense with so-called user profiling and automated decisions based on it, as well as the display of advertising and the sending of newsletters.':
    'AdminCat gibt keine Daten an Dritte weiter (außer im Falle einer gesetzlichen Verpflichtung). Die Seite nutzt zudem keinerlei Social-Media-Plugins (wie z.B. für Facebook, Instagram oder Twitter). Auch Tracking und Analyse-Dienste wie z.B. Google Analytics sind bei uns nicht im Einsatz. Weiterhin verzichten wir komplett sowohl auf ein sogenanntes User-Profiling und darauf basierende automatisierte Entscheidungen als auch auf die Anzeige von Werbung und das Versenden von Newslettern.',
  'data protection rights of the data subject':
    'Datenschutzrechte der betroffenen Person',

  'conditions for consent': 'Recht auf Widerruf der Einwilligung',
  'art. 7 paragraph 3 gdpr': 'Art. 7 Abs 3 DSGVO',
  'if the processing of personal data is based on consent given, you have the right to revoke your consent at any time in accordance with':
    'Sofern die Verarbeitung personenbezogener Daten auf einer erteilten Einwilligung beruht, haben Sie gemäß',
  'revoking your consent does not affect the legality of the processing carried out on the basis of your consent up to the point of revocation.':
    'jederzeit das Recht, die Einwilligung zu widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.',

  'you can contact us at any time to exercise your right of withdrawal.':
    'Für die Ausübung des Widerrufsrechts können Sie sich jederzeit an uns wenden.',

  'right of access by the data subject': 'Recht auf Bestätigung',
  'in accordance with': 'Sie haben gemäß',
  'art. 15 gdpr': 'Art. 15 DSGVO',
  'you have the right to request a confirmation from admincat as to whether we are processing personal data related to you. if that is the case, you have the right to information about this personal data, the details of the processing and comprehensive information about your rights. you can request confirmation at any time using the contact details above.':
    'das Recht, von AdminCat eine Bestätigung darüber zu verlangen, ob bei uns Sie betreffende personenbezogene Daten verarbeitet werden. Sollte das der Fall sein, haben Sie das Recht auf Auskunft über diese personenbezogenen Daten, die Details der Verarbeitung und eine umfassende Belehrung über Ihre Rechte. Die Bestätigung können Sie jederzeit unter den oben genannten Kontaktdaten verlangen.',

  'right to rectification': 'Recht auf Berichtigung',
  'in accordance with': 'Sie haben gemäß',
  'art. 16 gdpr': 'Art. 16 DSGVO',
  'you have the right to demand immediate rectification or completion of personal data about you that is administered by us.':
    'das Recht, von uns unverzüglich das Berichtigen oder Vervollständigen von personenbezogenen Daten zu verlangen, die bei uns zu Ihnen gespeichert sind.',

  'right to erasure (right to be forgotten)':
    'Recht auf Löschung (Recht auf vergessen werden)',
  'in accordance with': 'Sie haben gemäß',
  'art. 17 gdpr': 'Art. 17 DSGVO',
  'you have the right to request the immediate deletion of personal data that admincat has stored about you, provided that it is no longer necessary for the purposes for which it was collected or otherwise processed.':
    'das Recht, das unverzügliche Löschen von personenbezogenen Daten zu verlangen, die bei AdminCat zu Ihnen gespeichert sind, sofern sie für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig sind.',

  'right to restriction of processing': 'Recht auf Einschränkung der Verarbeitung',
  'art. 18 gdpr': 'Art. 18 DSGVO',
  'according to': 'Gemäß',
  // "according to":"Jede von der Verarbeitung personenbezogener Daten betroffene Person hat gemäß",
  'any data subject affected by the processing of personal data, has the right to require the person responsible for restricting the processing, if such information is incorrect, the processing illegitimate or inexpedient, or if an objection according to':
    'das Recht, von dem Verantwortlichen die Einschränkung der Verarbeitung zu verlangen, falls diese Daten inkorrekt sind, die Verarbeitung unrecht- oder unzweckmäßig ist oder falls ein Widerspruch gemäß',

  'art. 21 paragraph 1': 'Art. 21 Abs 1',
  'is present.': 'vorliegt.',

  'in order to exercise the right to restriction of processing, the data subject can contact us at any time using the contact details given above.':
    'Um das Recht auf Einschränkung der Verarbeitung geltend zu machen, kann sich die betroffene Person jederzeit unter den oben angegebenen Kontaktdaten an uns wenden.',
  'notification obligation to receiving third parties':
    'Mitteilungspflicht an empfangende Dritte',

  'if the person responsible is sharing personal data of the data subject with third parties, according to':
    'Sollte der Verantwortliche personenbezogene Daten an Dritte weitergeben, so muss er gemäß',
  'art. 19 gdpr': 'Art. 19 DSGVO',
  'he must notify these recipients when personal data have been corrected or deleted':
    'diesen Empfängern mitteilen, wenn personenbezogene Daten berichtigt oder gelöscht wurden',
  'you have the right to ask companies to whom this information was sent':
    'Sie haben das Recht, bei Unternehmen zu erfragen, an welche Empfänger diese Informationen gingen',

  'however, since we do not pass on any data to third parties, this passage has no practical significance for you.':
    'Da wir allerdings keine Daten an Dritte weitergeben, hat dieser Passus für Sie hier keine praktische Bedeutung.',

  'right to data portability': 'Recht auf Datenübertragbarkeit',
  'if you have provided a company with personal data in an automated process, you have the right, in accordance with':
    'Haben Sie einem Unternehmen in einem automatisierten Verfahren personenbezogene Daten bereitgestellt, haben Sie gemäß',
  'art. 20 gdpr': 'Art. 20 DSGVO',
  'to receive the personal data stored there about you in a structured, common and machine-readable format or to request that it be transmitted to another person responsible. at least as far as this is technically feasible.':
    'das Recht, die dort über Sie gespeicherten personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen. Zumindest, so weit dies technisch machbar ist.',
  'right to object': 'Recht auf Widerspruch',

  'in accordance with': 'Sie haben gemäß',
  'art. 21 gdpr': 'Art. 21 DSGVO',
  'you have the right to object to processing of personal data at admincat at any time, unless there are compelling legitimate grounds for the processing.':
    'das Recht, jederzeit dem Verarbeiten von personenbezogenen Daten bei AdminCat zu widersprechen, es sei denn, es gibt zwingende schutzwürdige Gründe für diese Verarbeitung.',

  'according to': 'Gemäß',
  'art. 22 gdpr': 'Art. 22 DSGVO',

  'to exercise the right to object you can contact the relevant person responsible at any time.':
    'Zum Ausüben des Widerspruchsrechts können Sie sich jederzeit an den jeweiligen Verantwortlichen wenden.',
  'automated individual decision-making, including profiling':
    'Automatisierte Entscheidungen im Einzelfall einschließlich Profiling',

  // "according to":"Jede von der Verarbeitung personenbezogener Daten betroffene Person hat gemäß",
  'every person affected by the processing of personal data has the right not to be subject to a decision based on automated processing - including profiling. such processing is not used at admincat.':
    'das Recht, nicht einer auf einer automatisierten Verarbeitung – einschließlich Profiling – beruhenden Entscheidung unterworfen zu werden. Bei AdminCat ist eine solche Verarbeitung nicht im Einsatz.',

  'communication of a personal data breach to the data subject':
    'Benachrichtigung bei Verletzung des Datenschutzes',
  'if a company saves your personal data, it must inform you immediately in accordance with':
    'Speichert ein Unternehmen personenbezogene Daten von Ihnen, muss es Sie gemäß',
  'art. 34 gdpr': 'Art. 34 DSGVO',
  'if the protection of this data has been breached, we will inform you about security-relevant incidents in which the security of your data stored at admincat could be violated by email and on our website and give you tips for suitable measures.':
    'unverzüglich darüber informieren, wenn der Schutz dieser Daten verletzt wurde. Über sicherheitsrelevante Vorfälle, bei denen die Sicherheit Ihrer bei AdminCat gespeicherten Daten verletzt werden könnte, werden wir Sie per E-Mail und auf unserer Webseite informieren und Tipps für geeignete Maßnahmen mitteilen.',

  'right to lodge a complaint with a supervisory authority':
    'Recht auf Beschwerde bei einer Aufsichtsbehörde',
  'according to': 'Gemäß',
  'art. 77 gdpr': 'Art. 77 DSGVO',
  'you have the right to complain to a supervisory authority. the responsible supervisory authorities are':
    'haben Sie das Recht, sich bei einer Aufsichtsbehörde zu beschweren. Die zuständigen Aufsichtsbehörden sind',

  'affected persons have the right to complain to the competent technical supervisory authority in the event of data protection problems. the appropriate authority for us is':
    'Betroffene haben das Recht, sich bei datenschutzrechtlichen Problemen bei der zuständigen Fachaufsichtsbehörde zu beschweren. Die entsprechende Stelle für AdminCat ist',

  'topicality and changes to this privacy statement':
    'Aktualität und Änderung dieser Datenschutzerklärung',

  'this privacy statement is currently valid and is dated june 2024. due to the further development of admincat or due to changed legal or official requirements, it may be necessary to change this privacy statement. in this case, we will inform our users in advance by email.':
    'Diese Datenschutzerklärung ist aktuell gültig und hat den Stand Juni 2024. Durch die Weiterentwicklung von AdminCat oder aufgrund geänderter gesetzlicher beziehungsweise behördlicher Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu ändern. In diesem Fall informieren wir unsere Nutzer vorab per Email.',

  'terms and conditions': 'Allgemeine Geschäftsbedingungen',
  'scope and changes to these terms of use':
    'Geltungsbereich und Änderungen dieser Nutzungsbedingungen',

  'these terms of use regulate the service utilization based relationship between the customer and admincat gmbh (hereinafter "admincat" or "we"), landgraf-karl-str. 21a, 34131 kassel, germany (e-mail:':
    'Diese Nutzungsbedingungen regeln das Dienstleistungsverhältnis zwischen dem Kunden und der AdminCat GmbH (nachfolgend "AdminCat" oder "wir"), Landgraf-Karl-Str. 21A, 34131 Kassel, Deutschland (E-Mail:',
  'admin@admincat.net': 'admin@admincat.net',
  'as a provider of e-mail, data and document storage services.':
    'als Anbieter von E-Mail-, Daten- und Dokumentenspeicherdiensten.',

  'admincat reserves the right to change these terms of use as well as the service description and prices. admincat shall inform the customer of any changes. if changes are made to the detriment of the customer, he may terminate the user relationship without notice, close his account and have the remaining prepaid amount refunded. admincat informs the customer of this right of termination in the notification of change.':
    'AdminCat behält sich vor, diese Nutzungsbedingungen sowie die Leistungsbeschreibung und Preise zu ändern. Änderungen teilt AdminCat dem Kunden mit. Erfolgen Änderungen zu Ungunsten des Kunden, kann dieser das Nutzungsverhältnis fristlos kündigen, sein Konto auflösen und sich den verbliebenen Prepaid-Betrag zurückzahlen lassen. Auf dieses Kündigungsrecht weist AdminCat den Kunden in der Änderungsmitteilung hin.',

  'usage relationship': 'Nutzungsverhältnis',

  'admincat is an online platform that provides e-mail, document storage and data processing services. the contract for the use of admincat is established by an order from the customer in the form of an online registration, for which only an existing e-mail address is required.':
    'AdminCat ist eine Internetplattform, die E-Mail-, Dokumentenspeicher- und Datenverarbeitungsdienste anbietet. Der Vertrag über die Nutzung von AdminCat kommt durch einen Auftrag des Kunden in Form einer Online-Registrierung zustande, für die lediglich eine bestehende externe E-Mail-Adresse erforderlich ist.',

  'after the free trial period of three months, the user can decide to continue using admincat by paying a monthly or yearly subscription fee for the service. if the user forgoes the option to subscribe during the trial period, he or she will no longer have access to any spaces, but will be kept as an inactive space member as long as there are other paying members assigned to that space, and the user continues to be able to subscribe and return to actively use admincat at a later point in time. however, if such an inactive user isn’t assigned to any space anymore, he or she will get deleted automatically after a grace period of three months subsequent to the expiration of the last payment period. the user will receive alert notifications via e-mail before accordingly.':
    'Nach der kostenlosen Testphase von drei Monaten kann der Nutzer entscheiden, ob er AdminCat weiterhin nutzen möchte, indem er eine monatliche oder jährliche Abonnementgebühr für den Dienst bezahlt. Verzichtet der Nutzer während des Testzeitraums auf ein Abonnement, hat er keinen Zugriff mehr auf Spaces, sondern wird als inaktives Space-Mitglied geführt, solange diesem Space andere zahlende Mitglieder zugewiesen sind. Der Nutzer kann weiterhin ein Abonnement abschließen und AdminCat zu einem späteren Zeitpunkt wieder aktiv nutzen. Ist ein inaktiver Nutzer jedoch keinem Space mehr zugewiesen, wird er nach Ablauf einer Karenzzeit von drei Monaten nach Ablauf der letzten Zahlungsperiode automatisch gelöscht. Der Nutzer erhält vorher eine entsprechende Benachrichtigung per E-Mail.',

  services: 'Leistungen',

  'donut chart': 'Kuchen-Diagramm',

  'while (and even after) the registration, the customer may create a space, that includes access to a same name e-mailbox. every user is free to create up to three spaces at a time. the creator of a space automatically becomes its first administrator.':
    'Während (und auch nach) der Registrierung kann der Kunde einen Space anlegen, der Zugang zu einem gleichnamigen E-Mail-Postfach beinhaltet. Jeder Nutzer kann bis zu drei Spaces anlegen. Der Ersteller eines Spaces wird automatisch sein erster Administrator.',

  'alternatively, a user can join another already existing space during (and also after) registration, provided that the administrator of this space approves this new membership request.':
    'Alternativ kann ein Nutzer sich während (und auch nach) der Registrierung einem anderen bereits existierenden Space anschließen, vorausgesetzt der Administrator dieses Spaces genehmigt das.',

  'after the establishment of a space or the admission to another, the following services are available to the customer':
    'Nach der Einrichtung eines Spaces oder der Aufnahme in einen anderen stehen dem Kunden die folgenden Dienste zur Verfügung',

  'one or, if several users have access, a common space e-mail address in the format':
    'Eine eigene bzw. falls mehrere Nutzer Zugang haben eine gemeinsame Space-E-Mail-Adresse nach dem Format',
  'desiredname@admincat.net': 'Wunschname@admincat.net',

  'you can create a total of three spaces (including e-mail addresses) yourself':
    'Sie können insgesamt drei Spaces (einschließlich E-Mail-Adressen) selbst anlegen',
  'to join other spaces is limitless (of course only with the approval of the individual administrators)':
    'Sich Spaces anzuschließen (bei Genehmigung der Administratoren) ist unbegrenzt möglich',
  'each space provides 1 gb storage capacity for messages, data and documents':
    'Jeder Space bietet 1 GB Speicherkapazität für Nachrichten, Daten und Dokumente',
  'sending and receiving of e-mails with a total size of up to 20 mb':
    'Versenden und Empfangen von E-Mails mit einer Gesamtgröße von bis zu 20 MB',
  'upload of individual documents with a size of up to 10 mb and multiple documents at once with a':
    'Upload von einzelnen Dokumenten mit einer Größe von bis zu 10 MB und mehreren Dokumenten auf einmal mit',
  'size of together up to 50 mb': 'einer Größe von zusammen bis zu 50 MB',

  'spam filter: e-mails suspected of being spam are not accepted by our system (reject)':
    'Spamfilter: spamverdächtige E-Mails werden von unserem System nicht angenommen (reject)',
  'virus filter: incoming e-mails that contain a virus are deleted and not delivered to the customer':
    'Virenfilter: eingehende E-Mails, die einen Virus enthalten, werden gelöscht und dem Kunden nicht zugestellt',

  'if the storage space of a space is full, receiving messages or storing more documents is disabled. on the space dashboard, the users can check the allocation of storage space for each space. an extension of the storage on request is currently not available.':
    'Wenn der Speicherplatz eines Spaces voll belegt ist, können keine weiteren Nachrichten mehr empfangen oder Dokumente hochgeladen werden. Auf dem Space-Dashboard können die Nutzer die Belegung von Speicherplatz je Space überprüfen. Eine Erweiterung ist derzeit nicht möglich.',
  'admincat is usually available 24 hours a day. however, in cases of force majeure we cannot guarantee the uninterrupted accessibility and availability of all services. of course we will announce planned maintenance work related downtimes via e-mail and on our website in advance.':
    'AdminCat steht in der Regel 24 Stunden täglich zur Verfügung. AdminCat kann jedoch keine Gewähr für die ununterbrochene Erreichbarkeit und Verfügbarkeit sämtlicher Leistung übernehmen. Selbstverständlich kündigen wir geplante wartungsbedingte Ausfallzeiten per E-Mail und auf unserer Website im Voraus an.',

  'usage fee': 'Nutzungsentgelt',
  'the use of admincat is available free of charge within the three months trial period without any special service restrictions. after that period a monthly or yearly paid subscription interval may be chosen on the user settings page (go to the subscription page to see the current subscription fees).':
    'Die Nutzung von AdminCat ist innerhalb der dreimonatigen Testphase kostenlos und ohne Serviceeinschränkungen möglich. Danach kann in den Benutzereinstellungen ein monatliches oder jährliches kostenpflichtiges Abonnement gewählt werden (auf dieser Seite können Sie die aktuellen Angebote einsehen).',

  'obligations of the customer': 'Pflichten des Kunden',

  'the customer will keep the access password to his account secret and change it immediately or have it changed by admincat if unauthorized third parties could have gained knowledge of it.':
    'Der Kunde wird das Zugangspasswort zu seinem Account geheim halten und es unverzüglich ändern oder von AdminCat ändern lassen, wenn unbefugte Dritte davon Kenntnis erlangt haben könnten.',

  'the customer is also liable for third parties who use or have used services via his account, either authorized or unauthorized. this does not apply if the customer is not responsible for unauthorized use. the customer must provide evidence that he is not responsible for such use.':
    'Der Kunde haftet auch für Dritte, die befugt oder unbefugt Leistungen über seinen Account nutzen oder genutzt haben. Dies gilt nicht, wenn der Kunde eine unbefugte Nutzung nicht zu vertreten hat. Der Kunde hat den Nachweis zu erbringen, dass er eine solche Nutzung nicht zu vertreten hat.',

  'the customer will not send any advertising by e-mail for commercial purposes via the mail service and will not use the mail service to send standardized e-mails to a large number of recipients.':
    'Der Kunde wird über den Mail Service keine Werbung per E-Mail für kommerzielle Zwecke versenden und den Mail Service nicht zum Versand standardisierter E-Mails an eine Vielzahl von Empfängern nutzen.',

  'abuse and protection of minors': 'Missbrauch und Jugendschutz',
  'the customer undertakes not to misuse admincat':
    'Der Kunde verpflichtet sich, AdminCat nicht missbräuchlich zu nutzen',

  'not to send spam (no more than 100 e-mails per hour and no more than 300 e-mails':
    'keinen Spam zu verschicken (nicht mehr als 100 E-Mails pro Stunde und nicht mehr als 300 E-Mails',
  'per day)': 'pro Tag versenden)',

  'not to violate any laws (e.g. sending forbidden or harassing messages)':
    'nicht gegen Gesetze zu verstossen (z.B. Versenden verbotener oder belästigender Nachrichten)',
  'not to violate regulations for the protection of young people':
    'nicht gegen Vorschriften zum Schutze der Jugend zu verstoßen',

  'not to use any mechanisms, software or scripts in connection with the use of the services that could':
    'keine Mechanismen, Software oder Scripts im Zusammenhang mit der lnanspruchnahme der Dienste verwenden,',
  'impair or disrupt the functioning of admincat':
    'die das Funktionieren von AdminCat beeinträchtigen oder stören können',

  'the customer is liable to admincat for damage caused by violating his obligations under 6.1., and releases admincat from any third party claims in this regard. this does not apply, if he is not responsible for the violation. the customer must provide evidence that he is not responsible for the violation.':
    'Der Kunde haftet AdminCat gegenüber für Schäden, die durch Verstöße gegen seine Pflichten aus 6.1. entstehen und stellt AdminCat von diesbezüglichen Ansprüchen Dritter frei. Dies gilt nicht, wenn er den Verstoß nicht zu vertreten hat. Der Kunde hat den Nachweis zu erbringen, dass er den Verstoß nicht zu vertreten hat.',
  'if the customer uses admincat contrary to the provisions of 6.1 and 5.3, the user account can get blocked by admincat without prior notice. the right to terminate the contract without notice remains unaffected.':
    'Nutzt der Kunde AdminCat entgegen den Bestimmungen von 6.1 und 5.3, kann das Nutzerkonto ohne vorherige Ankündigung durch AdminCat gesperrt werden. Das Recht zur fristlosen Kündigung des Vertrages bleibt hiervon unberührt.',

  privacy: 'Datenschutz',
  'admincat and the customer will comply with the statutory data protection requirements, in particular according to the telemedia act (tmg) and the federal data protection act (bdsg). inventory and usage data of the customer are only collected, processed or used insofar as this is necessary for the execution of the contractual relationship.':
    'AdminCat und der Kunde werden die gesetzlichen Datenschutzbestimmungen, insbesondere nach dem Telemediengesetz (TMG) und dem Bundesdatenschutzgesetz (BDSG), einhalten. Bestands- und Nutzungsdaten des Kunden werden nur erhoben, verarbeitet oder genutzt, soweit dies für die Durchführung des Vertragsverhältnisses erforderlich ist.',

  'in order to ensure the service integrity and compliance with the terms of use of admincat, in cases of reasonable suspicion of misuse admincat is entitled to determine polluters and restrict the usage of services for these customers.':
    'Um die lntegrität der Dienste und die Einhaltung der Nutzungsbedingungen von AdminCat sicherstellen zu können, ist AdminCat berechtigt, bei begründetem Verdacht auf missbräuchliche Nutzung, den Verursacher festzustellen und die in Anspruch genommene Leistung im Einzelfall für diesen einzuschränken.',

  'further information on the type, scope and purpose of the collection, processing and use of personal and other data can be found in the privacy policy of admincat.':
    'Weitere Informationen über Art, Umfang und Zwecke der Erhebung, Verarbeitung und Nutzung personenbezogener und anderer Daten finden Sie in der Datenschutzerklärung zu AdminCat.',

  'warranty and liability': 'Gewährleistung und Haftung',

  'admincat encrypts and protects the managed data and documents on the live server as far as this is possible with the current state of the art. with regular backups, we also prevent major data loss from occurring. development guidelines and internal processes make sure, that new features are thoroughly tested in a test environment and the users data isn’t get compromised by any updates. for the unlikely event of data loss however, we recommend users to keep digital copies on their local systems as a precaution.':
    'AdminCat verschlüsselt und schützt die verwalteten Daten und Dokumente auf dem Live-Server, soweit dies nach dem aktuellen Stand der Technik möglich ist. Mit regelmäßigen Backups beugen wir zudem größeren Datenverlusten vor. Entwicklungsrichtlinien und interne Prozesse stellen sicher, dass neue Features in einer Testumgebung ausgiebig getestet werden und die Daten der Nutzer durch Updates nicht gefährdet werden. Für den unwahrscheinlichen Fall eines Datenverlustes empfehlen wir den Nutzern jedoch, vorsorglich digitale Kopien auf ihren lokalen Systemen zu erstellen.',
  'admincat does not guarantee that all of the services mentioned under item 3 are available and free of errors at all times. this applies in particular if access to these services is caused by disruptions that are beyond the control of admincat. we will immediately eliminate disruptions to the services mentioned under item 3 within the scope of the existing technical and operational possibilities.':
    'AdminCat übernimmt keine Gewähr dafür, dass alle unter Punkt 3 genannten Dienste jederzeit verfügbar und fehlerfrei sind. Dies gilt insbesondere dann, wenn der Zugang zu diesen Diensten durch Störungen verursacht wird, die nicht im Einflussbereich von AdminCat liegen. Wir werden Störungen der unter Ziffer 3 genannten Dienste im Rahmen der bestehenden technischen und betrieblichen Möglichkeiten unverzüglich beseitigen.',
  'any liability claim against us related to a possible data loss suffered or incorrect and/or harmful data processing needs to be documented or reasonably reproducible. our support team is your first level contact, ready to analyze incidents, give advice or support with quick bug-fixing in case we identify design and processing errors in our responsibility.':
    'Haftungsansprüche gegen uns, die sich auf einen eventuell erlittenen Datenverlust oder eine fehlerhafte und/oder schädliche Datenverarbeitung beziehen, müssen dokumentiert oder in zumutbarer Weise reproduzierbar sein. Unser Support-Team steht Ihnen als erster Ansprechpartner zur Verfügung, um Vorfälle zu analysieren, Ratschläge zu erteilen oder Sie bei der schnellen Fehlerbehebung zu unterstützen, falls wir Konstruktions- und Verarbeitungsfehler in unserem Verantwortungsbereich feststellen.',

  'termination, deletion of the account': 'Kündigung, Löschung des Accounts',
  'the usage relationship can run for an indefinite period in principle, as long as the user subscribed and paid for the service in advance. it can actively get terminated by the customer at any time – not via email, but by deleting the user account directly under the user settings, in which case we unfortunately can’t provide a reimbursement for the remaining prepaid subscription. the right to terminate the contract without notice in accordance with section 1.2 or for an important reason (sections 5 and 6) remains unaffected.':
    'Das Nutzungsverhältnis kann grundsätzlich auf unbestimmte Zeit laufen, sofern der Nutzer den Dienst im Voraus abonniert und bezahlt hat. Es kann vom Kunden jederzeit aktiv gekündigt werden - nicht per E-Mail, sondern durch Löschen des Benutzerkontos direkt in den Benutzereinstellungen, wobei wir in diesem Fall leider keine Rückerstattung für das verbleibende vorausbezahlte Abonnement leisten können. Das Recht zur fristlosen Kündigung nach Ziffer 1.2 oder aus wichtigem Grund (Ziffer 5 und 6) bleibt unberührt.',
  'a deletion removes all sensitive details of a user, such as email, password, its space memberships and payments. however, for technical reasons, user id and the freely defined user name will remain in the system. inactive user accounts, whose subscription ended more than three months ago and which are also no longer members of a space anymore will get deleted automatically by admincat.':
    'Bei einer Löschung werden alle sensiblen Daten eines Nutzers, wie E-Mail, Passwort, seine Space-Mitgliedschaften und Zahlungen, entfernt. Aus technischen Gründen bleiben jedoch die Benutzerkennung und der frei definierte Benutzername im System erhalten. Inaktive Benutzerkonten, deren Abonnement vor mehr als drei Monaten endete und die auch nicht mehr Mitglied in einem Space sind, werden automatisch von AdminCat gelöscht.',
  'if the subscription of a space admin expires, the admin role gets reassigned to any of the remaining active/paying users (if any) automatically, such that the space continues to have an admin. if space administrating users decide to actively delete themselves and the admin role was not consciously transferred to another user before, the admin role gets reassigned to any of the remaining active/paying (or in secondary priority inactive/non-paying) users automatically. if there is none or the payment periods of all relevant inactive users ended more than 3 months ago, the space will get deleted automatically as well.':
    'Wenn das Abonnement eines Space-Admins ausläuft, wird die Admin-Rolle automatisch an einen der verbleibenden aktiven/zahlenden Nutzer (falls vorhanden) neu zugewiesen, so dass der Space weiterhin einen Admin hat. Wenn ein Space-Administrator sich selbst aktiv löscht und die Admin-Rolle vorher nicht bewusst auf einen anderen Benutzer übertragen wurde, wird die Admin-Rolle automatisch an einen der verbleibenden aktiven/zahlenden (oder in zweiter Priorität inaktiven/nicht-zahlenden) Benutzer neu zugewiesen. Wenn es keine gibt oder die bezahlte Nutzung aller relevanten inaktiven Nutzer vor mehr als 3 Monaten endete, wird der Space ebenfalls automatisch gelöscht.',

  'governing law': 'Geltendes Recht',
  'the law of the federal republic of germany applies exclusively to all legal relationships between admincat and the customer. the law on the united nations convention on the international sale of goods ("cisg") does not apply.':
    'Für sämtliche Rechtsbeziehungen zwischen AdminCat und dem Kunden gilt ausschließlich das Recht der Bundesrepublik Deutschland. Das Recht des Übereinkommens der Vereinten Nationen über Verträge über den internationalen Warenkauf ("CISG") findet keine Anwendung.',

  'status: june 2024': 'Stand: Juni 2024',

  'heads-up period (in weeks) for documents and contracts':
    'Vorwarnperiode (in Wochen) für Dokumente und Verträge',
  'request date': 'Antrag vom',
  'decision date': 'Entscheidung vom',
  approve: 'Genehmigen',
  disapprove: 'Missbilligen',
  decline: 'Ablehnen',
  issuer: 'Aussteller',
  expense: 'Ausgaben',
  'space group': 'Space-Gruppe',
  assets: 'Vermögen',
  liabilities: 'Verbindlichkeiten',
  'assets vs. liabilities': 'Vermögen vs. Verbindlichkeiten',
  'assets vs liabilities': 'Vermögen vs Verbindlichkeiten',
  'asset vs liability': 'Vermögen vs Verbindlk',
  'liability.': 'Verbindlk',
  loss: 'Verlust',
  profit: 'Gewinn',
  'income vs. expenses': 'Einnahmen vs. Kosten',
  notifications: 'Mitteilungen',
  'expenses pie chart': 'Kosten-Diagramm',
  emails: 'Emails',
  documents: 'Dokumente',
  'storage space': 'Speicherplatz',
  'top 5 contracts': 'Top 5 Verträge',
  unread: 'ungelesen',
  read: 'gelesen',
  loss: 'Verlust',
  profit: 'Gewinn',
  'offset account': 'Gegenkonto',
  'actual value': 'Ist-Betrag',
  'actual value sc': 'Ist-Betrag (SW)',
  'rec op': 'Org Indikator',
  'transfer date': 'Angelegt am',
  'org country': 'Land',
  'finance account': 'Finanz-Konto',
  'item reference id': 'Sachbezug-Link',
  'financial category': 'Kategorie Finanzen',
  'document link': 'Dokument Link',
  'choose resident': 'Bewohner wählen',
  'choose address': 'Adresse wählen',
  'address': 'Adresse',

  'asset vs liability': 'Vermögen vs Verbindlk',
  'liability.': 'Verbindlk',

  'income & expenses line chart': 'Einnahmen & Kosten-Diagramm',
  'work experience': 'Berufserfahrung',
  'choose organisation': 'Organisation auswählen',
  'choose profession': 'Beruf auswählen',
  'title description': 'Titelbezeichnung',
  'new entry': 'Neuen Eintrag',
  attachments: 'Anhänge',
  print: 'abdrucken',
  'failed to change workspace': 'Aktualisierung des Arbeitsbereichs fehlgeschlagen',
  'space updated successfully': 'Space erfolgreich aktualisiert',

  of: 'VON',
  'in use': 'IN GEBRAUCH',
  'no contracts yet!': 'Noch keine Verträge!',
  maximum: 'Maximal können',
  'members allowed': 'Personen hinzugefügt werden',
  'a member at least must be provided':
    'Mindestens ein Mitglied muss gestellt werden',

  'files have been appended.': 'Es wurden Dateien angehängt.',
  'attachments size must be less than 8 mb.':
    'Die Größe der Anhänge muss weniger als 8 MB betragen.',
  'attach documents': 'Dokumente beifügen',

  'the contract': 'Der Vertrag',
  'can get cancelled until': 'kann bis',
  'if you pass this date, the contract will get extended automatically':
    'gekündigt werden. Wenn Sie dieses Datum überschreiten, wird der Vertrag automatisch verlänger',
  'space currency amount': 'Betrag in Space Währung',
  'space amount': 'Betrag in Space',
  transaction: 'Transaktion',

  'contracts (cost per month)': 'Verträge (Kosten pro Monat)',
  'income vs expenditure': 'Einnahmen vs. Ausgaben',

  'storage exceeded 1 gb. please clean up and remove files.':
    'Speicher von 1GB überschritten. Bitte entfernen Sie Dateien.',
  'storage exceeded 100 mb. please clean up and remove files.':
    'Speicher von 100 MB überschritten. Bitte entfernen Sie Dateien.',

  // "Space Group": "Space-Gruppe",
  // "Assets":"Vermögen",
  // "Liabilities":"Verbindlichkeiten",
  // "Assets vs. Liabilities": "Vermögen vs. Verbindlichkeiten",
  // "Income vs. Expenses": "Einnahmen vs. Kosten",
  // "Notifications": "Mitteilungen",
  // "Expenses Pie Chart": "Kosten-Diagramm",
  // "Emails": "Emails",
  // "Documents": "Dokumente",
  // "Storage Space": "Speicherplatz",
  // "Top 5 Contracts":"Top 5 Verträge",
  // "Income & Expenses Line Chart": "Einnahmen & Kosten-Diagramm"
};

// This is only meant for the Login , Register and Footer Page Translations;
const de = {
  translations: {
    Work: 'Arbeit',
    'Password Reset': 'Passwort zurücksetzen',
    'I agree with the Terms and Conditions': 'Ich stimme den Nutzungsbedingungen zu',
    'I agree with the': 'Ich stimme den',
    'Terms and Conditions.': 'Nutzungsbedingungen zu.',
    Username: 'Nutzername',
    'Personal User': 'Persönlicher Benutzer',
    'Already have an account?': 'Haben Sie bereits ein Konto?',
    'Organization Information': 'Organisations informationen',
    Register: 'Registrieren',
    Login: 'Login',
    Password: 'Passwort',
    'Forgot Password': 'Passwort vergessen',
    'All rights reserved': 'Alle Rechte vorbehalten',
    'Privacy Policy': 'Datenschutzerklärung ',
    'Sign in': 'Einloggen',
    'join personal workspace': 'Trete Privatem Space bei',
    'create personal workspace': 'Erstelle Privaten Space',
    'Organisation User': 'Organisations-Nutzer',
    'Sign up': 'Anmelden',
    link: '/deu/privacy-statement ',
    'Digitalize your documents and easily manage them online.':
      'Digitalisieren Sie Ihre Dokumente und verwalten Sie diese ganz einfach online.',
    'Wanna keep an overview on your finances? We got it!':
      'Sie wollen einen Überblick über Ihre Finanzen? Wir bieten ihn!',
    'Don‘t worry about deadlines - we‘ll remind you!':
      'Kümmern Sie sich nicht um Fristen - wir erinnern Sie!',

    'Centralize your data and documents in one safe place':
      'Zentralisieren Sie Ihre Daten und Dokumente an einem sicheren Ort.',
    'Receive emails and automatically save attachments in the relevant folders.':
      'Empfangen Sie E-Mails und speichern Sie die Anlagen automatisch im richtigen Ordner.',
    'All data of your family integrated - voilá!':
      'Alle Daten für Ihre Familie vereint - voilá! ',

    'An Innovative Document Management Tool':
      'Ein innovatives Dokumentenmanagement-Tool',
    'Register Now': 'Jetzt registrieren',
    'Reset My Password': 'Setze mein Passwort zurück',
    'Have an account?': 'Have an account?',
    'Reset My Password': 'Setze mein Passwort zurück',
    'Enter Registered Email': 'Registrierte E-Mail eingeben',
    'Centralize your data and documents in one safe place':
      'Zentralisieren Sie Ihre Daten und Dokumente an einem sicheren Ort.',
    'Receive emails and automatically save attachments in the relevant folders.':
      'Empfangen Sie E-Mails und speichern Sie die Anlagen automatisch im richtigen Ordner.',
    'All data of your family integrated - voilá!':
      'Alle Daten für Ihre Familie vereint - voilá! ',
    'Simplify bureaucracy!': 'Bürokratie vereinfacht!',
    'All you need in one App!': 'Alles Wichtige in einer App!',
    'An Innovative Document Management Tool':
      'Ein innovatives Dokumentenmanagement-Tool',
    'Register Now': 'Jetzt registrieren',
    Copyright: 'Urheberrecht',
    'P.O. Box': 'Postfach',

    'User Terms': 'AGB',
    'Welcome to your digital workspace!': 'Willkommen in Deiner digitalen Zentrale!',
    'Log in': ' Login',
    'Get started': 'Jetzt starten',

    'Simplify bureaucracy!': 'Vereinfacht Verwaltung!',
    'We provide features that make your private administration easy and fun.':
      'Wir bieten Funktionen, mit denen die private Verwaltung Spaß macht.',
    'The control station for your life!': 'Das Cockpit für Dein Leben!',
    'Our dashboard enables you to take control like a captain.':
      'Unser Dashboard ermöglicht es Dir, wie ein Kapitän das Steuer zu übernehmen.',
    'All you need in one App!': ' Alles in einer App!',
    'No more headache with incompatible apps. We provide a seamless all-in-one-solution.':
      'Keine Kopfschmerzen mehr mit Teillösungen. Wir bieten eine nahtlose All-in-one-App.',
    'Here are some of our wonderful features':
      'Hier einige unserer wundervollen Funktionen',
    'Simply find everything': 'Finde einfach alles wieder',
    'Digitalize your documents and easily manage them online.':
      'Digitalisiere Deine Dokumente und verwalte sie einfach online.',
    'Stay up to date': 'Bleib auf dem Laufenden',
    'Don‘t worry about deadlines - we‘ll remind you!':
      'Keine Sorge vor Fristen – wir erinnern Dich!',
    'Financial control': 'Die Finanzen im Griff',
    'Wanna keep an overview on your finances? We got it!':
      'Willst Du Deine Finanzen managen? Bei uns geht’s!',
    'Safety first': 'Sicherheit zuerst',
    'Centralize your data and documents in one safe place.':
      'Sammle Deine Daten und Dokumente an einem sicheren Ort.',
    'Smart Email': 'Smarte Email',
    'Receive emails and automatically save attachments in the relevant folders.':
      'Erhalte Emails und speichere Anhänge automatisch in den richtigen Ordnern.',
    'Family-friendly': 'Familien-freundlich',
    'All data of your family integrated - voilá!':
      'Alle Daten Deiner Familie vereint - voilá!',
    'Customers Trust Us': 'Kunden vertrauen uns',
    'Here are some of our reviews from our users':
      'Hier einige Kommentare unserer Nutzer',
    'My paperwork was always a challenge. Now I come here and find my documents in a few seconds. You are sooo smart.':
      'Mein Papierkram war immer eine Herausforderung. Jetzt komme ich hierher und finde meine Dokumente in wenigen Sekunden. Ihr seid echt gut.',
    'AdminCat is a great help to organize contracts and personal finances in general. And I like the dashboard a lot.':
      'AdminCat ist eine große Hilfe, um Verträge und die privaten Finanzen zu verwalten. Und am besten gefällt mir das Dashboard.',

    'We are here for you!': 'Wir sind für Dich da!',
    'Check out AdminCat today! It’s free.':
      'Teste AdminCat noch heute! Es ist kostenlos.',
    'Press the button below in order to start with your first own AdminCat workspace, You’re gonna love it!':
      'Klicke auf diese Schaltfläche, um mit Deinem ersten eigenen AdminCat Workspace zu starten. Du wirst es lieben!',
    'Get started': 'Jetzt starten',

    'What is AdminCat?': 'Was ist AdminCat?',
    'AdminCat is a internet service for private households for managing their personal data and documents.':
      'AdminCat ist ein Internet-Service für private Haushalte, um ihre persönlichen Daten und Dokumente zu verwalten.',
    'How does it help its users?': 'Wie unterstützt es seine Nutzer?',
    'Households receive letters, emails and documents in multiple external postboxes. AdminCat provides a central place for these documents and makes it easy to find everything in just seconds. But it also allows managing contracts, finances, health and many other matters in the private area.':
      'Haushalte erhalten Briefe, Emails und Dokumente verstreut auf verschiedenen Webseiten. AdminCat bietet einen zentralen Platz für diese Dokumente und macht es ganz leicht, alles in Sekunden wiederzufinden. Es ermöglicht aber auch, Verträge, Finanzen, Gesundheitsdaten und viele weitere perönliche Belange zu organisieren.',
    'Does using AdminCat cost anything?': 'Kostet die Nutzung von AdminCat etwas?',
    'No. All of the AdminCat services and features are offered for all our users for free. However, in order to keep it free of charge, we may introduce certain limits, e.g. in the total storage size per workspace. Heavy users may then decide to book additional capacities and services.':
      'Nein. Grundsätzlich werden alle Dienste und Funktionen für unsere Nutzer kostenlos angeboten. Allerdings behalten wir uns vor, bestimmte - für den normalen Bedarf absolut ausreichende – Freigrenzen einzuführen, wie z.B. für verfügbaren Speicherplatz pro Workspace. Nutzer mit erhöhtem Bedarf können dann entsprechende Dienste hinzubuchen.',
    'How is AdminCat financed?': 'Wie finanziert sich AdminCat?',
    'AdminCat plans to earn money mainly by running advertising for our business partners. Additionally we will offer some extra paid services to our users, e.g. if users prefer to use the add-free version.':
      'AdminCat soll sich hauptsächlich aus Werbeanzeigen für unsere Businesspartner finanzieren. Zusätzlich bieten wir unseren Nutzern einige kostenpflichtige Dienste an, wie z.B. die werbefreie Version.',
    'Is my data safe on AdminCat?': 'Sind meine Daten auf AdminCat sicher?',
    'Our site is using state of the art technology to encrypt and protect your data from unauthorized access. This includes the server security as well as the data transmission via the secure HTTPS protocol. As our servers are exclusively located in Germany, we are subject to the high GDPR standards in the EU.':
      'Unsere Seite nutzt die aktuellsten Technologien, um Deine Daten zu verschlüsseln und vor unberechtigtem Zugriff zu schützen. Das betrifft die Server-seitige Speicherung als auch die sichere Datenübertragung per HTTPS-Protokoll. Da unsere Server exklusiv in Deutschland stehen, unterliegen wir den strengen Datenschutz-Gesetzen (hier der DSGVO) in der EU.',

    'My paperwork was always a challenge. Now I come here and find my documents in a few seconds. You are sooo smart!':
      'Mein Papierkram war immer eine Herausforderung. Jetzt komme ich hier her und finde meine Dokumente in Sekunden. Ihr seid sooo clever!',
    'AdminCat is a great help to organize contracts and personal finances in general. And I like the dashboard a lot.':
      'AdminCat ist eine große Hilfe, um Verträge und die privaten Finanzen zu organisieren. Und daher mag ich auch das Dashboard sehr.',
    Disclaimer: 'Haftungsausschluss',
    'General Contact': 'Allgemeiner Kontakt',
    'Represented by': 'Vertreten durch',
    Credits: 'Impressum',
    Germany: 'Deutschland',
    'Head office': 'Firmensitz',
    'no support': 'kein Support',
    'Please mail to': 'Post bitte an',

    'Authorized managing director': 'Vertretungsberechtigter Geschäftsführer',
    'Register court': 'Registergericht',
    'Register number': 'Registernummer',
    'VAT ID': 'USt-IdNr.',

    Contact: 'Kontakt',
    'Liability for Content': 'Haftung für Inhalte',
    'Local court ': 'Amtsgericht ',

    'The content provided has been created with greatest care. However we cannot guarantee for the accuracy, completeness, reliability, usability and timeliness of the content. As a service provider according to § 7 (1) of the German Telemedia Act (TMG), we are responsible for own content on these sites under the general laws. However, corresponding to §§ 8 to 10 TMG, it is not our duty, to control or check information transmitted or saved from third parties, whether or not they are in line with the law.':
      'Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir nach § 7 Abs. 1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Allerdings sind wir gemäß §§ 8 bis 10 TMG nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. ',

    'Obligations to remove or block the use of information under the general laws remain unaffected. However, liability is only possible from the date of knowledge of a specific infringement. Upon notification of such violations, we will remove the content immediately.':
      'Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.',

    Copyrights: 'Urheberrecht',

    'The contents and works on these pages compiled by us are subject to copyright law. Copying, processing, distribution and any kind of use outside the limits of copyright law require the written consent of us.':
      'Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.',

    'In case the content is not created by us, the copyrights of third parties are being observed. In particular contents of third parties are marked as such. However, if a user becomes aware of a copyright infringement, we ask the user for notification. Upon notification of such violations, we will remove the content immediately.':
      'Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.',

    'Data Protection': 'Datenschutz',
    'The collection of person-related data on our website is based, as far as possible, on voluntariness. These data will not be transmitted to third parties without your explicit approval. Please be aware, that the data transmission over the internet (e.g. communication via email) may bear security risks. Therefore a complete protection of data from access of third parties is not possible. We hereby expressly object to the use of contact data published within the framework of the imprint obligation by third parties for sending unsolicited advertising and information material. The site operators reserve the right to take legal action in the event that unsolicited advertising information is sent, such as spam e-mails.':
      'Die Erhebung personenbezogener Daten auf unseren Seiten erfolgt, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.',

    'Terms and Conditions': 'Allgemeine Geschäftsbedingungen',
    'Scope and changes to these terms of use':
      'Geltungsbereich und Änderungen dieser Nutzungsbedingungen',

    'These terms of use regulate the service utilization based relationship between the customer and AdminCat GmbH (hereinafter "AdminCat" or "we"), Landgraf-Karl-Str. 21A, 34131 Kassel, Germany (E-mail:':
      'Die vorliegenden Nutzungsbedingungen regeln das zwischen dem Kunden und AdminCat, Landgraf-Karl-Str. 21A, 34131 Kassel, German (E-Mail: support@admincat.net) begründete Nutzungsverhältnis des E-Mail-, Daten- und Dokumenten-Services AdminCat.',
    'admin@admincat.net': 'admin@admincat.net',
    'as a provider of E-mail, data and document storage services.':
      'als Anbieter von E-Mail-, Daten- und Dokumentenspeicherdiensten.',

    'AdminCat reserves the right to change these terms of use as well as the service description and prices. AdminCat shall inform the customer of any changes. If changes are made to the detriment of the customer, he may terminate the user relationship without notice, close his account and have the remaining prepaid amount refunded. AdminCat informs the customer of this right of termination in the notification of change.':
      'AdminCat behält sich vor, diese Nutzungsbedingungen sowie die Leistungsbeschreibung und Preise zu ändern. Änderungen teilt AdminCat dem Kunden mit. Erfolgen Änderungen zu Ungunsten des Kunden, kann er das Nutzungsverhältnis fristlos kündigen. Auf dieses Kündigungsrecht weist AdminCat den Kunden in der Änderungsmitteilung hin.',

    'Usage relationship': 'Nutzungsverhältnis',

    'AdminCat is an online platform that provides E-mail, document storage and data processing services. The contract for the use of AdminCat is established by an order from the customer in the form of an online registration, for which only an existing e-mail address is required.':
      'AdminCat ist eine Internetplattform, die E-Mail-, Dokumentenspeicher- und Datenverarbeitungsdienste anbietet. Der Vertrag über die Nutzung von AdminCat kommt durch einen Auftrag des Kunden in Form einer Online-Registrierung zustande, für die lediglich eine bestehende externe E-Mail-Adresse erforderlich ist.',

    'After the free trial period of three months, the user can decide to continue using AdminCat by paying a monthly or yearly subscription fee for the service. If the user forgoes the option to subscribe during the trial period, he or she will no longer have access to any Spaces, but will be kept as an inactive Space member as long as there are other paying members assigned to that Space, and the user continues to be able to subscribe and return to actively use AdminCat at a later point in time. However, if such an inactive user isn’t assigned to any Space anymore, he or she will get deleted automatically after a grace period of three months subsequent to the expiration of the last payment period. The user will receive alert notifications via e-mail before accordingly.':
      'Nach der kostenlosen Testphase von drei Monaten kann der Nutzer entscheiden, ob er AdminCat weiterhin nutzen möchte, indem er eine monatliche oder jährliche Abonnementgebühr für den Dienst bezahlt. Verzichtet der Nutzer während des Testzeitraums auf ein Abonnement, hat er keinen Zugriff mehr auf Spaces, sondern wird als inaktives Space-Mitglied geführt, solange diesem Space andere zahlende Mitglieder zugewiesen sind. Der Nutzer kann weiterhin ein Abonnement abschließen und AdminCat zu einem späteren Zeitpunkt wieder aktiv nutzen. Ist ein inaktiver Nutzer jedoch keinem Space mehr zugewiesen, wird er nach Ablauf einer Karenzzeit von drei Monaten nach Ablauf der letzten Zahlungsperiode automatisch gelöscht. Der Nutzer erhält vorher eine entsprechende Benachrichtigung per E-Mail.',

    Services: 'Leistungen',

    'While (and even after) the registration, the customer may create a Space, that includes access to a same name e-mailbox. Every user is free to create up to three Spaces at a time. The creator of a Space automatically becomes its first administrator.':
      'Während (und auch nach) der Registrierung kann der Kunde einen Space anlegen, der Zugang zu einem gleichnamigen E-Mail-Postfach beinhaltet. Jeder Nutzer kann bis zu drei Spaces anlegen. Der Ersteller eines Spaces wird automatisch sein erster Administrator.',

    'Alternatively, a user can join another already existing Space during (and also after) registration, provided that the administrator of this Space approves this new membership request.':
      'Alternativ kann ein Nutzer sich während (und auch nach) der Registrierung einem anderen bereits existierenden Space anschließen, vorausgesetzt der Administrator dieses Spaces genehmigt das.',

    'After the establishment of a Space or the admission to another, the following services are available to the customer':
      'Nach der Einrichtung eines Spaces oder der Aufnahme in einen anderen stehen dem Kunden die folgenden Dienste zur Verfügung',

    'One or, if several users have access, a common space e-mail address in the format':
      'Eine eigene bzw. falls mehrere Nutzer Zugang haben eine gemeinsame Space-E-Mail-Adresse nach dem Format',
    'desiredname@admincat.net': 'Wunschname@admincat.net',

    'You can create a total of three spaces (including e-mail addresses) yourself':
      'Sie können insgesamt drei Spaces (einschließlich E-Mail-Adressen) selbst anlegen',
    'To join other spaces is limitless (of course only with the approval of the individual administrators)':
      'Sich Spaces anzuschließen (bei Genehmigung der Administratoren) ist unbegrenzt möglich',
    'Each space provides 1 GB storage capacity for messages, data and documents':
      'Jeder Space bietet 1 GB Speicherkapazität für Nachrichten, Daten und Dokumente',
    'Sending and receiving of e-mails with a total size of up to 20 MB':
      'Versenden und Empfangen von E-Mails mit einer Gesamtgröße von bis zu 20 MB',
    'Upload of individual documents with a size of up to 10 MB and multiple documents at once with':
      'Upload von einzelnen Dokumenten mit einer Größe von bis zu 10 MB und mehreren Dokumenten auf einmal mit',
    'a size of together up to 50 MB': 'einer Größe von zusammen bis zu 50 MB',

    'Spam filter: e-mails suspected of being spam are not accepted by our system (reject)':
      'Spamfilter: spamverdächtige E-Mails werden von unserem System nicht angenommen (reject)',
    'Virus filter: Incoming e-mails that contain a virus are deleted and not delivered to the customer':
      'Virenfilter: eingehende E-Mails, die einen Virus enthalten, werden gelöscht und dem Kunden nicht zugestellt',

    'If the storage space of a Space is full, receiving messages or storing more documents is disabled. On the Space dashboard, the users can check the allocation of storage space for each Space. An extension of the storage on request is currently not available.':
      'Wenn der Speicherplatz eines Spaces voll belegt ist, können keine weiteren Nachrichten mehr empfangen oder Dokumente hochgeladen werden. Auf dem Space-Dashboard können die Nutzer die Belegung von Speicherplatz je Space überprüfen. Eine Erweiterung ist derzeit nicht möglich.',
    'AdminCat is usually available 24 hours a day. However, in cases of force majeure we cannot guarantee the uninterrupted accessibility and availability of all services. Of course we will announce planned maintenance work related downtimes via e-mail and on our website in advance.':
      'AdminCat steht in der Regel 24 Stunden täglich zur Verfügung. AdminCat kann jedoch keine Gewähr für die ununterbrochene Erreichbarkeit und Verfügbarkeit sämtlicher Leistung übernehmen. Selbstverständlich kündigen wir geplante wartungsbedingte Ausfallzeiten per E-Mail und auf unserer Website im Voraus an.',

    'Alternatively, a user can join another already existing Space during (and also after) registration, provided that the administrator of this Space approves this new membership request.':
      'Alternativ kann ein Nutzer sich während (und auch nach) der Registrierung einem anderen bereits existierenden Space anschließen, vorausgesetzt der Administrator dieses Spaces genehmigt das.',

    'After the establishment of a Space or the admission to another, the following services are available to the customer':
      'Nach der Einrichtung eines Spaces oder der Aufnahme in einen anderen stehen dem Kunden die folgenden Dienste zur Verfügung',

    'One or, if several users have access, a common space e-mail address in the format':
      'Eine eigene bzw. falls mehrere Nutzer Zugang haben eine gemeinsame Space-E-Mail-Adresse nach dem Format',
    'desiredname@admincat.net': 'Wunschname@admincat.net',

    'You can create a total of three spaces (including e-mail addresses) yourself':
      'Sie können insgesamt drei Spaces (einschließlich E-Mail-Adressen) selbst anlegen',
    'To join other spaces is limitless (of course only with the approval of the individual administrators)':
      'Sich Spaces anzuschließen (bei Genehmigung der Administratoren) ist unbegrenzt möglich',
    'Each space provides 1 GB storage capacity for messages, data and documents':
      'Jeder Space bietet 1 GB Speicherkapazität für Nachrichten, Daten und Dokumente',
    'Sending and receiving of e-mails with a total size of up to 20 MB':
      'Versenden und Empfangen von E-Mails mit einer Gesamtgröße von bis zu 20 MB',
    'Upload of individual documents with a size of up to 10 MB and multiple documents at once with a':
      'Upload von einzelnen Dokumenten mit einer Größe von bis zu 10 MB und mehreren Dokumenten auf einmal mit',
    'a size of together up to 50 MB': 'einer Größe von zusammen bis zu 50 MB',

    'Spam filter: e-mails suspected of being spam are not accepted by our system (reject)':
      'Spamfilter: spamverdächtige E-Mails werden von unserem System nicht angenommen (reject)',
    'Virus filter: Incoming e-mails that contain a virus are deleted and not delivered to the customer':
      'Virenfilter: eingehende E-Mails, die einen Virus enthalten, werden gelöscht und dem Kunden nicht zugestellt',

    'If the storage space of a Space is full, receiving messages or storing more documents is disabled. On the Space dashboard, the users can check the allocation of storage space for each Space. An extension of the storage on request is currently not available.':
      'Wenn der Speicherplatz eines Spaces voll belegt ist, können keine weiteren Nachrichten mehr empfangen oder Dokumente hochgeladen werden. Auf dem Space-Dashboard können die Nutzer die Belegung von Speicherplatz je Space überprüfen. Eine Erweiterung ist derzeit nicht möglich.',
    'AdminCat is usually available 24 hours a day. However, in cases of force majeure we cannot guarantee the uninterrupted accessibility and availability of all services. Of course we will announce planned maintenance work related downtimes via e-mail and on our website in advance.':
      'AdminCat steht in der Regel 24 Stunden täglich zur Verfügung. AdminCat kann jedoch keine Gewähr für die ununterbrochene Erreichbarkeit und Verfügbarkeit sämtlicher Leistung übernehmen. Selbstverständlich kündigen wir geplante wartungsbedingte Ausfallzeiten per E-Mail und auf unserer Website im Voraus an.',

    'Usage fee': 'Nutzungsentgelt',
    'The use of AdminCat is available free of charge within the three months trial period without any special service restrictions. After that period a monthly or yearly paid subscription interval may be chosen on the user settings page (go to the subscription page to see the current subscription fees).':
      'Die Nutzung von AdminCat ist innerhalb der dreimonatigen Testphase kostenlos und ohne Serviceeinschränkungen möglich. Danach kann in den Benutzereinstellungen ein monatliches oder jährliches kostenpflichtiges Abonnement gewählt werden (auf dieser Seite können Sie die aktuellen Angebote einsehen).',

    'Obligations of the customer': 'Pflichten des Kunden',
    'The customer will keep the access password to his account secret and change it immediately or have it changed by AdminCat if unauthorized third parties could have gained knowledge of it.':
      'Der Kunde wird das Zugangspasswort zu seinem Account geheim halten und es unverzüglich ändern oder von AdminCat ändern lassen, wenn unbefugte Dritte davon Kenntnis erlangt haben könnten.',
    'The customer is also liable for third parties who use or have used services via his account, either authorized or unauthorized. This does not apply if the customer is not responsible for unauthorized use. The customer must provide evidence that he is not responsible for such use.':
      'Der Kunde haftet auch für Dritte, die befugt oder unbefugt Leistungen über seinen Account nutzen oder genutzt haben. Dies gilt nicht, wenn der Kunde eine unbefugte Nutzung nicht zu vertreten hat. Der Kunde hat den Nachweis zu erbringen, dass er eine solche Nutzung nicht zu vertreten hat.',
    'The customer will not send any advertising by e-mail for commercial purposes via the mail service and will not use the mail service to send standardized e-mails to a large number of recipients.':
      'Der Kunde wird über den Mail Service keine Werbung per E-Mail für kommerzielle Zwecke versenden und den Mail Service nicht zum Versand standardisierter E-Mails an eine Vielzahl von Empfängern nutzen.',

    'Abuse and protection of minors': 'Missbrauch und Jugendschutz',
    'The customer undertakes not to misuse AdminCat':
      'Der Kunde verpflichtet sich, AdminCat nicht missbräuchlich zu nutzen',
    'not to send spam (no more than 100 e-mails per hour and no more than 300 e-mails':
      'keinen Spam zu verschicken (nicht mehr als 100 E-Mails pro Stunde und nicht mehr als 300 E-Mails',
    'per day)': 'pro Tag versenden)',

    'not to violate any laws (e.g. sending forbidden or harassing messages)':
      'nicht gegen Gesetze zu verstossen (z.B. Versenden verbotener oder belästigender Nachrichten)',
    'not to violate regulations for the protection of young people':
      'nicht gegen Vorschriften zum Schutze der Jugend zu verstoßen',

    'not to use any mechanisms, software or scripts in connection with the use of the services that could':
      'keine Mechanismen, Software oder Scripts im Zusammenhang mit der lnanspruchnahme der Dienste verwenden,',
    'impair or disrupt the functioning of AdminCat':
      'die das Funktionieren von AdminCat beeinträchtigen oder stören können',

    'The customer is liable to AdminCat for damage caused by violating his obligations under 6.1., and releases AdminCat from any third party claims in this regard. This does not apply, if he is not responsible for the violation. The customer must provide evidence that he is not responsible for the violation.':
      'Der Kunde haftet AdminCat gegenüber für Schäden, die durch Verstöße gegen seine Pflichten aus 6.1. entstehen und stellt AdminCat von diesbezüglichen Ansprüchen Dritter frei. Dies gilt nicht, wenn er den Verstoß nicht zu vertreten hat. Der Kunde hat den Nachweis zu erbringen, dass er den Verstoß nicht zu vertreten hat.',
    'If the customer uses AdminCat contrary to the provisions of 6.1 and 5.3, the user account can get blocked by AdminCat without prior notice. The right to terminate the contract without notice remains unaffected.':
      'Nutzt der Kunde AdminCat entgegen den Bestimmungen von 6.1 und 5.3, kann das Nutzerkonto ohne vorherige Ankündigung durch AdminCat gesperrt werden. Das Recht zur fristlosen Kündigung des Vertrages bleibt hiervon unberührt.',

    Privacy: 'Datenschutz',

    'AdminCat and the customer will comply with the statutory data protection requirements, in particular according to the Telemedia Act (TMG) and the Federal Data Protection Act (BDSG). Inventory and usage data of the customer are only collected, processed or used insofar as this is necessary for the execution of the contractual relationship.':
      'AdminCat und der Kunde werden die gesetzlichen Datenschutzbestimmungen, insbesondere nach dem Telemediengesetz (TMG) und dem Bundesdatenschutzgesetz (BDSG), einhalten. Bestands- und Nutzungsdaten des Kunden werden nur erhoben, verarbeitet oder genutzt, soweit dies für die Durchführung des Vertragsverhältnisses erforderlich ist.',
    'In order to ensure the service integrity and compliance with the Terms of Use of AdminCat, in cases of reasonable suspicion of misuse AdminCat is entitled to determine polluters and restrict the usage of services for these customers.':
      'Um die lntegrität der Dienste und die Einhaltung der Nutzungsbedingungen von AdminCat sicherstellen zu können, ist AdminCat berechtigt, bei begründetem Verdacht auf missbräuchliche Nutzung, den Verursacher festzustellen und die in Anspruch genommene Leistung im Einzelfall für diesen einzuschränken.',
    'Further information on the type, scope and purpose of the collection, processing and use of personal and other data can be found in the Privacy policy of AdminCat.':
      'Weitere Informationen über Art, Umfang und Zwecke der Erhebung, Verarbeitung und Nutzung personenbezogener und anderer Daten finden Sie in der Datenschutzerklärung zu AdminCat.',

    'Warranty and Liability': 'Gewährleistung und Haftung',
    'AdminCat encrypts and protects the managed data and documents on the live server as far as this is possible with the current state of the art. With regular backups, we also prevent major data loss from occurring. Development guidelines and internal processes make sure, that new features are thoroughly tested in a test environment and the users data isn’t get compromised by any updates. For the unlikely event of data loss however, we recommend users to keep digital copies on their local systems as a precaution.':
      'AdminCat verschlüsselt und schützt die verwalteten Daten und Dokumente auf dem Live-Server, soweit dies nach dem aktuellen Stand der Technik möglich ist. Mit regelmäßigen Backups beugen wir zudem größeren Datenverlusten vor. Entwicklungsrichtlinien und interne Prozesse stellen sicher, dass neue Features in einer Testumgebung ausgiebig getestet werden und die Daten der Nutzer durch Updates nicht gefährdet werden. Für den unwahrscheinlichen Fall eines Datenverlustes empfehlen wir den Nutzern jedoch, vorsorglich digitale Kopien auf ihren lokalen Systemen zu erstellen.',
    'AdminCat does not guarantee that all of the services mentioned under item 3 are available and free of errors at all times. This applies in particular if access to these services is caused by disruptions that are beyond the control of AdminCat. We will immediately eliminate disruptions to the services mentioned under item 3 within the scope of the existing technical and operational possibilities.':
      'AdminCat übernimmt keine Gewähr dafür, dass alle unter Punkt 3 genannten Dienste jederzeit verfügbar und fehlerfrei sind. Dies gilt insbesondere dann, wenn der Zugang zu diesen Diensten durch Störungen verursacht wird, die nicht im Einflussbereich von AdminCat liegen. Wir werden Störungen der unter Ziffer 3 genannten Dienste im Rahmen der bestehenden technischen und betrieblichen Möglichkeiten unverzüglich beseitigen.',
    'Any liability claim against us related to a possible data loss suffered or incorrect and/or harmful data processing needs to be documented or reasonably reproducible. Our support team is your first level contact, ready to analyze incidents, give advice or support with quick bug-fixing in case we identify design and processing errors in our responsibility.':
      'Haftungsansprüche gegen uns, die sich auf einen eventuell erlittenen Datenverlust oder eine fehlerhafte und/oder schädliche Datenverarbeitung beziehen, müssen dokumentiert oder in zumutbarer Weise reproduzierbar sein. Unser Support-Team steht Ihnen als erster Ansprechpartner zur Verfügung, um Vorfälle zu analysieren, Ratschläge zu erteilen oder Sie bei der schnellen Fehlerbehebung zu unterstützen, falls wir Konstruktions- und Verarbeitungsfehler in unserem Verantwortungsbereich feststellen.',

    'Termination, deletion of the account': 'Kündigung, Löschung des Accounts',
    'The usage relationship can run for an indefinite period in principle, as long as the user subscribed and paid for the service in advance. It can actively get terminated by the customer at any time – not via email, but by deleting the user account directly under the user settings, in which case we unfortunately can’t provide a reimbursement for the remaining prepaid subscription. The right to terminate the contract without notice in accordance with section 1.2 or for an important reason (sections 5 and 6) remains unaffected.':
      'Das Nutzungsverhältnis kann grundsätzlich auf unbestimmte Zeit laufen, sofern der Nutzer den Dienst im Voraus abonniert und bezahlt hat. Es kann vom Kunden jederzeit aktiv gekündigt werden - nicht per E-Mail, sondern durch Löschen des Benutzerkontos direkt in den Benutzereinstellungen, wobei wir in diesem Fall leider keine Rückerstattung für das verbleibende vorausbezahlte Abonnement leisten können. Das Recht zur fristlosen Kündigung nach Ziffer 1.2 oder aus wichtigem Grund (Ziffer 5 und 6) bleibt unberührt.',
    'A deletion removes all sensitive details of a user, such as email, password, its Space memberships and payments. However, for technical reasons, user id and the freely defined user name will remain in the system. Inactive user accounts, whose subscription ended more than three months ago and which are also no longer members of a Space anymore will get deleted automatically by AdminCat.':
      'Bei einer Löschung werden alle sensiblen Daten eines Nutzers, wie E-Mail, Passwort, seine Space-Mitgliedschaften und Zahlungen, entfernt. Aus technischen Gründen bleiben jedoch die Benutzerkennung und der frei definierte Benutzername im System erhalten. Inaktive Benutzerkonten, deren Abonnement vor mehr als drei Monaten endete und die auch nicht mehr Mitglied in einem Space sind, werden automatisch von AdminCat gelöscht.',
    'If the subscription of a Space admin expires, the admin role gets reassigned to any of the remaining active/paying users (if any) automatically, such that the Space continues to have an admin. If Space administrating users decide to actively delete themselves and the admin role was not consciously transferred to another user before, the admin role gets reassigned to any of the remaining active/paying (or in secondary priority inactive/non-paying) users automatically. If there is none or the payment periods of all relevant inactive users ended more than 3 months ago, the Space will get deleted automatically as well.':
      'Wenn das Abonnement eines Space-Admins ausläuft, wird die Admin-Rolle automatisch an einen der verbleibenden aktiven/zahlenden Nutzer (falls vorhanden) neu zugewiesen, so dass der Space weiterhin einen Admin hat. Wenn ein Space-Administrator sich selbst aktiv löscht und die Admin-Rolle vorher nicht bewusst auf einen anderen Benutzer übertragen wurde, wird die Admin-Rolle automatisch an einen der verbleibenden aktiven/zahlenden (oder in zweiter Priorität inaktiven/nicht-zahlenden) Benutzer neu zugewiesen. Wenn es keine gibt oder die bezahlte Nutzung aller relevanten inaktiven Nutzer vor mehr als 3 Monaten endete, wird der Space ebenfalls automatisch gelöscht.',

    'Governing Law': 'Geltendes Recht',
    'The law of the Federal Republic of Germany applies exclusively to all legal relationships between AdminCat and the customer. The law on the United Nations Convention on the International Sale of Goods ("CISG") does not apply.':
      'Für sämtliche Rechtsbeziehungen zwischen AdminCat und dem Kunden gilt ausschließlich das Recht der Bundesrepublik Deutschland. Das Recht des Übereinkommens der Vereinten Nationen über Verträge über den internationalen Warenkauf ("CISG") findet keine Anwendung.',

    'Status: June 2024': 'Stand: Juni 2024',

    'Privacy Statement': 'Datenschutzerklärung',
    'Thank you for your interest in our online service. The protection of your data is very important to us. We therefore apply the utmost care and state-of-the-art security standards to ensure maximum protection of your data. Below we will explain in detail, which data AdminCat is processing and how it protects this data.':
      'Wir freuen uns über Ihr Interesse an unserem Online-Service. Der Schutz Ihrer Daten liegt uns sehr am Herzen. Daher wenden wir äußerste Sorgfalt und modernste Sicherheitsstandards an, um einen maximalen Schutz Ihrer Daten zu gewährleisten. Im Folgenden erklären wir Ihnen ausführlich, welche Daten AdminCat verarbeitet und wie es diese Daten schützt.',

    'The person responsible for data processing is':
      'Verantwortlicher für die Datenverarbeitung ist',

    'Legal basis': 'Rechtsgrundlage',
    "The provision of our services requires the processing of data and takes place in accordance with the statutory provisions. For data protection, the General Data Protection Regulation (GDPR), the applicable Federal Data Protection Act (BDSG), the Telemedia Act (TMG) and telecommunications secrecy are decisive. AdminCat's services are hosted exclusively on servers in Germany (Frankfurt am Main).":
      'Die Erbringung unserer Dienstleistungen erfordert die Verarbeitung von Daten und erfolgt entsprechend den gesetzlichen Vorschriften. Für den Datenschutz sind insbesondere die Datenschutzgrundverordnung (DSGVO), das geltende Bundesdatenschutzgesetz (BDSG), das Telemediengesetz (TMG) sowie das Fernmeldegeheimnis maßgeblich. Die Dienste von AdminCat werden ausschliesslich auf Servern in Deutschland (Frankfurt am Main) gehostet.',

    Terms: 'Begriffe',
    "The legislator requires that personal data be processed in a lawful manner, in good faith and in a manner that is understandable for the person concerned ('lawfulness, processing in good faith, transparency'). To ensure this, we inform you about the individual legal definitions that are also used in this data protection declaration":
      'Der Gesetzgeber fordert, dass personenbezogene Daten auf rechtmäßige Weise, nach Treu und Glauben und in einer für die betroffene Person nachvollziehbaren Weise verarbeitet werden („Rechtmäßigkeit, Verarbeitung nach Treu und Glauben, Transparenz“). Um dies zu gewährleisten, informieren wir Sie über die einzelnen gesetzlichen Begriffsbestimmungen, die auch in dieser Datenschutzerklärung verwendet werden',

    'Personal data': 'Personenbezogene Daten',
    '"Personal data" is all information that relates to an identified or identifiable natural person (hereinafter “data subject”); A natural person is regarded as identifiable who can be identified directly or indirectly, in particular by means of assignment to an identifier such as a name, an identification number, location data, an online identifier or one or more special features that express the physical, physiological, genetic, psychological, economic, cultural or social identity of this natural person.':
      '“Personenbezogene Daten” sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person (im Folgenden „betroffene Person“) beziehen; als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten,iner Online-Kennung oder zu einem oder mehreren besonderen Merkmalen identifiziert werden kann, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind.',

    Processing: 'Verarbeitung',
    '"Processing" is any process carried out, with or without the help of automated processes, or any such series of processes in connection with personal data such as the collection, recording, organization, ordering, storage, adaptation or change, reading out, querying, use, disclosure by transmission, distribution or any other form of provision, comparison or linking, restriction, deletion or destruction.':
      '“Verarbeitung” ist jeder, mit oder ohne Hilfe automatisierterVerfahren, ausgeführter Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.',

    'Restriction of processing': 'Einschränkung der Verarbeitung',
    '"Restriction of processing" is the marking of stored personal data with the aim of restricting their future processing.':
      '„Einschränkung der Verarbeitung“ ist die Markierung gespeicherter personenbezogener Daten mit dem Ziel, ihre künftige Verarbeitung einzuschränken.',

    Profiling: 'Profiling',
    '"Profiling" is any type of automated processing of personal data that consists of using this personal data to evaluate certain personal aspects relating to a natural person, in particular aspects relating to work performance, economic situation, health,to analyze or predict personal preferences, interests, reliability, behavior, whereabouts or relocation of this natural person.':
      '"Profiling" ist jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin besteht, dass diese personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf eine natürliche Person beziehen, zu bewerten, insbesondere um Aspekte bezüglich Arbeitsleistung, wirtschaftliche Lage, Gesundheit, persönliche Vorlieben, Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen Person zu analysieren oder vorherzusagen.',

    Pseudonymization: 'Pseudonymisierung',
    '"Pseudonymization" is the processing of personal data in such a way that the personal data can no longer be assigned to a specific data subject without the use of additional information, provided that this additional information is stored separately and is subject to technical and organizational measures that ensure that the personal data cannot be assigned to an identified or identifiable natural person.':
      '"Pseudonymisierung" ist die Verarbeitung personenbezogener Daten in einer Weise, dass die personenbezogenen Daten ohne Hinzuziehung zusätzlicher Informationen nicht mehr einer spezifischen betroffenen Person zugeordnet werden können, sofern diese zusätzlichen Informationen gesondert aufbewahrt werden und technischen und organisatorischen Maßnahmen unterliegen, die gewährleisten, dass die personenbezogenen Daten nicht einer identifizierten oder identifizierbaren natürlichen Person zugewiesen werden können.',

    'File system': 'Dateisystem',
    '"File system" is any structured collection of personal data that is accessible according to certain criteria, regardless of whether this collection is managed centrally, decentralized or according to functional or geographical criteria':
      '"Dateisystem" ist jede strukturierte Sammlung personenbezogener Daten, die nach bestimmten Kriterien zugänglich sind, unabhängig davon, ob diese Sammlung zentral, dezentral oder nach funktionalen oder geografischen Gesichtspunkten geordnet geführt wird.',

    'Person responsible': 'Verantwortlicher',
    '"Person responsible" is a natural or legal person, authority, institution or other body that alone or jointly with others decides on the purposes and means of processing personal data; If the purposes and means of this processing are specified by Union law or the law of the member states, the person responsible or the specific criteria for his appointment can be provided for in accordance with Union law or the law of the member states.':
      '„Verantwortlicher“ ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet; sind die Zwecke und Mittel dieser Verarbeitung durch das Unionsrecht oder das Recht der Mitgliedstaaten vorgegeben, so können der Verantwortliche beziehungsweise die bestimmten Kriterien seiner Benennung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten vorgesehen werden.',

    'Access Data': 'Zugriffsdaten',
    'AdminCat collects data and information, some of which is personal data, each time the website is accessed. This data is stored in the server log files. The following can be recorded':
      'AdminCat erfasst bei jedem Aufruf der Webseite Daten und Informationen, die teilweise auch personen-bezogene Daten darstellen. Diese Daten werden in den Logfiles des Servers gespeichert. Erfasst werden können',

    'Web request and interaction with a service, e.g. PayPal':
      'Webanfrage und Interaktion mit einem Service, z.B. PayPal',
    'The Internet Protocol address (IP address)':
      'Die Internetprotokoll-Adresse (IP-Adresse)',
    'Browser type and language of the browser':
      'Browsertyp und Sprache des Browsers',
    'Date and time of access to our website':
      'Datum und Uhrzeit eines Zugriffs auf unsere Webseite',
    'Time zone difference to Greenwich Mean Time (GMT)':
      'Zeitzonendifferenz zur Greenwich Mean Time (GMT)',
    'The email address of a Space, if a user is logged in there':
      'Die Email-Adresse eines Space, wenn ein User dort eingeloggt ist',
    'Access status/HTTPS status code': 'Zugriffsstatus/HTTPS-Statuscode',

    'The legal basis for data processing is Art. 6 (1) p. 1 lit. f GDPR. Our legitimate interest is based on the following purposes for data collection':
      'Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Unser berechtigtes Interesse begründet sich aus folgenden Zwecken zur Datenerhebung',
    'Ensuring a smooth connection to the website':
      'Sicherstellung einer reibungslosen Verbindung der Website',
    'Ensuring a convenient use of our website':
      'Sicherstellung einer komfortablen Nutzung unserer Website',
    'Evaluation of system security and stability, and':
      'Bewertung der Systemsicherheit und -stabilität, und',
    'For other administrative purposes': 'für andere administrative Zwecke',

    'After the end of the communication process, the collected information is only evaluated for statistical purposes and in anonymized form. Under no circumstances do we use the data collected for the purpose of drawing conclusions about your person.':
      'Nach Beendigung des Kommunikationsvorgangs werden die gesammelten Informationen lediglich zu statistischen Zwecken und in anonymisierter Form ausgewertet. In keinem Fall verwenden wir die erhobenen Daten, um Rückschlüsse auf Ihre Person zu ziehen.',

    Cookies: 'Cookies',
    "AdminCat doesn't use any cookie. Therefore we also don't provide an integrated cookie consent tool on our site, which usually allows you to administrate your opt-in choices related to certain cookies and their features.":
      'AdminCat verwendet keine Cookies. Daher bieten wir auf unserer Website auch kein integriertes Cookie-Zustimmungstool an, mit dem Sie normalerweise Ihre Opt-in-Optionen in Bezug auf bestimmte Cookies und deren Funktionen verwalten können. ',

    'Inventory and payment data': 'Bestands- und Bezahldaten',
    'All you need to create a user account is an e-mail address. We use this to send you a link to confirm your registration and to reset your password. We do not collect any other inventory data. Your email address is not passed on to third parties, nor do we use third-party data to enrich your email address with this data and create a profile about you.':
      'Zur Erstellung eines Nutzerkontos wird lediglich eine Email-Adresse benötigt. Diese verwenden wir, um Ihnen zur Bestätigung Ihrer Registrierung und im Falle des Zurücksetzens Ihres Passwortes jeweils einen entsprechenden Link zusenden zu können. Weitere Bestandsdaten werden von uns nicht erhoben. Ihre Email-Adresse wird weder an Dritte weitergegeben, noch nutzen wir Daten Dritter, um Ihre Email-Adresse mit diesen Daten anzureichern und ein Profil über Sie zu erstellen.',
    'Although you can voluntarily enter or view extensive data in a Space to which you have been granted access (about people, addresses, health data, contract data and much more), the members of these Spaces are themselves responsible for the data managed there. AdminCat employees have virtually no access to this data (see explanations under 7. Content data).':
      'Zwar können Sie in einem Space, zu dem Sie Zugang erhalten haben, freiwillig umfangreiche Daten erfassen oder einsehen (über Personen, Adressen, Gesundheitsdaten, Vertragsdaten und vieles mehr), allerdings sind die Mitglieder dieser Spaces für die dort verwalteten Daten selbst verantwortlich. Mitarbeiter von AdminCat haben darauf so gut wie keinen Zugriff (siehe Erläuterungen unter 7. Inhaltsdaten).',
    'You can pay for our services, such as the paid subscription, via payment services such as PayPal. The amount, the subscription period and a PayPal reference in connection with your user account are stored in our database for you as confirmation and overview for each payment transaction processed via this service, but only in double-encrypted form, so that our employees have no access to this data.':
      'Unsere Leistungen wie z.B. das kostenpflichtige Abo können Sie über Bezahldienste wie PayPal bezahlen. Für jeden darüber abgewickelten Bezahlvorgang wird der Betrag, der Abo-Zeitraum und eine PayPal-Referenz in Verbindung mit Ihrem Nutzerkonto in unserer Datenbank für Sie als Bestätigung und Übersicht gespeichert, allerdings nur in doppelt verschlüsselter Form, sodass unsere Mitarbeiter hierauf keinerlei Zugriff haben.',

    'Content data (email, documents and recorded data)':
      'Inhaltsdaten (Email, Dokumente und erfasste Daten)',
    'AdminCat manages emails, documents and extensive master and transaction data for you (insofar as you decide to use it). Only you as the user should have sovereignty and full control over this data. That is why we offer you maximum protection with our security concept':
      'AdminCat verwaltet für Sie (insoweit Sie sich für deren Nutzung entscheiden) Emails, Dokumente und umfangreiche Stamm- und Bewegungsdaten. Die Hoheit über diese Daten sollen allein Sie als Nutzer haben. Daher bieten wir Ihnen mit unserem Sicherheitskonzept maximalen Schutz',

    'AdminCat is hosted on an AWS server in Frankfurt am Main (Germany) and is therefore subject to the strictest EU data protection regulations; AWS has ISO/IEC certifications 27001:2022, 27017:2015 and 27018:2019':
      'AdminCat wird auf einem Server der Firma AWS in Frankfurt am Main (Deutschland) gehostet und unterliegt damit strengsten EU-Datenschutzvorgaben; AWS besitzt die ISO/IEC-Zertifizierungen 27001:2022, 27017:2015 und 27018:2019',
    'We secure communication with your end device using the modern TLS 1.3 (Transport Layer Security) standard, which means that all data is transmitted in encrypted form and protected against manipulation and unauthorized access by third parties during transmission':
      'Die Kommunikation mit Ihrem Endgerät sichern wir mit dem modernen Standard TLS 1.3 (Transport Layer Security), wodurch sämtliche Daten verschlüsselt übermittelt werden und auf dem Übertragungsweg vor Manipulationen und dem unbefugten Zugriff Dritter geschützt sind',
    'We counter potential attackers with high password standards, automatic session termination after 1 hour of inactivity and optional two-factor authentication (2FA)':
      'Wir begegnen möglichen Angreifern mit hohen Passwort-Standards, einer automatischen Sitzungsbeendigung nach 1 Stunde Inaktivität und einer optionalen Zwei-Faktor-Authentisierung (2FA)',
    'In addition to AWS encryption, your content data is stored on a second encrypted database and access to it is only permitted using keys assigned to your login data':
      'Ihre Inhaltsdaten werden zusätzlich zu der AWS-Verschlüsselung auf einer zweitverschlüsselten Datenbank gespeichert und ein Zugriff darauf ausschließlich durch Ihren Logindaten zugeordnete Schlüssel erlaubt',
    'The column names in the tables of our database are still masked in such a way that an unauthorized reader can hardly interpret or assign the content of the columns':
      'Die Spaltenbezeichnungen in den Tabellen unserer Datenbank sind weiterhin derart maskiert, dass ein unbefugter Leser den Inhalt der Spalten kaum interpretieren bzw. zuordnen kann',
    'In rare cases, complete encryption of the data is associated with unacceptable performance losses, e.g. for full-text searches in documents uploaded by you. Therefore, instead of secondary encryption, rigorous text splitting is used here, which means that the specific text content remains protected for you':
      'In seltenen Fällen ist eine Komplettverschlüsselung der Daten mit unzumutbaren Leistungseinbußen verbunden, z.B. bei der Volltextsuche in von Ihnen hochgeladenen Dokumenten. Daher kommt hier anstelle der Zweit-Verschlüsselung eine rigorose Textsplittung zum Einsatz, durch die der konkrete Textinhalt für Sie weiterhin geschützt bleibt',

    'Service contact (contact form, email, phone)':
      'Servicekontakt (Kontaktformular, Email, Telefon)',

    'You can contact us via a contact form (accessible from your user account), by email or by phone. These communication channels are generally open to you for your questions. We are also very interested in your feedback, what you like and your suggestions for improvements.':
      'Sie können uns über ein Kontaktformular (von Ihrem Nutzerkonto aus zugänglich), per Email oder Telefon kontaktieren. Diese Kommunikationswege stehen Ihnen allgemein für Ihre Fragen offen. Darüber hinaus interessieren wir uns sehr für Ihr Feedback, was Ihnen gefällt und Ihre Vorschläge bezüglich Verbesserungen.',

    'Personal data is not required, unless you may wish to be called back or receive an answer by email. To analyze errors on the site, we ask you for a description of the error as detailed as possible, but we will never ask for personal data or even login data. Seven days after each request has been completed, we delete all related messages in our systems. Your telephone number will remain on a call list on one of our telephones for one month without a name reference until it is deleted; no additional storage or further processing will take place.':
      ' Personenbezogene Daten werden hierfür nicht benötigt, außer Sie wünschen evtl. einen Rückruf oder eine Antwort per Email. Zur Analyse von Fehlern auf der Seite bitten wir Sie um eine möglichst detailierte Fehlerbeschreibung, personenbezogene Daten oder sogar Login-Daten werden wir dafür allerdings niemals erfragen. Sieben Tage nach Abschluss einer jeden Anfrage löschen wir sämtliche dazugehörige Nachrichten in unseren Systemen. Ihre Telefonnummer bleibt bis zur Löschung einen Monat ohne Namenbezug auf einer Anrufliste eines unserer Telefone, eine zusätzliche Speicherung bzw. Weiterverarbeitung findet nicht statt.',

    'Disclosure of data': 'Weitergabe von Daten',
    'AdminCat does not pass on any data to third parties unless there is a legal obligation to do so or the transfer of the data is absolutely necessary for the provision of a service requested by the user (Art. 6 para. 1 lit. f GDPR). This section provides information about such services, our partners involved in them and what data is used for what purpose. Users who make use of these services on our website tacitly consent to the corresponding processing.':
      'AdminCat gibt keine Daten an Dritte weiter, es sei denn es gibt dazu gesetzliche Verpflichtungen oder die Weitergabe der Daten ist für die Erbringung einer vom Nutzer gewünschten Dienstleistung unbedingt erforderlich (Art. 6 Abs. 1 lit. f DSGVO). Dieser Abschnitt informiert über solche Services, unsere dafür eingebundenen Partner und welche Daten zu welchem Zweck verwendet werden. Nutzer, die diese Dienste auf unserer Seite in Anspruch nehmen, geben zur entsprechenden Verarbeitung stillschweigend ihr Einverständnis.',
    'AWS is our hosting partner, on whose servers our website is operated in Frankfurt am Main (Germany). This means that AWS is of course bound by the strict European data protection laws (GDPR), and under no circumstances may it access your data or pass data on to third parties. The open source software AWStats is used on its servers to analyze log files, which means that no cookies are required by your browser. AWS only uses anonymized IP address data for this purpose. This data cannot be assigned to a specific person and is not forwarded to third parties.':
      'AWS ist unser Hosting-Partner, auf dessen Servern unsere Webseite in Frankfurt am Main (Deutschland) betrieben wird. Damit ist AWS selbstverständlich an die strengen europäischen Datenschutzgesetze (DSGVO) gebunden, unter keinen Umständen darf es weder auf Ihre Daten zugreifen noch Daten an Dritte weitergeben. Auf seinen Servern wird zur Analyse von Logfiles die OpenSource-Software AWStats eingesetzt, wodurch keine Cookies durch Ihren Browser notwendig sind. AWS verwendet dazu nur anonymisierte IP-Adressdaten. Diese Daten sind keiner bestimmten Person zuordenbar, eine Weiterleitung an Dritte findet nicht statt.',
    'If you take out a subscription on our website, you can use the service provider PayPal for payment. To do this, you need a corresponding user account with PayPal, for which you agree to the terms and conditions and privacy policy accordingly. For each payment process, AdminCat only transmits the absolutely necessary data such as provider, amount and currency and receives the status and a PayPal reference number of the payment from PayPal.':
      'Wenn Sie auf unserer Seite ein Abonnement abschließen, können Sie zur Bezahlung den Dienstleister PayPal verwenden. Dafür benötigen Sie ein entsprechendes Nutzer-Konto bei PayPal, zu dessen Eröffnung Sie sich dort mit AGB und Datenschutzerklärung einverstanden erklären. AdminCat übermittelt bei jedem Bezahlvorgang lediglich die dafür absolut notwendigen Daten wie Anbieter, Betrag und Währung und erhält von PayPal jeweils den Status und eine PayPal-Referenznummer der Zahlung.',
    'You will find embedded videos on our pages that are provided by the YouTube service. YouTube is operated by Google Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5, Ireland. When you play a video, a direct connection to the Google servers is established. Your IP address and the address of the page from which you are watching the video will be transmitted. YouTube also uses a cookie that collects further user data in order to offer matching videos. If you have a Google user account and are currently logged in, YouTube can link data, such as the video you clicked on, to your Google user account. If you do not wish this to happen, you must log out of Google.':
      'Auf unseren Seiten finden Sie eingebettete Videos, die durch den Dienst YouTube bereitgestellt werden. Betreiber von YouTube ist die Firma Google Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5, Ireland. Wenn Sie ein Video abspielen, wird eine direkte Verbindung zu den Servern der Firma Google hergestellt. Es wird Ihre IP-Adresse und die Adresse der Seite übermittelt, von der aus Sie das Video ansehen. Außerdem verwendet YouTube ein Cookie, welches weitere Nutzerdaten sammelt, um dazu passende Videos anzubieten. Wenn Sie über ein Nutzerkonto von Goolge verfügen und aktuell angemeldet sind, kann YouTube Daten, wie das angeklickte Video, mit Ihrem Google-Nutzerkonto verknüpfen. Falls Sie dies nicht wünschen, müssen Sie sich bei Google abmelden (ausloggen). ',
    "We also use Google Fonts on our website, that are loaded onto your device from the Google Fonts servers by your browser. In this way, we can consistently present our texts in an appealing form (Art. 6 para. 1 lit. f GDPR). You can find more information about Google's data protection at":
      'Weiterhin verwenden wir auf unserer Webseite Schriftarten, die von Google zur Verfügung gestellt werden. Die Schriftarten werden dabei von den Servern von Google Fonts durch Ihren Browser auf Ihr Endgerät geladen. Auf diese Weise können wir unsere Texte immer in ansprechender Form darstellen (Art. 6 Abs. 1 lit. f DSGVO). Weitere Informationen zum Datenschutz von Google finden Sie in der Datenschutzerklärung unter',
    'https://policies.google.com/privacy.':
      'https://policies.google.com/privacy?hl=de.',
    'AdminCat does not use social media plugins (e.g. for Facebook, Instagram or X). We also do not use tracking and analysis services such as Google Analytics. Furthermore, we do not use user profiling and automated decisions based on this, nor do we display advertising or send newsletters.':
      'AdminCat nutzt keine Social-Media-Plugins (wie z.B. für Facebook, Instagram oder X). Auch Tracking und Analyse-Dienste wie z.B. Google Analytics sind bei uns nicht im Einsatz. Weiterhin verzichten wir komplett sowohl auf ein sogenanntes User-Profiling und darauf basierende automatisierte Entscheidungen als auch auf die Anzeige von Werbung und das Versenden von Newslettern.',

    Others: 'Sonstiges',
    'AdminCat does not pass on any data to third parties (except in the case of a legal obligation). The site also does not use any social media Plug-Ins (such as for Facebook, Instagram or Twitter). We also do not use tracking and analysis services such as Google Analytics. Furthermore, we completely dispense with so-called user profiling and automated decisions based on it, as well as the display of advertising and the sending of newsletters.':
      'AdminCat gibt keine Daten an Dritte weiter (außer im Falle einer gesetzlichen Verpflichtung). Die Seite nutzt zudem keinerlei Social-Media-Plugins (wie z.B. für Facebook, Instagram oder Twitter). Auch Tracking und Analyse-Dienste wie z.B. Google Analytics sind bei uns nicht im Einsatz. Weiterhin verzichten wir komplett sowohl auf ein sogenanntes User-Profiling und darauf basierende automatisierte Entscheidungen als auch auf die Anzeige von Werbung und das Versenden von Newslettern.',

    'Data protection rights of the data subject':
      'Datenschutzrechte der betroffenen Person',

    'Conditions for consent': 'Recht auf Widerruf der Einwilligung',
    'Art. 7 Paragraph 3 GDPR': 'Art. 7 Abs 3 DSGVO',
    'If the processing of personal data is based on consent given, you have the right to revoke your consent at any time in accordance with':
      'Sofern die Verarbeitung personenbezogener Daten auf einer erteilten Einwilligung beruht, haben Sie gemäß',
    'Revoking your consent does not affect the legality of the processing carried out on the basis of your consent up to the point of revocation.':
      'jederzeit das Recht, die Einwilligung zu widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.',

    'You can contact us at any time to exercise your right of withdrawal.':
      'Für die Ausübung des Widerrufsrechts können Sie sich jederzeit an uns wenden.',

    'Right of access by the data subject': 'Recht auf Bestätigung',
    'In accordance with': 'Sie haben gemäß',
    'Art. 15 GDPR': 'Art. 15 DSGVO',

    'you have the right to request a confirmation from AdminCat as to whether we are processing personal data related to you. If that is the case, you have the right to information about this personal data, the details of the processing and comprehensive information about your rights. You can request confirmation at any time using the contact details above.':
      'das Recht, von AdminCat eine Bestätigung darüber zu verlangen, ob bei uns Sie betreffende personenbezogene Daten verarbeitet werden. Sollte das der Fall sein, haben Sie das Recht auf Auskunft über diese personenbezogenen Daten, die Details der Verarbeitung und eine umfassende Belehrung über Ihre Rechte. Die Bestätigung können Sie jederzeit unter den oben genannten Kontaktdaten verlangen',

    'Right to rectification': 'Recht auf Berichtigung',
    'In accordance with': 'Sie haben gemäß',
    'Art. 16 GDPR': 'Art. 16 DSGVO',
    'you have the right to demand immediate rectification or completion of personal data about you that is administered by us.':
      'das Recht, von uns unverzüglich das Berichtigen oder Vervollständigen von personenbezogenen Daten zu verlangen, die bei uns zu Ihnen gespeichert sind.',

    'Right to erasure (right to be forgotten)':
      'Recht auf Löschung (Recht auf vergessen werden)',
    'In accordance with': 'Sie haben gemäß',
    'Art. 17 GDPR': 'Art. 17 DSGVO',
    'you have the right to request the immediate deletion of personal data that AdminCat has stored about you, provided that it is no longer necessary for the purposes for which it was collected or otherwise processed.':
      'das Recht, das unverzügliche Löschen von personenbezogenen Daten zu verlangen, die bei AdminCat zu Ihnen gespeichert sind, sofern sie für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig sind',

    'Right to restriction of processing': 'Recht auf Einschränkung der Verarbeitung',
    // "According to":"Jede von der Verarbeitung personenbezogener Daten betroffene Person hat gemäß",
    'Art. 18 GDPR': 'Art. 18 DSGVO',
    'According to': 'Gemäß',
    // "according to":"Jede von der Verarbeitung personenbezogener Daten betroffene Person hat gemäß",
    'any data subject affected by the processing of personal data, has the right to require the person responsible for restricting the processing, if such information is incorrect, the processing illegitimate or inexpedient, or if an objection according to':
      'das Recht, von dem Verantwortlichen die Einschränkung der Verarbeitung zu verlangen, falls diese Daten inkorrekt sind, die Verarbeitung unrecht- oder unzweckmäßig ist oder falls ein Widerspruch gemäß',

    'Art. 21 paragraph 1': 'Art. 21 Abs 1',
    'is present.': 'vorliegt.',

    'In order to exercise the right to restriction of processing, the data subject can contact us at any time using the contact details given above.':
      'Um das Recht auf Einschränkung der Verarbeitung geltend zu machen, kann sich die betroffene Person jederzeit unter den oben angegebenen Kontaktdaten an uns wenden.',

    'Notification obligation to receiving third parties':
      'Mitteilungspflicht an empfangende Dritte',
    'If the person responsible is sharing personal data of the data subject with third parties, according to':
      'Sollte der Verantwortliche personenbezogene Daten an Dritte weitergeben, so muss er gemäß',
    'Art. 19 GDPR': 'Art. 19 DSGVO',
    'he must notify these recipients when personal data have been corrected or deleted':
      'diesen Empfängern mitteilen, wenn personenbezogene Daten berichtigt oder gelöscht wurden',
    'you have the right to ask companies to whom this information was sent':
      'Sie haben das Recht, bei Unternehmen zu erfragen, an welche Empfänger diese Informationen gingen',

    'However, since we do not pass on any data to third parties, this passage has no practical significance for you.':
      'Da wir allerdings keine Daten an Dritte weitergeben, hat dieser Passus für Sie hier keine praktische Bedeutung.',

    'Right to data portability': 'Recht auf Datenübertragbarkeit',
    'If you have provided a company with personal data in an automated process, you have the right, in accordance with':
      'Haben Sie einem Unternehmen in einem automatisierten Verfahren personenbezogene Daten bereitgestellt, haben Sie gemäß',
    'Art. 20 GDPR': 'Art. 20 DSGVO',
    'to receive the personal data stored there about you in a structured, common and machine-readable format or to request that it be transmitted to another person responsible. At least as far as this is technically feasible.':
      'das Recht, die dort über Sie gespeicherten personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen. Zumindest, so weit dies technisch machbar ist.',
    'Right to object': 'Recht auf Widerspruch',

    'In accordance with': 'Sie haben gemäß',
    'Art. 21 GDPR': 'Art. 21 DSGVO',
    'you have the right to object to processing of personal data at AdminCat at any time, unless there are compelling legitimate grounds for the processing.':
      'das Recht, jederzeit dem Verarbeiten von personenbezogenen Daten bei AdminCat zu widersprechen, es sei denn, es gibt zwingende schutzwürdige Gründe für diese Verarbeitung.',

    'To exercise the right to object you can contact the relevant person responsible at any time.':
      'Zum Ausüben des Widerspruchsrechts können Sie sich jederzeit an den jeweiligen Verantwortlichen wenden.',
    'Automated individual decision-making, including profiling':
      'Automatisierte Entscheidungen im Einzelfall einschließlich Profiling',

    'According to': 'Gemäß',
    'Art. 22 GDPR': 'Art. 22 DSGVO',
    // "According to":"Jede von der Verarbeitung personenbezogener Daten betroffene Person hat gemäß",
    'every person affected by the processing of personal data has the right not to be subject to a decision based on automated processing - including profiling. Such processing is not used at AdminCat.':
      'das Recht, nicht einer auf einer automatisierten Verarbeitung – einschließlich Profiling – beruhenden Entscheidung unterworfen zu werden. Bei AdminCat ist eine solche Verarbeitung nicht im Einsatz.',

    'This privacy statement is currently valid and is dated June 2024. Due to the further development of AdminCat or due to changed legal or official requirements, it may be necessary to change this privacy statement. In this case, we will inform our users in advance by email.':
      'Diese Datenschutzerklärung ist aktuell gültig und hat den Stand Juni 2024. Durch die Weiterentwicklung von AdminCat oder aufgrund geänderter gesetzlicher beziehungsweise behördlicher Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu ändern. In diesem Fall informieren wir unsere Nutzer vorab per Email.',

    'Communication of a personal data breach to the data subject':
      'Benachrichtigung bei Verletzung des Datenschutzes',

    'If a company saves your personal data, it must inform you immediately in accordance with':
      'Speichert ein Unternehmen personenbezogene Daten von Ihnen, muss es Sie gemäß',
    'Art. 34 GDPR': 'Art. 34 DSGVO',
    'If the protection of this data has been breached, we will inform you about security-relevant incidents in which the security of your data stored at AdminCat could be violated by email and on our website and give you tips for suitable measures.':
      'unverzüglich darüber informieren, wenn der Schutz dieser Daten verletzt wurde. Über sicherheitsrelevante Vorfälle, bei denen die Sicherheit Ihrer bei AdminCat gespeicherten Daten verletzt werden könnte, werden wir Sie per E-Mail und auf unserer Webseite informieren und Tipps für geeignete Maßnahmen mitteilen.',

    'Right to lodge a complaint with a supervisory authority':
      'Recht auf Beschwerde bei einer Aufsichtsbehörde',
    // "According to you have the right to complain to a supervisory authority. The responsible supervisory authorities are":"Gemäß Art. 77 DSGVO haben Sie das Recht, sich bei einer Aufsichtsbehörde zu beschweren. Die zuständigen Aufsichtsbehörden sind",

    'According to': 'Gemäß',
    'Art. 77 GDPR': 'Art. 77 DSGVO',
    'you have the right to complain to a supervisory authority. The responsible supervisory authorities are':
      'haben Sie das Recht, sich bei einer Aufsichtsbehörde zu beschweren. Die zuständigen Aufsichtsbehörden sind',

    'Affected persons have the right to complain to the competent technical supervisory authority in the event of data protection problems. The appropriate authority for us is':
      'Betroffene haben das Recht, sich bei datenschutzrechtlichen Problemen bei der zuständigen Fachaufsichtsbehörde zu beschweren. Die entsprechende Stelle für AdminCat ist',

    'Topicality and changes to this privacy statement':
      'Aktualität und Änderung dieser Datenschutzerklärung',

    'This privacy statement is currently valid and is dated June 2024. Due to the further development ofAdminCat or due to changed legal or official requirements, it may be necessary to change this privacy statement. In this case, we will inform our users in advance by email.':
      'Diese Datenschutzerklärung ist aktuell gültig und hat den Stand Juni 2024. Durch die Weiterentwicklung von AdminCat oder aufgrund geänderter gesetzlicher beziehungsweise behördlicher Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu ändern. In diesem Fall informieren wir unsere Nutzer vorab per Email.',
    'Password Recovery': 'Passwort Wiederherstellung',
    'Reset Password': 'Passwort Zurücksetzen',
    'An email has been sent to your email address':
      'Eine E-Mail wurde an Ihre E-Mail-Adresse gesendet',
    'Change My Password': 'Ändere mein Passwort',
    'New Password': 'Neues Passwort',
    'Confirm Password': 'Kennwort bestätigen',
    Base: 'Basis',
    Housing: 'Wohnen',
    Leisure: 'Freizeit',
    Dashboard: 'Dashboard',
    Submit: 'Senden',
    'user terms': 'AGB',
    'privacy policy': 'Datenschutzerklärung',
    'Please enter your one-time code (TOTP) here:':
      'Bitte geben Sie hier Ihren Einmalcode (TOTP) ein:',
    'Password length must not be less than 14':
      'Die Länge des Passworts darf nicht weniger als 14 betragen',
    'Password must contain at least two(2) numbers':
      'Das Passwort muss mindestens zwei (2) Zahlen enthalten',
    'Password must contain at least two(2) lowercase letter':
      'Das Passwort muss mindestens zwei (2) Kleinbuchstaben enthalten',
    'Password must contain at least two(2) uppercase letter':
      'Das Passwort muss mindestens zwei (2) Großbuchstaben enthalten',
    'Password must contain at least one(1) special character':
      'Das Passwort muss mindestens ein (1) Sonderzeichen enthalten',
    faqs: 'FAQs',
    Phone: 'Telefon',
    Fax: 'Telefax',
    'Resend TOTP': 'Erneut senden TOTP',
    'Your session has expired. If you wish, please log in again.':
      'Ihre Sitzung wurde beendet. Wenn Sie mögen, melden Sie sich bitte erneut an.',
    'Registration successful. Activation link has been sent to your email':
      'Registrierung erfolgreich. Der Aktivierungslink wurde an Ihre E-Mail gesendet',
    'Registration Status': 'Registrationsstatus',
    'Registration successful': 'Registrierung erfolgreich',
    'Password length must not be less than 14':
      'Das Passwort muss mindestens 14 Zeichen lang sein',
    'Password must contain at least two(2) numbers':
      'Das Passwort muss mindestens zwei (2) Zahlen enthalten',
    'Password must contain at least two(2) lowercase letter':
      'Das Passwort muss mindestens zwei (2) Kleinbuchstaben enthalten',
    'Password must contain at least two(2) uppercase letter':
      'Das Passwort muss mindestens zwei (2) Großbuchstaben enthalten',
    'Password must contain at least one(1) special character':
      'Das Passwort muss mindestens ein (1) Sonderzeichen enthalten',
    'Your session has expired. If you wish, please log in again.':
      'Ihre Sitzung wurde beendet. Wenn Sie mögen, melden Sie sich bitte erneut an.',

    'What is AdminCat? And why should I use AdminCat?':
      'Was ist AdminCat? Und warum sollte ich AdminCat nutzen?',
    'AdminCat is a unique internet service solution for private households striving to completely digitalize and interconnect your paperwork and personal data. This way you can save administrative time and get a more powerful control over your entire life.':
      'AdminCat ist eine einzigartige Internet-Service-Lösung für Privathaushalte, die danach streben, ihren Papierkram und ihre persönlichen Daten vollständig zu digitalisieren und miteinander zu vernetzen. Auf diese Weise können Sie Verwaltungszeit einsparen und erhalten eine bessere Kontrolle über Ihr gesamtes Leben.',

    'Which key features does AdminCat offer?':
      'Welche Hauptfunktionen bietet AdminCat?',
    'With the integrated Email-Service, attached documents can get saved automatically where they belong and with the right categorization. An OCR text extraction allows a quick and easy full-text search. AdminCat also allows managing contracts and financial data in over 50 currencies with daily exchange rate updates. Reminders make you aware of upcoming expiration of contracts and important documents. And as a cloud service you can reach AdminCat from anywhere and at anytime. But the best is still to come as AdminCat is seeking to be your all-in-one admin solution.':
      'Mit dem integrierten Email-Service können angehängte Dokumente automatisch dort gespeichert werden, wo sie hingehören, und mit der richtigen Kategorisierung versehen werden. Eine OCR-Textextraktion ermöglicht eine schnelle und einfache Volltextsuche. AdminCat ermöglicht auch die Verwaltung von Verträgen und Finanzdaten in über 50 Währungen mit tagesaktuellen Wechselkursen. Erinnerungen machen Sie auf das bevorstehende Auslaufen von Verträgen und wichtigen Dokumenten aufmerksam. Und als Cloud-Service können Sie AdminCat von überall und jederzeit erreichen. Aber das Beste kommt erst noch, denn AdminCat will Ihre All-in-One-Verwaltungslösung sein.',

    'Which limitations at AdminCat do I need to know?':
      'Welche Einschränkungen muss ich bei AdminCat kennen?',
    'AdminCat currently offers a data storage of up to 1 GB per workspace. Uploads are possible for documents not greater than 10 MB. And excessively sending emails (>300 a day) are not allowed. For this please also see our Terms and Conditions.':
      'AdminCat bietet derzeit einen Datenspeicher von bis zu 1 GB pro Arbeitsbereich. Uploads sind für Dokumente nicht größer als 10 MB möglich. Und das übermäßige Versenden von E-Mails (>300 pro Tag) ist nicht erlaubt. Bitte beachten Sie hierzu auch unsere Allgemeinen Geschäftsbedingungen (AGB).',

    'How much does it cost to use AdminCat?':
      'Wie viel kostet die Nutzung von AdminCat?',
    'We offer a 30-days free of charge trial period to every user initially. After that all of the AdminCat services and features are offered for a monthly rate of 2 € or a yearly rate of only 19 €.':
      'Wir bieten jedem Nutzer zunächst eine 30-tägige kostenlose Testphase an. Danach werden alle AdminCat-Dienste und -Funktionen für eine monatliche Rate von 2 € oder eine jährliche Rate von nur 19 € angeboten.',

    'Is there a notice period for cancelling the contract?':
      'Gibt es eine Kündigungsfrist für den Vertrag?',
    'No, the contract is valid until the end of the prepayment period. This means that your payment alone defines the end of the contract. All user accounts are kept anonymous and hence it is technically not foreseen to offer a premature contract cancellation and payback option.':
      'Nein, der Vertrag ist bis zum Ende der Vorauszahlungsfrist gültig. Das bedeutet, dass allein Ihre Zahlung das Ende des Vertrages bestimmt. Alle Nutzerkonten werden anonym gehalten und daher ist es technisch nicht vorgesehen, eine vorzeitige Vertragsauflösung und Rückzahlungsoption anzubieten.',

    'Does AdminCat automatically delete my data after the contract has ended?':
      'Löscht AdminCat meine Daten automatisch nach Vertragsende?',
    'No. All your workspaces that still have at least one valid and subscribed user will continue to exist. If you haven’t assigned a new admin for them, the system will choose a subscribed successor automatically. Workspaces, which are assigned to inactive users only, will also remain in the system - for another two months. During that time, the first user to renew his or her subscription will become the new admin of such a space.':
      'Nein. Alle Workspaces, die noch mindestens einen gültigen und abonnierenden Benutzer haben, bleiben bestehen. Wenn Sie ihnen keinen neuen Admin zugewiesen haben, wählt das System automatisch einen Nachfolger mit Abonnement. Spaces, die nur inaktiven Benutzern zugewiesen sind, bleiben ebenfalls im System erhalten - für weitere zwei Monate. In dieser Zeit wird der erste Benutzer, der sein Abonnement erneuert, der neue Administrator eines solchen Bereichs.',

    'What happens with spaces that have no admin for more than two months?':
      'Was geschieht mit Spaces, die seit mehr als zwei Monaten keinen Administrator haben?',
    'Spaces abandoned for more than two months will get deleted automatically. An automatic and immediate deletion also takes place, if all assigned users have already deleted their accounts.':
      'Spaces, die länger als zwei Monate nicht verwaltet werden, werden automatisch gelöscht. Eine automatische und sofortige Löschung erfolgt auch, wenn alle zugewiesenen Nutzer ihre Konten bereits gelöscht haben.',

    'Are users also getting deleted automatically?':
      'Werden auch Benutzer automatisch gelöscht?',
    'Inactive (unsubscribed) users, that have no active space assignment, will get deleted automatically after one month. This concerns users for instance, which either never completed the registration or space acceptance process or whose spaces got deleted because of the two months inactivity described above.':
      'Inaktive (abgemeldete) Benutzer, die keine aktive Space-Zuordnung haben, werden nach einem Monat automatisch gelöscht. Dies betrifft z.B. Nutzer, die entweder nie den Registrierungs- oder Space-Zulassungsprozess abgeschlossen haben oder deren Spaces aufgrund der oben beschriebenen zweimonatigen Inaktivität gelöscht wurden.',

    'Is my data safe on AdminCat?': 'Sind meine Daten auf dem AdminCat sicher?',
    'For security reasons we constantly keep our software packages up-to-date to avoid exploits. Additionally to the standard encryption of our server provider, we encrypt your data a second time, such that even our staff can’t read it. High password standards, a Two-Factor-Authentication (TOTP), and the automatic session expiration after inactivity, protects all user accounts. We use the secure HTTPS protocol for data transmissions through the internet of course and daily backups are stored for the unlikely event of a major data loss.':
      'Aus Sicherheitsgründen halten wir unsere Softwarepakete ständig aktuell, um Exploits zu vermeiden. Zusätzlich zur Standardverschlüsselung unseres Serveranbieters verschlüsseln wir Ihre Daten ein zweites Mal, so dass selbst unsere Mitarbeiter sie nicht lesen können. Hohe Passwortstandards, eine Zwei-Faktor-Authentifizierung (TOTP) und der automatische Ablauf der Sitzung nach Inaktivität schützen alle Benutzerkonten. Für Datenübertragungen über das Internet verwenden wir selbstverständlich das sichere HTTPS-Protokoll, und für den unwahrscheinlichen Fall eines größeren Datenverlustes werden täglich Backups gespeichert.',

    'Do you recommend additional security measures for users?':
      'Empfehlen Sie zusätzliche Sicherheitsmaßnahmen für die Benutzer?',
    'We recommend users to obey general security rules concerning password protection and special prudence against malicious phishing mails. Additionally you can export your emails from AdminCat to your local machine manually or establish a synchronization with your Email program as an own backup.':
      'Wir empfehlen den Benutzern, die allgemeinen Sicherheitsregeln bezüglich des Passwortschutzes zu befolgen und besondere Vorsicht vor bösartigen Phishing-Mails walten zu lassen. Zusätzlich können Sie Ihre Emails aus AdminCat manuell auf Ihren lokalen Rechner exportieren oder eine Synchronisation mit Ihrem Email-Programm als eigenes Backup einrichten.',

    'Are you subject to the GDPR standard in the EU?':
      'Unterliegt AdminCat dem GDPR-Standard in der EU?',
    'Yes, AdminCat exclusively runs on servers located in Frankfurt (Germany) and is therefore subject to one of the highest privacy-protecting regulations in the world – the GDPR of the European Union. For this reason we have contracted an external data security officer, which is regularly checking, monitoring and improving our security processes.':
      'Ja, AdminCat läuft ausschließlich auf Servern in Frankfurt (Deutschland) und unterliegt damit einer der höchsten datenschutzrechtlichen Bestimmungen der Welt - der DSGVO der Europäischen Union. Aus diesem Grund haben wir einen externen Datenschutzbeauftragten unter Vertrag genommen, der unsere Sicherheitsprozesse regelmäßig der Verordnung überprüft, überwacht und verbessert.',

    'Does AdminCat also offer access via App?':
      'Bietet AdminCat auch einen Zugang per App?',
    'Not yet. At first we want you to enjoy the generous user experience that our website offers for large-screen devices. But we are planning to introduce a mobile app in the future as well.':
      'Noch nicht. Wir möchten, dass Sie zunächst das großzügige Benutzererlebnis genießen, das unsere Website für Großbildschirme bietet. Aber wir planen, in Zukunft auch eine mobile App einzuführen.',

    'How should I get started with my new account and Space?':
      'Wie soll ich mit meinem neuen Konto und dem Space beginnen?',
    'Add persons, person groups and favorite organizations. Once defined, these units can get assigned to your Space, your documents and data like contracts and financial transactions. Once logged in you can also check out our Quick-Tour videos for more recommendations. Or you visit our channel series on YouTube.':
      'Fügen Sie Personen, Personengruppen und Organisationen-Favoriten hinzu. Einmal definiert, können diese Einheiten Ihrem Space, Ihren Dokumenten und Daten wie Verträgen und finanziellen Transaktionen zugewiesen werden. Sobald Sie eingeloggt sind, können Sie sich auch unsere Quick-Tour-Videos ansehen, um weitere Empfehlungen zu erhalten. Oder Sie besuchen unsere Video-Serie auf YouTube.',

    'Does every person as member of a Space need a user account?':
      'Braucht jede Person, die in einem Space verwaltet wird, ein Benutzerkonto?',
    'No – like in a household, where only one or two people actively do the paperwork for all. In fact, a user may even be a third person taking care for the household’s administration.':
      'Nein - wie in einem Haushalt, wo nur ein oder zwei Personen aktiv den Papierkram für alle anderen erledigen. Tatsächlich kann ein Nutzer sogar eine dritte Person sein, die sich um die Verwaltung des Haushalts und die entsprechenden Personen in einem Space kümmert.',

    'What is the basis for the data on the Dashboard? ':
      'Was ist die Grundlage für die Daten auf dem Dashboard?',
    'Each Space represents a person group, which may be the members of a household or another type of personal economic unit. The dashboard displays data corresponding to this person group. If the person group members change, the financial data on the dashboard changes too.':
      'Jeder Space repräsentiert eine Personengruppe, die aus den Mitgliedern eines Haushalts oder einer anderen Art von persönlicher Wirtschaftseinheit bestehen kann. Das Dashboard zeigt Daten an, die dieser Personengruppe entsprechen. Wenn sich die Mitglieder der Personengruppe ändern, ändern sich auch die Finanzdaten auf dem Dashboard.',

    'How can I contact AdminCat in case of questions or feedback?':
      'Wie kann ich AdminCat kontaktieren, wenn ich Fragen oder Feedback habe?',
    'As an active user you can find the option “Contact us” in the user menu on the top right. Else you can also write to support@admincat.net directly. We appreciate your valuable comments and feedback, which will of course play an important role in our further product development. But please be aware, that we can only give general support and guidance, as your data is invisible for us on the database.':
      'Als aktiver Benutzer finden Sie die Option "Kontaktieren Sie uns" im Benutzermenü oben rechts. Ansonsten können Sie auch direkt an support@admincat.net schreiben. Wir freuen uns über Ihre wertvollen Kommentare und Rückmeldungen, die natürlich eine wichtige Rolle bei der weiteren Produktentwicklung spielen werden. Bitte bedenken Sie aber, dass wir nur allgemeine Hilfestellungen geben können, da Ihre Daten für uns in der Datenbank nicht sichtbar sind.',

    'Could I join the AdminCat team?': 'Kann ich dem AdminCat-Team beitreten?',
    'Of course! We are honored by your interest. Just send us an email and describe your main experiences and biggest strengths and we will come back to you.':
      'Ja, natürlich! Wir freuen uns über Ihr Interesse. Schicken Sie uns einfach eine E-Mail und beschreiben Sie Ihre wichtigsten Erfahrungen und größten Stärken und wir kommen auf Sie zurück.',
    'Registration is not allowed for this email address, please check back':
      'Die Registrierung ist für diese E-Mail-Adresse nicht erlaubt, bitte melden Sie sich wieder',

    'The collection of person-related data on our website is based, as far as possible, on voluntariness. These data will not be transmitted to third parties without your explicit approval.':
      'Die Erhebung personenbezogener Daten auf unseren Seiten erfolgt, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.',

    'Please be aware, that the data transmission over the internet (e.g. communication via email) may bear security risks. Therefore a complete protection of data from access of third parties is not possible.':
      'Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.',

    'We hereby expressly object to the use of contact data published within the framework of the imprint obligation by third parties for sending unsolicited advertising and information material. The site operators reserve the right to take legal action in the event that unsolicited advertising information is sent, such as spam e-mails.':
      'Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.',

    'Oh, sorry! Unfortunately the limit of registrations has already been reached.':
      'Oh, sorry! Das gesetzte Limit an Anmeldungen ist leider bereits erreicht.',
    However: 'Sie',
    ', you are welcome to put your name ': 'können sich aber gerne auf die ',
    'on the waiting list': 'Warteliste setzen lassen',

    'Join Waiting List': 'Auf Warteliste setzen',
    'Thank you for registering on the waiting list.':
      'Danke, dass Sie sich auf der Warteliste eingetragen haben.',
    'We will contact you as soon as new registrations are possible.':
      ' Wir melden uns bei Ihnen, sobald wieder neue Anmeldungen möglich sind',
  },
};
export default de;
