import React from 'react';
import styled from 'styled-components';


export const CenterContentDiv = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: ${props => props.flexDirection || 'column' };
    margin-top: ${props => props.marginTop || 0};
    margin-left: ${props => props.marginLeft || 0};
    margin-right: ${props => props.marginRight || 0};
    margin-down: ${props => props.marginDown || 0};
`;

export const FormFlexWrapper = styled('div')`
    display: flex;
    flex-wrap: wrap;
    padding: ${props => props.padding || '1%' };
`;

export const VariableWidthDiv = styled('div')`
    width: ${props => props.width || '100%'};
    margin-right: ${props => props.marginRight || '.5%'}
    display: flex;
    flex-direction: ${props => props.flexDirection || 'column'}
    align-items: 'center'
`;

export const FormLabel = styled.p`
color: var(--admincat-color-grey-2);
font-size: 16px;
`;