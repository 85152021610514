import React from 'react';
import styled, { css } from 'styled-components';
import { parseISO, differenceInDays } from 'date-fns';

const Banner = styled.div`
  color: #4f81bd;
  text-align: center;
  width: 48%;
  font-size: clamp(14px, 4vw, 14px);
  margin-left: 5px;

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
    margin-top: 20px;
  }
`;

const SpanDivider = styled.span`
  color: #948d8d;
`;

const SpanText = styled.span`
  color: #e00a0a;
`;

const SubscribeButton = styled.a`
  background-color: #c24611;
  border-radius: 25px;
  padding: 5px 5px;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 30px;
  font-size: 12px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
`;

const SubscriptionAlert = ({ daysLeft = 0, tr, daysLeftUntilSubscriptionEnds }) => {
  const isWithinTwoWeeks = daysLeftUntilSubscriptionEnds <= 14;

  return (
    <>
      {daysLeft > 0 ? (
        <>
          <SpanDivider>{'|'}</SpanDivider>
          {/* <Banner>
            {tr('You are currently on the free trial period plan - still for ')}
            <SpanText>{`${daysLeft}`}</SpanText>
            <SpanText>{tr(' days')}</SpanText>
            <SubscribeButton href={`/dashboard/settings/payments/create`}>
              {tr('Subscribe')}
            </SubscribeButton>
          </Banner> */}
          <Banner>
            {tr('You use AdminCat free of charge!')}
          </Banner>
        </>
      ) : isWithinTwoWeeks ? (
        <>
          <SpanDivider>{'|'}</SpanDivider>
          <Banner>
            {tr('You are currently on the subscription plan - still for ')}
            <SpanText>{`${daysLeftUntilSubscriptionEnds}`}</SpanText>
            <SpanText>{tr(' days')}</SpanText>
            <SubscribeButton href={`/dashboard/settings/payments/create`}>
              {tr('Subscribe')}
            </SubscribeButton>
          </Banner>
        </>
      ) : null}
    </>
  );
};

export default SubscriptionAlert;
