import React from 'react';
import compose from 'lodash/fp/compose';
import {
  withTranslation,
  withDiseaseTypes,
  withDiseaseHistory,
  withCustomRouter,
} from 'components/hoc';
import MyDataContentTemplate from '../../common/MyDataContentTemplate';
import LottieLoader from 'components/common/LottieLoader';
import { getFullName } from '../../../../utils/getFullName';

const DiseaseHistory = (props) => {
  // Add filterableColumns configuration
  const filterableColumns = [
    {
      columnId: 'disease',
      columnDataType: 'text',
    },
    {
      columnId: 'person',
      columnDataType: 'text',
    },
    {
      columnId: 'start_date',
      columnDataType: 'date',
    },
    {
      columnId: 'valid_from',
      columnDataType: 'date',
    },
    {
      columnId: 'valid_to',
      columnDataType: 'date',
    },
    {
      columnId: 'space_name',
      columnDataType: 'text',
    },
  ];

  const mapFields = () => {
    const { diseaseHistory, trObj, fd } = props;
    return diseaseHistory.data.map((data) => {
      return {
        ...data,
        space_name: data.space,
        disease: trObj(data.disease_detail),
        person: getFullName(data?.person_detail),
        // person: `${data.person_detail.first_name}
        //   ${data.person_detail.last_name}`,
        start_date: fd(data.start_date),
        valid_from: fd(data.valid_from),
        valid_to: fd(data.valid_to),
      };
    });
  };

  const {
    // deleteDiseaseHistoryById,
    deleteBulkDiseaseHistory,
    gotoCreatePath,
    loaded,
  } = props;

  if (!loaded) {
    return (
      <>
        <LottieLoader />
      </>
    );
  }

  return (
    <React.Fragment>
      <MyDataContentTemplate
        tableName="disease_history"
        columns={[]}
        data={mapFields()}
        toggleForm={gotoCreatePath}
        bulkDelete={deleteBulkDiseaseHistory}
        filterableColumns={filterableColumns}
      />
    </React.Fragment>
  );
};

export default compose(
  withTranslation,
  withDiseaseTypes,
  withDiseaseHistory,
  withCustomRouter,
)(DiseaseHistory);
