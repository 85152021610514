import * as types from '../../actions/types';

const initialState = {
  loading: false,
  error: null,
  taskMonitorData: {},
};

const taskMonitorReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TASK_MONITOR_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case types.TASK_MONITOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case types.FETCH_TASK_MONITOR_SUCCESS:
      return {
        ...state,
        loading: false,
        taskMonitorData: action.payload,
        error: null,
      };

    default:
      return state;
  }
};

export default taskMonitorReducer;
