import React, { useMemo } from 'react';
import compose from 'lodash/fp/compose';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Cell,
  LabelList,
} from 'recharts';
import 'chart.js/auto';
import { trimStringLength } from 'helpers';
import { withTranslation, withUserPreferences } from 'components/hoc';


const BarChartLabel = ({ x, y, value }) => {
  const newX = x + 5;
  const newY = y - 5;
  return (
    <text
      x={newX}
      y={newY}
      fill="var(--admincat-color-grey-12)"
      textAnchor="start"
      fontFamily="Poppins"
      fontSize={14}
    >
      {trimStringLength(value, 37)}
    </text>
  );
};

const Top5Contracts = ({ data, tr, fn }) => {
  const bgColors = [
    ['rgba(255, 99, 132, 1)'],
    ['rgba(255, 99, 132, 1)', 'rgba(255, 159, 64, 1)'],
    ['rgba(255, 205, 86, 1)', 'rgba(255, 99, 132, 1)', 'rgba(255, 159, 64, 1)'],
    [
      'rgba(255, 205, 86, 1)',
      'rgba(255, 99, 132, 1)',
      'rgba(75, 192, 192, 1)',
      'rgba(255, 159, 64, 1)',
    ],
    [
      'rgba(54, 162, 235, 1)',
      'rgba(255, 205, 86, 1)',
      'rgba(255, 99, 132, 1)',
      'rgba(75, 192, 192, 1)',
      'rgba(255, 159, 64, 1)',
    ],
  ];

  const data_ = [...(data || [])];

  const getColor = (length, index) => {
    if (length <= bgColors.length) {
      return bgColors[length - 1][index];
    }

    return bgColors[bgColors.length - 1][index % bgColors.length];
  };

  let ctx;

  const measureText14HelveticaNeue = (text) => {
    if (!ctx) {
      ctx = document.createElement('canvas').getContext('2d');
      ctx.font = "14px 'Helvetica Neue";
    }

    return ctx.measureText(text).width;
  };

  const BAR_AXIS_SPACE = 30;

  const maxTextWidth = useMemo(
    () =>
      data_.reduce((acc, cur) => {
        const value = cur['transactions'];
        const width = measureText14HelveticaNeue(value.toLocaleString());
        if (width > acc) {
          return width;
        }
        return acc;
      }, 0),
    [data_, 'transactions'],
  );

  return (
    <ResponsiveContainer
      width={'100%'}
      height={60 * (data_.length + 0.5)}
      debounce={50}
    >
      <BarChart
        data={data_}
        layout="vertical"
        margin={{ left: 2, right: maxTextWidth + (BAR_AXIS_SPACE - 8) }}
      >
        <XAxis type="number" axisLine={false} display="none" />

        <YAxis
          yAxisId={0}
          orientation="left"
          dataKey={'description'}
          type="category"
          axisLine={true}
          tickLine={true}
          mirror
          hide
          tick={{
            transform: `translate(${maxTextWidth + BAR_AXIS_SPACE}, 0)`,
          }}
        />

        <YAxis
          orientation="right"
          yAxisId={1}
          dataKey={'transactions'}
          type="category"
          axisLine={false}
          tickLine={false}
          tickFormatter={(value) => fn(value?.toFixed(2))}
          mirror
          tick={{
            transform: `translate(${maxTextWidth + BAR_AXIS_SPACE}, 0)`,
            fill: "var(--admincat-color-grey-12)",
          }}
        />

        <Bar dataKey={'transactions'} minPointSize={10} barSize={22}>
          <LabelList
            dataKey={'description'}
            position="top"
            // fill="#262626"
            fill="var(--admincat-color-grey-12)"
            content={<BarChartLabel x={-1} y={-1} width={-1} value={-1} />}
          />

          {data_.map((d, idx) => {
            return (
              <Cell key={d['description']} fill={getColor(data_.length, idx)} />
            );
          })}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default compose(withTranslation, withUserPreferences)(Top5Contracts);
