import React from 'react';
import compose from 'lodash/fp/compose';
import {
  withUserPreferences,
  withTranslation,
  withCitizenshipInfo,
  withCustomRouter,
} from '../../../../hoc';
import MyDataContentTemplate from '../../common/MyDataContentTemplate';
import { getFullName } from '../../../../utils/getFullName';
import LottieLoader from 'components/common/LottieLoader';

const options = ({ detail, _delete }) => {
  return [
    {
      onClick: detail,
      name: 'View',
    },
    {
      onClick: _delete,
      name: 'Delete',
    },
  ];
};

const Citizenship = (props) => {
  const mapFields = () => {
    const { citizenshipInfo, trObj, fd } = props;
    return citizenshipInfo.data.map((data) => {
      return {
        ...data,
        space_name: data.space,
        _citizen: getFullName(data.citizen_detail),
        _nationality: trObj(data.nationality_detail),
        _valid_from: fd(data.valid_from),
        _valid_to: fd(data.valid_to),
      };
    });
  };

  const {
    deleteCitizenshipInfoById,
    deleteBulkCitizenshipInfo,
    gotoCreatePath,
    gotoDetailPath,
    loaded,
  } = props;

  if (!loaded) {
    return (
      <>
        <LottieLoader />
      </>
    );
  }

  const filterableColumns = [
    {
      columnId: '_citizen',
      columnDataType: 'text',
    },
    {
      columnId: '_nationality',
      columnDataType: 'text',
    },
    {
      columnId: '_valid_from',
      columnDataType: 'date',
    },
    {
      columnId: '_valid_to',
      columnDataType: 'date',
    },
    {
      columnId: 'space_name',
      columnDataType: 'text',
    },
  ];

  return (
    <React.Fragment>
      <MyDataContentTemplate
        tableName="citizenship"
        columns={[]}
        data={mapFields() || []}
        toggleForm={gotoCreatePath}
        bulkDelete={deleteBulkCitizenshipInfo}
        options={options({
          detail: (data) => gotoDetailPath(data.id),
          _delete: (data) => deleteCitizenshipInfoById(data),
        })}
        filterableColumns={filterableColumns}
      />
    </React.Fragment>
  );
};

export default compose(
  withTranslation,
  withUserPreferences,
  withCitizenshipInfo,
  withCustomRouter,
)(Citizenship);
