import React, { useState } from 'react';
import compose from 'lodash/fp/compose';
import {
  deleteBulkCreditCard,
} from 'actions';
import { useDispatch, useSelector } from 'react-redux';
import { useReferenceCheckingAccount } from 'hooks/useReferenceCheckingAccount';
import {
  withTranslation,
  withBulkDelete,
  withDeleteConfirmation,
  withCustomRouter,
} from 'components/hoc';
import MyDataContentTemplate from '../../common/MyDataContentTemplate';
import { useListCreditCard } from '../hooks/useListCreditCard';
import LottieLoader from  'components/common/LottieLoader';


const CreditCard = (props) => {

  // Add filterableColumns configuration
  const filterableColumns = [
    {
      columnId: '_person',
      columnDataType: 'text',
    },
    {
      columnId: '_license_org',
      columnDataType: 'text',
    },
    {
      columnId: '_card_type',
      columnDataType: 'text',
    },
    {
      columnId: 'card_no',
      columnDataType: 'text',
    },
    {
      columnId: 'valid_thru',
      columnDataType: 'date',
    },
    {
      columnId: '_issuing_org',
      columnDataType: 'text',
    },
    {
      columnId: '_account_ref',
      columnDataType: 'text',
    },
    {
      columnId: 'cvc',
      columnDataType: 'text',
    },
    {
      columnId: 'space_name',
      columnDataType: 'text',
    }
  ];

  const { 
    gotoCreatePath,
  } = props;

  const referenceCheckingAccount = useReferenceCheckingAccount();
  const loading = useSelector((state) => state.creditCard.loading);

  const dispatch = useDispatch();

  const handleBulkDelete = async (data) => {
    const deleteBulk = () => dispatch(deleteBulkCreditCard(data));
    const { deleteConfirmation } = props;
    deleteConfirmation.initBulk(deleteBulk, data);
  };


  const data = useListCreditCard();

  const mapFields = () =>
    data?.map((creditCard) => ({
      ...creditCard,
      space_name: creditCard.space,
      _card_type: creditCard.card_type === 'C' ? 'Credit' : 'Debit',
      _person: `${creditCard?.personal_detail?.first_name} ${
        creditCard?.personal_detail?.other_names ?? ''
      }`,
      _issuing_org: creditCard.issuing_org_detail.name,
      _license_org: creditCard.license_org_detail.name,
      card_no: creditCard.number,
      _account_ref: creditCard.account_ref_detail?.account_no,
    }
    )
    );


  if (loading) return <><LottieLoader /></>;

  return (

    <MyDataContentTemplate 
      tableName="credit_card"
      columns={[]}
      data={mapFields() || []}
      handleCreateForm={gotoCreatePath}
      bulkDelete={handleBulkDelete}
      filterableColumns={filterableColumns}
    />

  );
};

export default compose(
  withTranslation,
  withBulkDelete,
  withDeleteConfirmation,
  withCustomRouter,
)(CreditCard);
