/* eslint-disable camelcase */
import React, { useState, Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Validator from 'validatorjs';
import compose from 'lodash/fp/compose';
import styled from 'styled-components';
import { useAccountCategory } from 'hooks/useAccountCategory';
import { useFinancialCategory } from 'hooks/useFinancialCategory';
import { useExpenseItemReference } from 'hooks/useExpenseItemReference';
import {
  handleInputChange,
  initialValues,
  getItemReference,
} from '../Contract/utils';
import {
  withCustomRouter,
  withAccountCategory,
  withMeasurementUnit,
  withCurrency,
  withMsgInfo,
  withTranslation
} from 'components/hoc';
import {
  createContractItems,
  getItemReferenceId,
  UpdateContractItems,
} from 'actions';
import {
  Select,
  Input,
  DualInputWrapper,
  Form,
  ButtonLink,
} from 'components/common/FormItems';
import { contractItemValidationConstraints } from 'helpers/validation';
import axios from '../../../../../axios';
import API from 'utils/api';
import { useHistory } from 'react-router';


const Wrapper = styled.div``;

const StyledButtonLink = styled(ButtonLink)`

  span {
    text-transform: uppercase;
  }
`;

const ContractItemForm = (props) => {
  const [errors, setErrors] = useState([]);
  const [touched, setTouched] = useState({});
  const [itemReference, setItemReference] = useState('');
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [offset, setOffset] = useState(0);
  const { isPersonBuyer, tr } = props;

  const [formValues, setFormValues] = useState({
    contract_items: [
      {
        ...initialValues,
        account_category: isPersonBuyer ? 'expense' : 'expenses',
      },
    ],
  });

  const { q: contractId } = props.routeQueries;
  const dispatch = useDispatch();

  const tempValidate = () => {
    const errorList = [];
    const validation = new Validator(
      formValues.contract_items[currentFormIndex],
      contractItemValidationConstraints,
    );
    if (validation.fails()) {
      errorList[currentFormIndex] = validation.errors.errors;
      const touchedFields = {};
      Object.keys(formValues.contract_items[currentFormIndex]).forEach((key) => {
        touchedFields[`contract_items.${currentFormIndex}.${key}`] = true;
      });
      setErrors(errorList);
      setTouched(touchedFields);
    }
    return errorList.length > 0;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const {
      // mode,
      updateMode,
      setMsgInfo,
      tr,
      // contractNo,
      // onItemsCreated,
    } = props;
    if (tempValidate()) return;
    let err;
    if (updateMode) {
      err = await dispatch(
        UpdateContractItems(formValues.contract_items, contractId),
      );
      dispatch(
        setMsgInfo({
          success: true,
          msg: [`${tr('Contract Item updated successfully')}`],
        }),
      );
    } else {
      err = await dispatch(
        createContractItems(formValues.contract_items, contractId),
      );
      dispatch(
        setMsgInfo({
          success: true,
          msg: [`${tr('Contract Item added successfully')}`],
        }),
      );
    }
    if (!err) {
      // go to the overview page
      history.push(`${basePath}?q=${contractId}`);
    }
  };

  const handleChange = (event) => {
    handleInputChange(event, formValues, setFormValues);
    const {
      target: { name, value },
    } = event;
    const fieldName = name.split('.')[2];
    validate({
      ...formValues.contract_items[currentFormIndex],
      [fieldName]: value,
    });
    setTouched({ ...touched, [name]: true });
  };

  const validate = (fields) => {
    const validation = new Validator(fields, contractItemValidationConstraints);
    let newErrors;
    if (validation.fails()) {
      newErrors = errors.length
        ? (newErrors = errors.map((error, index) => {
            if (index === currentFormIndex) return validation.errors.errors;
            return error;
          }))
        : [validation.errors.errors];
      setErrors(newErrors);
      return;
    }
    newErrors = [...errors];
    newErrors[currentFormIndex] = {};
    setErrors(newErrors);
  };

  const handleBlur = ({ target: { name, value } }) => {
    validate({ ...formValues.contract_items[currentFormIndex], [name]: value });
  };

  const {
    measurementUnits,
    expenseItemReference,
    currency,
    contract: { contract_reference_id_list },
  } = useSelector((state) => state);

  const { expenseItemReferenceOptions } = useExpenseItemReference();

  const itemRef = formValues.contract_items[currentFormIndex]?.item_reference;

  useEffect(() => {
    if (itemRef) {
      const reference = getItemReference(
        itemRef,
        expenseItemReference,
      );
      setItemReference(reference);
      reference && dispatch(getItemReferenceId(reference));
    }
  }, [itemRef, dispatch, expenseItemReference]);

  const { contractItems } = props;

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    const { routePathname, routeParams } = props;
    let activeIndex = 0;
    if (contractId) {
      try {
        setLoading(true);
        axios
          .get(`${API}/dashboard/contracts/${contractId}`)
          .then((response) => {
            const { data } = response;
            if (routePathname.includes('create')) {
              setLoading(false);
              setOffset(data?.contract_items.length);
            } else {
              const { id: selectedId } = routeParams;
              const uContractItems = data?.contract_items || [];
              uContractItems.forEach((contractItem, index) => {
                if ((contractItem?.id).toString() == selectedId) {
                  activeIndex = index;
                }
              });
              setFormValues({ contract_items: uContractItems });
              setLoading(false);
              setCurrentFormIndex(activeIndex);
            }
          })
          .catch((e) => {
            setFormValues({ contract_items: [] });
            setLoading(false);
            setCurrentFormIndex(activeIndex);
          });
      } catch (e) {
        setFormValues({ contract_items: [] });
        setLoading(false);
        setCurrentFormIndex(activeIndex);
      }
    } else {
      setFormValues({ contract_items: [] });
      setLoading(false);
      setCurrentFormIndex(activeIndex);
    }
  }, []);

  useEffect(() => {
    contractItems?.length &&
      setFormValues({
        contract_items: contractItems?.map((item) => {
          const newItem = {};
          Object.keys(item).forEach((key) => {
            if (item[key] === null) {
              newItem[key] = '';
            } else {
              newItem[key] = item[key];
            }
          });
          newItem.account_category = isPersonBuyer ? 'expense' : 'expenses';
          return newItem;
        }),
      });
  }, [contractItems, isPersonBuyer]);

  const { accountCategoryOptions } = useAccountCategory();
  const { financialCategoryOptions } = useFinancialCategory();

  // console.log(financialCategoryOptions)

  const isError = (touchedObj, error) =>
    touchedObj && error?.length > 0 ? '  ' : '';

  const { timeMeasurementLocaleOptions, basePath } = props;

  return (

    <Wrapper className="form-wrapper">
    <div className="flex">
    <StyledButtonLink
            to={`${basePath}?q=${contractId}`}
            className="option-btn"
            icon="angle double left"
            name="All entries"
          />
    </div>
    <div className="row">
      <div
        className="col-7 ml-10 mt-30 container"
        style={{
          position: 'relative',
          background: 'var(--admincat-color-grey-1)',
        }}
      >
        <br />

    
        <Form onSubmit={onSubmit} loadingData={loading} title="Contract Items">
          <Fragment>
            {formValues.contract_items.map((value, index) => (
              <Fragment key={index}>
                {currentFormIndex === index && (
                  <div className="contract-item">
                    <div className="d-flex justify-content-between p-0 m-0">
                      <div className="col-md-6 p-0">
                        <Input
                          label="Contract No"
                          value={contractId}
                          name={`contract_items.${index}.contract_no`}
                          placeholder="Contract No(Internal)"
                          disabled
                        />
                      </div>
                      <div className="col-md-6">
                        <Input
                          label="Item"
                          value={index + 1 + offset}
                          name={`contract_items.${index}.item`}
                          placeholder="Item"
                          required
                          disabled
                        />
                      </div>
                    </div>
                    <Input
                      showRequiredAsterisk
                      label="Item Description"
                      value={value.item_description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name={`contract_items.${index}.item_description`}
                      placeholder="Item"
                      error={isError(
                        touched?.[`contract_items.${index}.item_description`],
                        errors?.[currentFormIndex]?.item_description?.[0],
                      )}
                    />
                    <DualInputWrapper>
                      <Select
                        label="Item Reference"
                        optionDefaultValue="Item Reference"
                        value={value.item_reference}
                        name={`contract_items.${index}.item_reference`}
                        localeOptions={expenseItemReference}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={isError(
                          touched?.[`contract_items.${index}.item_reference`],
                          errors?.[currentFormIndex]?.item_reference,
                        )}
                      />
                      {itemReference ? (
                        <Select
                          label="Item Reference ID"
                          optionDefaultValue="Item Reference ID"
                          value={value.subject_id}
                          name={`contract_items.${index}.subject_id`}
                          localeOptions={contract_reference_id_list?.data ?? []}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={isError(
                            touched?.[`contract_items.${index}.subject_id`],
                            errors?.[currentFormIndex]?.subject_id,
                          )}
                        />
                      ) : (
                        <Input
                          value={value.subject_id}
                          name={`contract_items.${index}.subject_id`}
                          placeholder="Item Reference ID"
                          label="Item Reference ID"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={isError(
                            touched?.[`contract_items.${index}.subject_id`],
                            errors?.[currentFormIndex]?.subject_id,
                          )}
                        />
                      )}
                    </DualInputWrapper>
                    <DualInputWrapper>
                      <Select
                        showRequiredAsterisk
                        label="Account Category"
                        optionDefaultValue="Account Category"
                        value={value.account_category}
                        name={`contract_items.${index}.account_category`}
                        localeOptions={accountCategoryOptions}
                        onChange={handleChange}
                        // disabled
                        sort={false}
                      />
                      <Select
                        showRequiredAsterisk
                        label="Finance Account"
                        optionDefaultValue="Finance Account"
                        value={value.financial_category}
                        name={`contract_items.${index}.financial_category`}
                        localeOptions={financialCategoryOptions.filter(
                          ({ acc_cat_group }) =>
                            acc_cat_group === value.account_category,
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={isError(
                          touched?.[`contract_items.${index}.financial_category`],
                          errors?.[currentFormIndex]?.financial_category,
                        )}
                        sort={false}
                      />
                    </DualInputWrapper>
                    <DualInputWrapper>
                      <Select
                        showRequiredAsterisk
                        label="Fix or Variable"
                        optionDefaultValue="Fix or Variable"
                        value={value.fix_or_var}
                        name={`contract_items.${index}.fix_or_var`}
                        localeOptions={[
                          {
                            long_description_eng: 'Fix',
                            id: 'F',
                            long_description_deu: 'Fest',
                          },
                          {
                            long_description_eng: 'Variable',
                            id: 'V',
                            long_description_deu: 'Variabel',
                          },
                        ]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={isError(
                          touched?.[`contract_items.${index}.fix_or_var`],
                          errors?.[currentFormIndex]?.fix_or_var,
                        )}
                        sort={false}
                      />
                      <Select
                        showRequiredAsterisk
                        label="Currency"
                        optionDefaultValue="Currency"
                        value={value.currency}
                        name={`contract_items.${index}.currency`}
                        localeOptions={currency}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={isError(
                          touched?.[`contract_items.${index}.currency`],
                          errors?.[currentFormIndex]?.currency,
                        )}
                      />
                    </DualInputWrapper>
                    <DualInputWrapper>
                      <Select
                        showRequiredAsterisk={value.fix_or_var === 'F'}
                        label="Fixed period unit"
                        optionDefaultValue="Fixed period unit"
                        value={value.fix_period_unit}
                        name={`contract_items.${index}.fix_period_unit`}
                        localeOptions={timeMeasurementLocaleOptions}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={isError(
                          touched?.[`contract_items.${index}.fix_period_unit`],
                          errors?.[currentFormIndex]?.fix_period_unit,
                        )}
                        sort={false}
                      />
                      <Input
                        showRequiredAsterisk={value.fix_or_var === 'F'}
                        value={value.fix_amount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name={`contract_items.${index}.fix_amount`}
                        placeholder="Fixed amount"
                        label="Fixed amount"
                        error={isError(
                          touched?.[`contract_items.${index}.fix_amount`],
                          errors?.[currentFormIndex]?.fix_amount,
                        )}
                      />
                    </DualInputWrapper>
                    <DualInputWrapper>
                      <Select
                        showRequiredAsterisk={value.fix_or_var === 'V'}
                        label="Variable Unit"
                        optionDefaultValue="Variable Unit"
                        value={value.var_service_unit}
                        name={`contract_items.${index}.var_service_unit`}
                        localeOptions={measurementUnits}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={isError(
                          touched?.[`contract_items.${index}.var_service_unit`],
                          errors?.[currentFormIndex]?.var_service_unit,
                        )}
                        sort={false}
                      />
                      <Input
                        showRequiredAsterisk={value.fix_or_var === 'V'}
                        value={value.var_service_rate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name={`contract_items.${index}.var_service_rate`}
                        placeholder="Variable Rate"
                        label="Variable Rate"
                        error={isError(
                          touched?.[`contract_items.${index}.var_service_rate`],
                          errors?.[currentFormIndex]?.var_service_rate,
                        )}
                      />
                    </DualInputWrapper>
                    <DualInputWrapper>
                      <Input
                        showRequiredAsterisk={value.fix_or_var === 'V'}
                        value={value.counter_reading_b}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name={`contract_items.${index}.counter_reading_b`}
                        placeholder="Counter Reading Start"
                        label="Counter reading at Start"
                        error={isError(
                          touched?.[`contract_items.${index}.counter_reading_b`] &&
                            errors?.[currentFormIndex]?.counter_reading_b,
                        )}
                      />
                      <Input
                        value={value.counter_reading_e}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name={`contract_items.${index}.counter_reading_e`}
                        placeholder="Counter Reading End"
                        label="Counter reading at End"
                        error={isError(
                          touched?.[`contract_items.${index}.counter_reading_e`] &&
                            errors?.[currentFormIndex]?.counter_reading_e,
                        )}
                      />
                    </DualInputWrapper>
                  </div>
                )}
              </Fragment>
            ))}
          </Fragment>
        </Form>
      </div>
    </div>
    </Wrapper>
  );
};

export default compose(
  withCustomRouter,
  withAccountCategory,
  withMeasurementUnit,
  withCurrency,
  withMsgInfo,
  withTranslation,
)(ContractItemForm);
