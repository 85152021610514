import React, { useState } from 'react';
import styled from 'styled-components';
import { Logo } from 'components/Header/styled';
import logo from '../../../static/demo/brand/Admincatlogo1.png';
import { Input, Button } from '../../common/AuthFormItems';
import compose from 'lodash/fp/compose';
import { withTranslation, withAccount, withUserPreferences } from '../../hoc';
import { useTranslation } from 'react-i18next';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #317ed9;
`;

const LoginCard = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 30px;
  width: 380px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  font-size: 18px;
  color: #333;
  margin-bottom: 5px;
`;

const Subtitle = styled.p`
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const LoginButton = styled(Button)`
  width: 100%;
  padding: 10px;
  background-color: #4c81be;
  color: white;
  border: none;
  border-color: #1d579b;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #1976d2;
  }
`;

const AdminLoginComponent = (props) => {
  const { t } = useTranslation();
  const {
    account: { isLoading },
    adminLogin,
  } = props;

  const [form, setForm] = useState({
    email: '',
    password: '',
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const onSubmit = (e, redirectPath) => {
    e.preventDefault();
    if (isLoading) return;

    adminLogin(
      {
        ...form,
        browser_id: localStorage.getItem('browserId'),
      },
      redirectPath,
    );
  };

  return (
    <PageContainer>
      <LoginCard>
        <Logo
          style={{ height: 100, width: 120, marginLeft: -5 }}
          src={logo}
          alt="logo image"
        />
        <Title>{t('Admin Login')}</Title>
        <Subtitle>{t('Please provide information below to login')}</Subtitle>
        <Form onSubmit={onSubmit}>
          <Input
            icon="user"
            onChange={onChange}
            value={form?.email}
            name="email"
            label={t('Email')}
            placeholder={t('Email')}
            required
          />
          <Input
            icon="lock"
            onChange={onChange}
            value={form?.password}
            name="password"
            type="password"
            label={t('Password')}
            placeholder={t('Password')}
            required
          />
          <LoginButton type="submit" name={t('Sign in')} isLoading={isLoading} />
        </Form>
      </LoginCard>
    </PageContainer>
  );
};

export default compose(
  withAccount,
  withTranslation,
  withUserPreferences,
)(AdminLoginComponent);
