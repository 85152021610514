import React from "react";
import styled from "styled-components";
import { Icon } from "semantic-ui-react";
import { withTranslation } from "../../hoc";

const StyledIcon = styled(Icon)`
`

const TableHeader = ({ tr, columns, hasBulkDelete, onTableHeaderClicked, useContextMenu }) => {
  return (
    <React.Fragment>
      {hasBulkDelete && <th />}
      {columns.map(({ id, label, icon }, index) => {
        return (
          // (!label && !useContextMenu) 
          (!label) ?
            null :
            (
              <th onClick={onTableHeaderClicked(id)} className="" key={id || index} style={{ color: 'var(--admincat-color-grey-11)' }}>
                <strong>
                  {icon && <StyledIcon name={icon} />}
                  {tr(label)}
                </strong>
              </th>
            )
        )
      })}
    </React.Fragment>
  )
}

TableHeader.defaultProps = {
  useContextMenu: true,
}


export default withTranslation(TableHeader);
