import React from 'react';
import compose from 'lodash/fp/compose';
import styled from 'styled-components';
import check from 'check-types';
import { genId, isNumber } from 'components/utils';
import {
  withTranslation,
  withUserPreferences,
  withFieldError,
} from 'components/hoc';
import { SelectOptions } from '../SelectOptions';

const TinyInfoText = styled.small`
  color: gray;
  font-size: 0.8rem;
  margin-top: 3px;
  display: block;
`;

export const InputWrapper = styled.div`
  width: 98%;
  margin-bottom: 0.7rem;

  .displayError {
    margin-left: 0;
    width: 99.5%;
  }
`;

export const inputStyle = `
  font-size: 15px;
  width: 100%;
  border-radius: 5px;
  padding: 1.5px;
  padding-left: 10px;
  height: 42px;
  color: var(--admincat-color-grey-2);

  &:focus {
    box-shadow: none;
    border: 1px solid #4285f4;
  }

  &:disabled {
    opacity: 1;
    cursor: not-allowed;
    color: var(--admincat-color-grey-2);
  }

  &::-webkit-input-placeholder {
    color:var(--admincat-color-grey-3);
  }

  &:-ms-input-placeholder {
    color: var(--admincat-color-grey-3);
  }

  &::placeholder {
    color: var(--admincat-color-grey-3);
  }

  /* Additional styles to help prevent autocomplete styling */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    -webkit-text-fill-color: var(--admincat-color-grey-2) !important;
  }
`;

const StyledInput = styled.input`
  &&& {
    ${inputStyle}
    border: ${({ error }) =>
      error ? '1px solid red !important' : '1px solid var(--admincat-color-grey-2)'};
    height: 38px;
    box-sizing: border-box; 
    width: 100%; 
    
    /* Ensure text color is consistent */
    color: var(--admincat-color-grey-2);
    
    /* Use different color for placeholder */
    &::placeholder {
      color: var(--admincat-color-grey-3) !important;
    }
  }
`;

class Input extends React.Component {
  onChange = ({ target }) => {
    const { name, value } = target;
    const { onChange, fieldType, xfn } = this.props;
    if (fieldType === 'number') {
      target = { name, value: xfn(value, true) };
      if (isNumber(target.value) || target.value.endsWith('.')) {
        onChange({ target });
      }
    } else {
      onChange({ target });
    }
  };

  render() {
    const {
      style,
      inputStyle: customInputStyle,
      name,
      label,
      required,
      showRequiredAsterisk,
      type,
      value,
      placeholder,
      langPlaceholder,
      langOptions,
      localeOptions,
      options,
      className,
      disabled,
      id,
      tr,
      error,
      forwardedRef,
      tinyInfo,
      fieldType,
      fn,
      ...rest
    } = this.props;

    const _id = id || genId();
    const listid = genId();
    const hasOptions = langOptions || localeOptions || options;
    const isNumberInput = fieldType === 'number';
    const extraProps = {
      ...(isNumberInput
        ? { value: fn(value, true)}
        : { value: value || '' }),
    };

    return (
      <InputWrapper
        className={`form-group w-90 user-title ${className}`}
        style={style}
      >
        {label && (
          <label className="form-label" htmlFor={_id}>
            {tr(label || '')}
            {(required || showRequiredAsterisk) && (
              <span className="form-required">*</span>
            )}
          </label>
        )}
        <StyledInput
          {...rest}
          type={type}
          ref={forwardedRef}
          name={name}
          id={_id}
          onChange={this.onChange}
          placeholder={tr(placeholder)}
          style={customInputStyle}
          disabled={disabled}
          required={required}
          showRequiredAsterisk={showRequiredAsterisk}
          list={listid}
          error={error}
          autoComplete="new-password"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          data-form-type="other"
          data-lpignore="true"
          {...extraProps}
        />
        {hasOptions && (
          <datalist id={listid}>
            <SelectOptions
              localeOptions={langOptions || localeOptions}
              options={options}
              {...rest}
            />
          </datalist>
        )}
        {tinyInfo && (
          <TinyInfoText>
            {check.string(tinyInfo) ? tr(tinyInfo) : tinyInfo}
          </TinyInfoText>
        )}
        <small id="passwordHelp" className="text-danger">
          {tr(error)}
        </small>
      </InputWrapper>
    );
  }
}

Input.defaultProps = {
  style: {},
  inputStyle: {},
  value: '',
  listid: '',
  fieldType: null,
  type: 'text',
  disabled: false,
  required: false,
  showRequiredAsterisk: false,
  className: '',
  tinyInfo: '',
  onChange: () => {},
};

export default compose(withTranslation, withUserPreferences, withFieldError)(Input);