import React from 'react';
import compose from 'lodash/fp/compose';
import styled from 'styled-components';
// import check from 'check-types';
import { Button, Icon } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  withTranslation,
  withPersonalInfo,
  withCustomRouter,
  withUserPreferences,
  withPersonGroup,
  withPersonGroupMember,
  withMsgInfo,
} from 'components/hoc';
import FlashMessage from 'components/common/FormItems/FlashMessage';
import { Select, Input, Form, ButtonLink } from 'components/common/FormItems';
import { isEmptyValue } from 'components/utils/checkValue';
import { excludeUnsetField } from 'components/utils/excludeUnsetField';

const MAX_MEMBERS = 15;

const Wrapper = styled.div``;

const StyledAddButton = styled(Button)`
  && {
    font-weight: 600 !important;
    width: auto !important;
    color: var(--admincat-color-primary) !important;
  }
`;


export const AddButton = ({ label, onClick, ...rest }) => (
  <StyledAddButton
    type="button"
    icon
    labelPosition="left"
    onClick={onClick}
    {...rest}
  >
    {label}
    <Icon name="plus" />
  </StyledAddButton>
);


const MemberRowWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 0.5rem;
  width: 100%;

  .select-container {
    flex: 1;
  }
`;

const RemoveMemberButton = styled(Button)`
  && {
    background: transparent !important;
    font-size: 1.3rem !important;
    color: red !important;
    cursor: pointer;
    margin-bottom: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const StyledButtonLink = styled(ButtonLink)`
  span {
    text-transform: uppercase;
  }
`;

const personGroupFormSchema = {
  description: {
    validation: 'required',
  },
  // person: {
  //   validation: 'required',
  // },
};

const PersonGroupForm = (props) => {
  const {
    addPersonGroup,
    getPersonGroupById,
    updatePersonGroup,
    routeParams,
    gotoDetailPath,
    insertBulkPersonGroupMember,
    detailMode,
    tr,
    basePath,
    setMsgInfo,
    // getPersonGroup,
  } = props;

  const { id: personGroupId } = routeParams;
  const history = useHistory();

  const dispatch = useDispatch();

  const [formData, setFormData] = React.useState({});
  const [error, setError] = React.useState();
  const [members, setMembers] = React.useState([{}]);
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [formLoading, setFormLoading] = React.useState(false);

  const fetchData = async () => {
    if (personGroupId) {
      try {
        setFormLoading(true);
        const { members, ...data } = await getPersonGroupById(personGroupId);
        setFormData(data);
        setMembers(members.length ? members : [{}]);
      } finally {
        setFormLoading(false);
      }
    }
  };

  React.useEffect(() => {
    fetchData();
    return () => null;
  }, []);

  const onChange = (e) => {
    if (formSubmitting) return;
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async (e) => {
    if (formSubmitting) return;
    setError();
    const payload = excludeUnsetField(formData);
    const filteredMembers = members.filter(({ person }) => !isEmptyValue(person));

    if (!hasValidMembers(filteredMembers)) {
      setError(tr('A member at least must be provided'));
      return;
    }

    if (filteredMembers.length > MAX_MEMBERS) {
      setError(`Maximum ${MAX_MEMBERS} members are allowed`);
      return;
    }


    const getMembersPayload = (personGroupId) => {
      return filteredMembers.map(({ person, id, ...rest }) => ({
        person_group: personGroupId,
        person: String(person),
        ...(id && { id: String(id) }),
        ...rest,
      }));
    };
    try {
      setFormSubmitting(true);
      let personGroup = {};
      if (!formData.id) {
        personGroup = await addPersonGroup(payload);
        dispatch(
          setMsgInfo({
            success: true,
            msg: [`${tr('Person Group added successfully')}`],
          }),
        );
      } else {
        personGroup = await updatePersonGroup(formData.id, payload);
        dispatch(
          setMsgInfo({
            success: true,
            msg: [`${tr('Person Group updated successfully')}`],
          }),
        );
      }
      const membersPayload = getMembersPayload(personGroup.id);
      await insertBulkPersonGroupMember(membersPayload);
      history.push(basePath);
      window.scrollTo(0, 0);
    } catch (e) {
    } finally {
      setFormSubmitting(false);
    }
  };

  const handleAddMember = () => {
    if (formLoading || !hasValidMembers() || members.length >= MAX_MEMBERS) return;
    setMembers(prev => [...prev, {}]);
    setError('');
  };

  // const handleAddMember = (e) => {
  //   if (formLoading || !hasValidMembers()) return;
  //   setMembers([...members, {}]);
  // };

  const handleDeleteMember = (index) => {
    if (formLoading || members.length < 2) return;
    const restMembers = members.filter((_, i) => i != index);
    setMembers([...restMembers]);
  };

  const onLineChange = (e, index) => {
    if (formSubmitting) return;
    const { value, name } = e.target;
    members[index][name] = value;
    setMembers([...members]);
  };

  const hasValidMembers = (mbs = members) => {
    const getLastMember = () => {
      const lastIndex = mbs.length - 1;
      return mbs[lastIndex];
    };
    const { person } = getLastMember() || {};
    return !isEmptyValue(person);
  };

  // console.log(formData);

  const { personalInfoOptions } = props;

  const getPersonalInfoOptions = (person) => {
    return personalInfoOptions.filter(({ id }) => {
      if (+person === id) return true;
      return !members.map(({ person }) => +person).includes(id);
    });
  };

  const disabled = formSubmitting || formLoading || detailMode;
  const canAddMore = members.length < MAX_MEMBERS && hasValidMembers();

  return (
    <Wrapper className="form-wrapper">
      <div className="flex">
        <StyledButtonLink
          to={basePath}
          className="option-btn"
          icon="angle double left"
          name="All Entries"
        />
      </div>
      <div className="row">
        <div
          className="col-7 ml-10 mt-30 container"
          style={{
            position: 'relative',
            background: 'var(--admincat-color-grey-1)',
          }}
        >
          <br />
          <Form
            onSubmit={onSubmit}
            formData={formData}
            isLoading={formSubmitting}
            validationSchema={personGroupFormSchema}
            loadingData={formLoading}
            title="Person Group"
          >
            <Input
              value={formData.description || ''}
              onChange={onChange}
              name="description"
              id="description"
              placeholder="Description"
              label="Description"
              showRequiredAsterisk
            />
            {/* <hr /> */}

            {members.map((data, index) => (
              <MemberRowWrapper key={index}>
                <div className="select-container">
                  <Select
                    onChange={(e) => onLineChange(e, index)}
                    value={data.person}
                    name="person"
                    id="person"
                    label={`${tr('Member')} ${index + 1}`}
                    optionDefaultValue="Choose Member"
                    options={getPersonalInfoOptions(data.person)}
                    showRequiredAsterisk
                  />
                </div>
                <RemoveMemberButton
                  type="button"
                  disabled={disabled}
                  onClick={() => handleDeleteMember(index)}
                >
                  <i className="fa fa-minus-circle" />
                </RemoveMemberButton>
              </MemberRowWrapper>
            ))}
            <AddButton
              label={tr('Add')}
              onClick={handleAddMember}
              // disabled={disabled}
              disabled={disabled || !canAddMore}
            />

             {members.length >= MAX_MEMBERS && (
              <FlashMessage hasWarning>{tr(`Maximum`)} {MAX_MEMBERS} {tr(`members allowed`)}</FlashMessage>
            )}
            <hr />
            {error && <FlashMessage hasError={error}>{error}</FlashMessage>}
          </Form>
        </div>
      </div>
    </Wrapper>
  );
};

export default compose(
  withTranslation,
  withPersonalInfo,
  withCustomRouter,
  withUserPreferences,
  withPersonGroup,
  withPersonGroupMember,
  withMsgInfo,
)(PersonGroupForm);
